import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';
import OrdersPageV3 from 'pages/OrdersV3';
import OrdersPage from 'pages/OrdersV2';
import useCurrentUser from 'hooks/useCurrentUser';

const OrdersProxy = () => {
    // Este componente proxy se creo solamente para poder activar la feature flag, ya que no se puede activar fuera del router del app

    const currentUser = useCurrentUser();
    const merchant_id = String(currentUser?.userData?.merchant_id?._id ?? "");

    const use_orders_v3 = useGetValueFeatureFlag('use_orders_v3');

    return use_orders_v3?.enabled &&
        Array.from(use_orders_v3?.merchants ?? []).includes(merchant_id) ? (
        <OrdersPageV3 />
    ) : (
        <OrdersPage />
    );
};

export default OrdersProxy;