/* eslint-disable no-unused-vars */
import DrawerWithButton from 'components/DrawerWithButton';
import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { Chip, Typography } from './styles';
import Flex from 'components/Utils/Flex';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { toggleDrawer } from 'redux/features/Utils';
import { ReactComponent as ChevronUpIcon } from 'assets/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import { usePreProcessBatchDeliversMutation } from 'redux/features/Deliveries';
import printJS from 'print-js';
import { FiChevronsRight } from 'react-icons/fi';
import { Button } from '@andescommercecloud/beflow-design-system';
import { ModalValidateDeliveryPin } from '../components/ModalValidateDeliveryPin';
import StatesValidationModal from 'components/StatesValidationModal';

export const DrawerPreProcess = ({
    deliveryPreferences,
    handleSelectRows,
    dataPickupsPreferences,
    dataPreparations,
    updateList,
    isScan,
    buttonType,
    fulfillmentToDelivery,
    handleRefetch,
}) => {
    const prepareDefaultAssignment =
        dataPreparations?.preparations_preferences?.preparer_default_assignment;
    const twoStepDelivery =
        deliveryPreferences?.delivery_options?.shipping?.enable_two_step_delivery;
    const twoStepPickup = deliveryPreferences?.delivery_options?.pickup?.enable_two_step_delivery;
    const shippingTypeAutomatic =
        dataPickupsPreferences?.delivery_options?.shipping
            ?.type_automatic_assignment_manager_fulfillment;
    const pickupTypeAutomatic =
        dataPickupsPreferences?.delivery_options?.pickup
            ?.type_automatic_assignment_manager_fulfillment;

    const dispatch = useDispatch();
    const theme = useTheme();
    const [itemsOpens, setItemsOpens] = useState([]);
    const [preProcess, preProcessStatus] = usePreProcessBatchDeliversMutation();
    const [openModalValidatePinDelivery, setOpenModalValidatePinDelivery] = useState(false);
    const [fulfillmentToValidate, setFulfillmentToValidate] = useState([]);
    const [validateDeliveries, setValidateDeliveries] = useState([]);
    const [showStatesValidationModal, setShowStatesValidationModal] = useState(false);
    const [statesValidationSuccess, setStatesValidationSuccess] = useState(false);
    const [fulfillmentsToValidate, setFulfillmentsToValidate] = useState([]);
    const [fulfillmentIdsValidated, setFulfillmentIdsValidated] = useState([]);

    const manifest = useMemo(() => {
        const manifest = fulfillmentToDelivery?.reduce((rv, x) => {
            (rv[x.courier?._id] = rv[x.courier?._id] || []).push(x);
            return rv;
        }, {});

        return Object.values(manifest ?? {});
    }, [fulfillmentToDelivery]);

    const calculateNumberOfPackages = (order) => {
        return order?.reduce((acc, cur) => {
            return acc + (cur?.packages?.length ?? 0);
        }, 0);
    };

    const handleOpenItem = (index) => {
        const itemsOpensCopy = [...itemsOpens];
        itemsOpensCopy[index] = !itemsOpensCopy[index];
        setItemsOpens(itemsOpensCopy);
    };

    const { onlySuccessFulfillmentIds, onlySuccessFulfillments, selectedRowFulfillments } =
        useMemo(() => {
            const firstData = fulfillmentToDelivery?.[0];

            console.log({ fulfillmentToDelivery });
            console.log({ firstData });

            const onlySuccessFulfillments = fulfillmentToDelivery
                .filter((fulfillment) => {
                    const validationSuccess = ['success'].includes(fulfillment.status);

                    if (twoStepDelivery !== twoStepPickup) {
                        return (
                            validationSuccess &&
                            firstData?.delivery_method === fulfillment?.delivery_method
                        );
                    }

                    return validationSuccess;
                })
                .filter((fulfillment) => {
                    const everyPackageScanned = (fulfillment?.packages ?? []).every(
                        (pkg) => pkg?.status === 'processed'
                    );

                    return (
                        everyPackageScanned ||
                        fulfillment?.packages?.length === 1 ||
                        (prepareDefaultAssignment &&
                            !!(
                                (shippingTypeAutomatic === 'not_delivery_validation' &&
                                    fulfillment?.delivery_method === 'shipping') ||
                                (pickupTypeAutomatic === 'not_delivery_validation' &&
                                    fulfillment?.delivery_method === 'pickup')
                            ))
                    );
                });

            return {
                selectedRowFulfillments: fulfillmentToDelivery.map((ful) => ful._id),
                onlySuccessFulfillments: onlySuccessFulfillments,
                onlySuccessFulfillmentIds: onlySuccessFulfillments.map((ful) => ful._id),
            };
        }, [
            fulfillmentToDelivery,
            isScan,
            prepareDefaultAssignment,
            shippingTypeAutomatic,
            pickupTypeAutomatic,
        ]);

    const handleDeliveryFulfillments = async () => {
        try {
            const firstData = fulfillmentToDelivery?.[0];

            const isTwoStep =
                (onlySuccessFulfillments?.[0]?.delivery_method === 'shipping' && twoStepDelivery) ||
                (onlySuccessFulfillments?.[0]?.delivery_method === 'pickup' && twoStepPickup);

            const hasDeliveryPinValidation = onlySuccessFulfillments.some(
                (fulfillment) => fulfillment.has_delivery_pin_validation
            );

            const showValidateDeliveryPin = hasDeliveryPinValidation && !isTwoStep;

            if (showValidateDeliveryPin && !validateDeliveries.length) {
                setOpenModalValidatePinDelivery(true);
                setFulfillmentToValidate(
                    onlySuccessFulfillments.filter((fulfillment) => fulfillment.delivery_pin)
                );
                return;
            }

            const fulfillments = showValidateDeliveryPin
                ? onlySuccessFulfillments.filter((fulfillment) => !fulfillment.delivery_pin)
                : onlySuccessFulfillments;

            const fulfillmentsId = [
                ...fulfillments.map((f) => f._id),
                ...(validateDeliveries?.length ? validateDeliveries : []),
            ];

            setFulfillmentsToValidate(fulfillmentsId);

            if (!fulfillmentIdsValidated.length && !statesValidationSuccess) {
                setShowStatesValidationModal(true);
                dispatch(toggleDrawer('pre-process-delivery-orders-drawer'));

                return;
            }

            const parsedFulfillmentsId = fulfillmentsId.filter((el) =>
                fulfillmentIdsValidated.includes(el)
            );

            setStatesValidationSuccess(false);
            setFulfillmentIdsValidated([]);

            const response = await preProcess({
                fulfillmentIds: parsedFulfillmentsId,
                isTwoStep,
                remoteOrderId: firstData.order.remote_order_id,
            });

            handleRefetch(true);

            if (response?.data?.manifest_url) {
                printJS(response.data.manifest_url);

                updateList(
                    (state) => state.filter((f) => !selectedRowFulfillments.includes(f._id)) || []
                );

                handleSelectRows([]);
            }
        } catch (error) {
            console.error(error);
            setValidateDeliveries([]);
            dispatch(toggleDrawer('pre-process-delivery-orders-drawer'));
        }
    };

    const typeLetter = useMemo(
        () =>
            (onlySuccessFulfillments?.[0]?.delivery_method === 'shipping' && twoStepDelivery) ||
            (onlySuccessFulfillments?.[0]?.delivery_method === 'pickup' && twoStepPickup)
                ? 'pre-entrega'
                : 'entrega',
        [onlySuccessFulfillments, buttonType, twoStepDelivery, twoStepPickup]
    );

    useEffect(() => {
        if (validateDeliveries.length) {
            handleDeliveryFulfillments();
        }
    }, [validateDeliveries]);

    useEffect(() => {
        if (statesValidationSuccess && fulfillmentIdsValidated.length) {
            setStatesValidationSuccess(false);
            handleDeliveryFulfillments();
        }
    }, [statesValidationSuccess, fulfillmentIdsValidated]);

    return (
        <>
            <StatesValidationModal
                fulfillmentIds={fulfillmentsToValidate}
                action="batch_deliver"
                showStatesValidationModal={showStatesValidationModal}
                setShowStatesValidationModal={setShowStatesValidationModal}
                setStatesValidationSuccess={setStatesValidationSuccess}
                setFulfillmentIdsValidated={setFulfillmentIdsValidated}
                handleRefetch={handleRefetch}
                justRefetchAfterClose={true}
            />

            <DrawerWithButton
                forceRender
                titleTooltip={
                    selectedRowFulfillments.length !== onlySuccessFulfillmentIds?.length
                        ? 'Aun hay bultos pendientes por procesar, por favor verifique'
                        : ''
                }
                drawerProps={{
                    title: <Typography color={theme.color}>Procesar {typeLetter}</Typography>,
                    name: 'pre-process-delivery-orders-drawer',
                    width: '500px',
                    dontShowFooter: true,
                    forceRender: true,
                }}
                buttonProps={{
                    buttonText: `Procesar ${typeLetter} (${selectedRowFulfillments.length})`,
                    disabled:
                        (!selectedRowFulfillments?.length && !onlySuccessFulfillmentIds?.length) ||
                        selectedRowFulfillments.length !== onlySuccessFulfillmentIds?.length,
                }}
            >
                <Typography
                    style={{ padding: '20px 14px' }}
                    fontSize="14px"
                    color="primary.01"
                    fontWeight="500"
                >
                    El procesamiento de la {typeLetter} actual contempla la impresión de (
                    {Array.from(manifest.flat(1)).length}) manifiestos:
                </Typography>

                <hr />
                {manifest?.map((item, index) => (
                    <Fragment key={`manifest-${item._id}`}>
                        <Flex
                            alignItems="center"
                            columnGap="10px"
                            padding="20px 14px"
                            style={{ position: 'relative' }}
                        >
                            <Typography fontSize="14px" fontWeight="500">
                                Manifiesto - {item?.[0]?.courier?.public_name}
                            </Typography>
                            <Chip>
                                {item?.length ?? 0} pedidos / {calculateNumberOfPackages(item)}{' '}
                                bultos
                            </Chip>

                            {itemsOpens?.[index] ? (
                                <ChevronUpIcon
                                    style={{
                                        position: 'absolute',
                                        right: 30,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleOpenItem(index)}
                                />
                            ) : (
                                <ChevronDownIcon
                                    style={{
                                        position: 'absolute',
                                        right: 30,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleOpenItem(index)}
                                />
                            )}
                        </Flex>

                        {itemsOpens?.[index] &&
                            item?.map((fulfillment) => (
                                <Flex
                                    key={fulfillment?._id}
                                    direction="column"
                                    padding="5px 20px 5px"
                                    marginLeft="20px"
                                    style={{ lineHeight: '2.4' }}
                                >
                                    <Flex alignItems="center" columnGap="8px">
                                        <FiChevronsRight
                                            size={22}
                                            strokeWidth="2"
                                            color="#2D3D76"
                                            style={{ marginLeft: -29 }}
                                        />
                                        <Typography fontSize="12px" fontWeight="500">
                                            Id Ecommerce: {fulfillment?.remote_order_id}
                                        </Typography>
                                    </Flex>
                                    {fulfillment?.packages?.map((pkg) => (
                                        <Typography key={pkg?._id} fontSize="12px" fontWeight="400">
                                            Código de bulto: {pkg?.code}
                                        </Typography>
                                    ))}
                                </Flex>
                            ))}
                        <hr />
                    </Fragment>
                ))}
                <Flex padding="58px 20px 15px">
                    <Typography fontSize="14px" fontWeight="500" color="primary.01">
                        ¿Quieres procesar la {typeLetter} de estos pedidos?
                    </Typography>
                </Flex>
                <hr />

                <Flex
                    padding="14px 20px"
                    alignItems="center"
                    justifyContent="flex-end"
                    columnGap="10px"
                >
                    <Button
                        variation="secondary"
                        onClick={() => dispatch(toggleDrawer('pre-process-delivery-orders-drawer'))}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleDeliveryFulfillments}
                        disabled={preProcessStatus.isLoading}
                        loading={preProcessStatus.isLoading}
                    >
                        Procesar
                    </Button>
                </Flex>
                <ModalValidateDeliveryPin
                    onSuccess={(e) => {
                        setValidateDeliveries(e);
                        setOpenModalValidatePinDelivery(false);
                    }}
                    onClose={() => setOpenModalValidatePinDelivery(false)}
                    fulfillments={fulfillmentToValidate}
                    isOpen={openModalValidatePinDelivery}
                />
            </DrawerWithButton>
        </>
    );
};
