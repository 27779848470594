import { useParams } from 'react-router-dom';
import Header from '../OrderV2/components/Header/Header';
import Container from 'components/Grid/Container';
import { useGetOrderQuery } from 'redux/features/Orders';
import { PageLayout } from './styles';
import ChangeStoreProducts from './components/ChangeStoreProducts';
import ChangeStoreIncidence from './components/ChangeStoreIncidence';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { setChangeStoreValues } from '../../redux/features/ChangeStore';
import qs from 'qs';
import LockScreen from 'components/LockScreen';

const pages = {
    0: undefined,
    1: 'warehouse-list',
    2: 'change-confirmation',
};

const OrderChangeStorePage = () => {
    const { order_id } = useParams();
    const dispatch = useDispatch();
    const [hasLock, setHasLock] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [fulfillmentId, setFulfillmentId] = useState(undefined);
    const { data, isLoading: loading } = useGetOrderQuery(order_id);
    const changeStoreData = useSelector((state) => state.changeStore);

    const [productsIdArray, setProductsIdArray] = useState([]);

    const showLoading = (loading && !data?._id) || (loading && data?._id !== order_id);

    useEffect(() => {
        const queryString = window.location.search;
        const parsedQueryString = qs.parse(queryString, { ignoreQueryPrefix: true });
        const productsId = parsedQueryString?.products_id;
        const productsIdArray = productsId
            ? productsId.split(',')
            : data?.line_items.map((item) => item._id);
        setProductsIdArray(productsIdArray);
        setFulfillmentId(parsedQueryString?.fulfillment_id);
    }, [data]);

    useEffect(() => {
        const interval = setInterval(() => {
            const queryStrings = new URLSearchParams(window.location.search);
            const lock = queryStrings.get('lock');
            if (lock === 'true') setHasLock(true);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const itemsToChangeStore = Array.from(data?.line_items ?? [])?.filter((item) =>
        Array.from(productsIdArray ?? []).includes(item._id)
    );

    useEffect(() => {
        const step = changeStoreData.step;
        if (step === 'warehouse-available' || step === 'warehouse-list') {
            setCurrentStep(1);
        }
        if (step === 'change-confirmation') {
            setCurrentStep(2);
        }
    }, [changeStoreData.step]);

    useEffect(() => {
        return () => {
            dispatch(
                setChangeStoreValues({
                    step: undefined,
                })
            );
        };
    }, [dispatch]);

    const handleBackStep = () => {
        if (currentStep > 0) {
            dispatch(
                setChangeStoreValues({
                    step: pages[currentStep - 1],
                })
            );
            setCurrentStep(currentStep - 1);
        }
    };

    const { refetch } = useGetOrderQuery(order_id, { refetchOnMountOrArgChange: true });

    return (
        <Fragment>
            {hasLock && <LockScreen returnUrl={`/orders/${order_id}`} />}
            <Container extraTitle={!loading ? `Orden ${data?.name}` : ''}>
                <PageLayout>
                    <div className="order-page__header">
                        <Header
                            loading={showLoading}
                            data={data}
                            showActions={true}
                            refetch={refetch}
                        />
                    </div>

                    <div className="order__change-store-products">
                        <ChangeStoreProducts
                            fulfillmentId={fulfillmentId}
                            line_items={itemsToChangeStore}
                            order={data ?? {}}
                        />
                    </div>

                    <div className="order__change-store-incidence">
                        <ChangeStoreIncidence
                            fulfillmentId={fulfillmentId}
                            order={data ?? {}}
                            handleBackStep={handleBackStep}
                            currentStep={currentStep}
                        />
                    </div>
                </PageLayout>
            </Container>
        </Fragment>
    );
};

export default OrderChangeStorePage;
