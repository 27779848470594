/* eslint-disable no-undef */
import ReactDOM from 'react-dom/client';
import 'translate/i18n';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { registerLocale } from 'react-datepicker';
import App from './App';
import store from './redux';
import CheckOnLine from 'components/Utils/CheckOnLine';
import Toaster from 'components/Utils/Toaster';
import awsExports from './aws-exports';
import SEO from 'components/SEO';
import 'antd/dist/antd.min.css';
import 'scss/font.scss';
import 'scss/main.css';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

Amplify.configure(awsExports);

registerLocale('es', es);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

if (['v2', 'prod', 'oms'].some((st) => String(window?.location?.hostname ?? '').includes(st))) {
    Sentry.init({
        dsn: 'https://c3b0f1df2122736a69d0754bb18a4f7e@o4505092050911232.ingest.sentry.io/4506243263692800',
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
            }),
            new Sentry.Replay(),
        ],
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.8,
    });
}

root.render(
    <Provider store={store}>
        <SEO />
        <CheckOnLine />
        <Toaster />
        <ConfigProvider locale={esES}>
            <App />
        </ConfigProvider>
    </Provider>
);
