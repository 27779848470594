import regionsData from '../../../../data/regionsData';

import zipsMexico from '../../../../data/zipMexico';

export const deliveriesDeliveryMethodOptions = [
    {
        label: 'Retiro',
        value: 'pickup',
    },
    { label: 'Retiro con consolidación', value: 'pickup_consolidation' },
    {
        label: 'Despacho',
        value: 'shipping',
    },
    { label: 'Despacho con consolidación', value: 'shipping_consolidation' },
    { label: 'Retiro en punto de tercero', value: 'pickup_point' },
];

export const deliveriesFulfillmentStatusOptions = [
    {
        label: 'Listo para procesar entrega',
        value: 'success',
    },
    {
        label: 'Entregado',
        value: 'delivered',
    },
    {
        label: 'Despachado',
        value: 'delivered_to_courier',
    },
    // {
    //     label: 'Parcialmente procesado',
    //     value: 'partially_processed',
    // },
    // {
    //     label: 'Todos los bultos procesados',
    //     value: 'all_packages_processed',
    // },
];

export const deliveriesRegionOptions = regionsData.regions.map((region) => ({
    label: region.name,
    value: region.name,
}));

export const deliveriesMxZipOptions = zipsMexico.zips.map((zip) => ({
    label: zip.zip,
    value: zip.zip,
}));

export const deliveriesMxNeighborhoodOptions = zipsMexico.zips.reduce((acc, zip) => {
    zip.neighborhoods.forEach((neighborhood) => {
        if (!acc.some((el) => el.value === neighborhood)) {
            acc.push({ label: neighborhood, value: neighborhood });
        }
    });

    return acc;
}, []);
