/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($merchant_id: String, $user_id: String) {
    onCreateMessage(merchant_id: $merchant_id, user_id: $user_id) {
      action
      id
      merchant_id
      message
      user_id
      __typename
    }
  }
`;
export const onCreateRoom = /* GraphQL */ `
  subscription OnCreateRoom($merchant_id: String, $target_id: String) {
    onCreateRoom(merchant_id: $merchant_id, target_id: $target_id) {
      id
      block_actions
      block_reason
      merchant_id
      target_id
      user_id
      active_users
      __typename
    }
  }
`;
export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom($merchant_id: String, $target_id: String) {
    onUpdateRoom(merchant_id: $merchant_id, target_id: $target_id) {
      id
      block_actions
      block_reason
      merchant_id
      target_id
      user_id
      active_users
      __typename
    }
  }
`;
export const onDeleteRoom = /* GraphQL */ `
  subscription OnDeleteRoom($merchant_id: String, $target_id: String) {
    onDeleteRoom(merchant_id: $merchant_id, target_id: $target_id) {
      id
      block_actions
      block_reason
      merchant_id
      target_id
      user_id
      active_users
      __typename
    }
  }
`;
