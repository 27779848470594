import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

// new createApi
import { activitiesApi } from './features/Activities';
import { ArrivalsPendingApi } from './features/ArrivalsPending';
import { batchDeliversApi } from './features/BatchDelivers';
import { courierAddressMappingsApi } from './features/CourierAddressMappings';
import { courierExceptionsApi } from './features/CourierExceptions';
import { couriersApi } from './features/Couriers';
import { deliveriesApi } from './features/Deliveries';
import { emailNotificationApi } from './features/EmailMerchant';
import { errorsApi } from './features/Errors';
import { featureFlagApi } from './features/FeatureFlag';
import { fulfillmentsApi } from './features/Fulfillments';
import { incidentsApi } from './features/Incidents';
import { integrationApi } from './features/Integrations';
import { JsonImporterApi } from './features/JsonImporter';
import { locationsApi } from './features/Locations';
import { logsApi } from './features/Logs';
import { logMessagesApi } from './features/Logs/logMessages';
import { mappingsApi } from './features/Mappings';
import { masterStatesApi } from './features/MasterStates';
import { metafieldsApi } from './features/Metafields';
import { notificationsApi } from './features/Notifications';
import { ordersApi } from './features/Orders';
import { getAllDocuments } from './features/Orders/getAllDocuments';
import { updateOrderCustomerApi } from './features/Orders/updateOrderCustomer';
import { ordersConfigurationApi } from './features/OrdersConfiguration';
import { packageTypesApi } from './features/PackageTypes';
import { preparationsApi } from './features/Preparations';
import { reportsApi } from './features/Reports';
import { orderReportsApi } from './features/Reports/orderReports';
import { sapApi } from './features/SAP';
import { salesChannelAPI } from './features/SalesChannels';
import { stateChangeTraceabilityApi } from './features/StateChangeTraceability';
import { stocksApi } from './features/Stock';
import { usersApi } from './features/Users';
import { webhooksApi } from './features/Webhooks';
import { statesValidationApi } from './features/StatesValidation';

// Utils
import utils from './features/Utils';

// Preparations
import preparationsFilters from './features/Preparations/preparationsFilters';

// Pending Arrivals
import pendingArrivalsFilters from './features/PendingArrivals/pendingArrivalsFilters';

// Crossdocking
import crossdockingFilters from './features/Crossdocking/crossdockingFilters';

// Deliveries
import deliveriesFilters from './features/Deliveries/deliveriesFilters';

// Manifests
import manifestsFilters from './features/Manifests/manifestsFilters';

// Orders
import assignOrdersSlice from './features/Orders/assignResponsables';
import cancelOrderReducer from './features/Orders/cancelOrder';
import updateOrderStatusReducer from './features/Orders/changeStatus';
import filtersOrders from './features/Orders/filtersOrders';
import ordersReducer from './features/Orders/getAllOrders';
import getFilteredOrdersReducer from './features/Orders/getFilteredOrders';
import fulfilledOrdersReducer from './features/Orders/getFulfilledOrders';
import getManyOrdersByCustomReducer from './features/Orders/getManyOrdersByCustom';
import orderReducer from './features/Orders/getOrderById';
import orderDocumentsReducer from './features/Orders/getOrderDocuments';
import lineItemsCancel from './features/Orders/lineItemsCancel';
import orderTabFiltersReducer from './features/Orders/orderTabFilters';
import orderV3TabFiltersReducer from './features/Orders/orderV3TabFilters';
import removeShippingPrice from './features/Orders/removeShippingPrice';
import sortOrders from './features/Orders/sortOrders';
import updateOrder from './features/Orders/updateOrder';
import cashOnDelivery from './features/Orders/cashOnDelivery';

// Integrations
import createIntegrationReducer from './features/Integrations/createIntegration';
import deleteIntegrationReducer from './features/Integrations/deleteIntegration';
import editIntegrationReducer from './features/Integrations/editIntegration';
import getAllIntegrationReducer from './features/Integrations/getAllIntegration';

// Couriers
import createCourierReducer from './features/Couriers/createCourier';
import deleteCourierReducer from './features/Couriers/deleteCourier';
import editCourierReducer from './features/Couriers/editCourier';
import getAllCouriersReducer from './features/Couriers/getAllCouriers';
import getDynamicTableReducer from './features/Couriers/getDynamicTable';

// SalesChannels
import changeStatusManySalesChannelsReducer from './features/SalesChannels/changeStatusManySalesChannels';
import createSalesChannelReducer from './features/SalesChannels/createSalesChannel';
import editSalesChannelReducer from './features/SalesChannels/editSalesChannel';
import getAllSalesChannelReducer from './features/SalesChannels/getAllSalesChannel';
import getPosSalesChannelsReducer from './features/SalesChannels/getPosSalesChannel';
import getPossibleSalesChannelsReducer from './features/SalesChannels/getPossibleSalesChannels';

// Shops/Warehouse
import changeStatusManyShopsReducer from './features/Shops/changeStatusManyShops';
import createShopReducer from './features/Shops/createShops';
import editShopReducer from './features/Shops/editShops';
import filterShopReducer from './features/Shops/filtersShops';
import getAllShopReducer from './features/Shops/getAllShops';

// Perfiles
import createProfileReducer from './features/Profiles/createProfile';
import deleteProfileReducer from './features/Profiles/deleteProfile';
import editProfileReducer from './features/Profiles/editProfile';
import getAllProfilesReducer from './features/Profiles/getAllProfiles';

// Pickups preferences
import createPickupPreferenceReducer from './features/PickupsPreferences/createPickupPreference';
import editPickupPreferenceReducer from './features/PickupsPreferences/editPickupPreference';
import getPickupPreferenceByMerchantReducer from './features/PickupsPreferences/getPickupPreferenceByMerchant';

// Preparations preferences
import createPreparationPreferenceReducer from './features/PreparationsPreferences/createPreparationPreference';
import editPreparationPreferenceReducer from './features/PreparationsPreferences/editPreparationPreference';
import getClickAndCollectStatesReducer from './features/PreparationsPreferences/getClickAndCollectStates';
import getPreparationPreferenceByMerchantReducer from './features/PreparationsPreferences/getPreparationPreferenceByMerchant';

// Usuarios
import changeBlurEmailReducer from './features/Users/changeBlurEmail';
import changeBlurUsernameReducer from './features/Users/changeBlurUsername';
import changeStatusManyUsersReducer from './features/Users/changeStatusManyUsers';
import createUserReducer from './features/Users/createUser';
import editUserReducer from './features/Users/editUser';
import getAllUsersReducer from './features/Users/getAllUsers';
import getPreparersByCustomReducer from './features/Users/getPreparersByCustom';
import getUserLoggedReducer from './features/Users/getUserLogged';

// General
import createGeneralReducer from './features/General/createGeneral';
import editGeneralReducer from './features/General/editGeneral';
import getGeneralByMerchantReducer from './features/General/getGeneralByMerchant';
import { generalApi } from './features/General/index';

// Permissions
import getAllPermissionsReducer from './features/Permissions/getPermissions';
import getPermissionsOfCurrentUser from './features/Permissions/getPermissionsOfCurrentUser';

// Stripe
import cancelSubscription from './features/Stripe/cancelSubscription';
import createStripeSession from './features/Stripe/createSession';
import getStripeCustomer from './features/Stripe/getCustomer';
import getStripePlans from './features/Stripe/getPlans';

// Fulfillments
import createBatchFulfillments from './features/Fulfillments/batchCreateFulfillment';
import batchDeliverFulfillments from './features/Fulfillments/batchDeliverFulfillments';
import cancelFulfillment from './features/Fulfillments/cancelFulfillment';
import completeFulfillment from './features/Fulfillments/completeFulfillment';
import createFulfillment from './features/Fulfillments/createFulfillment';
import deliverFulfillment from './features/Fulfillments/deliverFulfillment';
import filtersArrivalsPending from './features/Fulfillments/filtersArrivalsPending';
import filtersDeliveries from './features/Fulfillments/filtersDeliveries';
import filtersPreparations from './features/Fulfillments/filtersPreparations';
import fulfillments from './features/Fulfillments/getAll';
import batchFulfillments from './features/Fulfillments/getBatchFulfillments';
import getCourierRate from './features/Fulfillments/getCourierRate';
import inProgressFulfillments from './features/Fulfillments/getInProgressFulfillments';
import fulfillment from './features/Fulfillments/getOne';
import pendingArrivalsFulfillments from './features/Fulfillments/getPendingArrivalsFulfillments';
import successFulfillments from './features/Fulfillments/getSuccessFulfillments';
import pauseFulfillment from './features/Fulfillments/pauseFulfillment';
import replaceItemsIncidence from './features/Fulfillments/replaceItemsIncidence';
import separateTicketsEnviame from './features/Fulfillments/separateTicketsEnviame';
import updateFulfillment from './features/Fulfillments/updateFulfillment';
import preparationState from './features/Preparations/preparationState';

// external
import getHolidaysForMonthsReducer from './external/Holidays/getHolidaysForMonths';
import dataByAddress from './external/Places/dataByAddress';
import getAutocompleteAddressReducer from './external/Places/getAutocompleteAddress';
import getDataByAddressReducer from './external/Places/getDataByAddress';
import getNewSlaDate from './external/Sla/getNewSlaDate';

// Packages
import addMarketingProductsToPackage from './features/Packages/addMarketingProductsToPakage';
import addToPackage from './features/Packages/addToPackage';
import createPackage from './features/Packages/createPackage';
import deletePackage from './features/Packages/deletePackage';
import processPackage from './features/Packages/processPackage';
import updatePackage from './features/Packages/updatePackage';

// Stock
import findProductsReducer from './features/Stock/findProducts';
import findStockOfLineItemsPerLocationReducer from './features/Stock/findStockOfLineItemsPerLocation';

// Global Actions Order
import changeStoreGAOD from './features/GlobalActionsOrder/changeStoreGAOD';
import globalActionsOrderDrawer from './features/GlobalActionsOrder/globalActionsOrderDrawer';

//------- SOLVE PARTIALLY - ORDER ---------
import solveOrderData from './features/SolveOrder/solveOrderData';
/* alternative solutions */
import SOreplaceProducts from './features/SolveOrder/AlternativeSolutions/SOreplaceProducts';
/* warehouse avaibility verification solution */
import SOwarehouseAvailabilityVerification from './features/SolveOrder/WarehouseAvailabilityVerification/SOwarehouseAvailabilityVerification';

// Manual Incidences
import changeStoreFulfillment from './features/Fulfillments/changeStoreFulfillment';
import { manualIncidencesApi } from './features/ManualIncidences';
import createLineItemsManualIncidence from './features/ManualIncidences/createLineItemsManualIncidence';
import createOrderManualIncidence from './features/ManualIncidences/createOrderManualIncidence';
import detailsManualIncidenceDrawer from './features/ManualIncidences/detailsManualIncidenceDrawer';
import detailsManualIncidenceOrderDrawer from './features/ManualIncidences/detailsManualIncidenceOrderDrawer';

// Change Store
import changeStore from './features/ChangeStore';
import { masterCouriersApi } from './features/Master';

// Promise Engine
import { promiseEngineApi } from './features/PromiseEngine';

import { returnPreferenceApi } from './features/ReturnPreference';

const apis_middlewares = [
    logsApi.middleware,
    metafieldsApi.middleware,
    reportsApi.middleware,
    packageTypesApi.middleware,
    webhooksApi.middleware,
    masterStatesApi.middleware,
    errorsApi.middleware,
    updateOrderCustomerApi.middleware,
    orderReportsApi.middleware,
    emailNotificationApi.middleware,
    mappingsApi.middleware,
    deliveriesApi.middleware,
    batchDeliversApi.middleware,
    salesChannelAPI.middleware,
    usersApi.middleware,
    ArrivalsPendingApi.middleware,
    notificationsApi.middleware,
    courierExceptionsApi.middleware,
    stateChangeTraceabilityApi.middleware,
    ordersApi.middleware,
    fulfillmentsApi.middleware,
    ordersConfigurationApi.middleware,
    preparationsApi.middleware,
    stocksApi.middleware,
    sapApi.middleware,
    JsonImporterApi.middleware,
    integrationApi.middleware,
    getAllDocuments.middleware,
    activitiesApi.middleware,
    logMessagesApi.middleware,
    incidentsApi.middleware,
    masterCouriersApi.middleware,
    courierAddressMappingsApi.middleware,
    locationsApi.middleware,
    manualIncidencesApi.middleware,
    couriersApi.middleware,
    promiseEngineApi.middleware,
    featureFlagApi.middleware,
    generalApi.middleware,
    returnPreferenceApi.middleware,
    statesValidationApi.middleware,
];

const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(apis_middlewares),
    reducer: {
        // Acciones utiles
        utils,
        // Acciones de usuarios
        createUser: createUserReducer,
        getAllUsers: getAllUsersReducer,
        editUser: editUserReducer,
        changeStatusManyUsers: changeStatusManyUsersReducer,
        changeBlurUsername: changeBlurUsernameReducer,
        changeBlurEmail: changeBlurEmailReducer,
        userLogged: getUserLoggedReducer,
        getPreparersByCustom: getPreparersByCustomReducer,
        // Acciones de preparaciones
        preparationsFilters,
        preparationState,
        // Acciones de pending arrivals
        pendingArrivalsFilters,
        crossdockingFilters,
        // Acciones de deliveries
        deliveriesFilters,
        // Acciones manifiestos
        manifestsFilters,
        // Acciones de ordenes
        sortOrders,
        filtersOrders,
        getFilteredOrders: getFilteredOrdersReducer,
        fulfilledOrders: fulfilledOrdersReducer,
        orders: ordersReducer,
        order: orderReducer,
        cancelOrder: cancelOrderReducer,
        orderTabFilters: orderTabFiltersReducer,
        orderV3TabFilters: orderV3TabFiltersReducer,
        cashOnDelivery,
        orderDocuments: orderDocumentsReducer,
        assignResponsables: assignOrdersSlice,
        updateOrderStatus: updateOrderStatusReducer,
        getManyOrdersByCustom: getManyOrdersByCustomReducer,
        removeShippingPrice,
        updateOrder,
        lineItemsCancel,
        getAllDocuments,
        // Acciones de integraciones
        createIntegrations: createIntegrationReducer,
        getAllIntegrations: getAllIntegrationReducer,
        editIntegrations: editIntegrationReducer,
        deleteIntegrations: deleteIntegrationReducer,
        // Acciones de couriers
        createCourier: createCourierReducer,
        getAllCouriers: getAllCouriersReducer,
        editCourier: editCourierReducer,
        deleteCourier: deleteCourierReducer,
        getDynamicTable: getDynamicTableReducer,
        // Acciones de canales_venta
        createSalesChannel: createSalesChannelReducer,
        getAllSalesChannel: getAllSalesChannelReducer,
        editSalesChannel: editSalesChannelReducer,
        changeStatusManySalesChannels: changeStatusManySalesChannelsReducer,
        getPossibleSalesChannels: getPossibleSalesChannelsReducer,
        getPosSalesChannels: getPosSalesChannelsReducer,
        // Acciones de Tiendas/Bodega
        createShop: createShopReducer,
        getAllShops: getAllShopReducer,
        editShop: editShopReducer,
        changeStatusManyShops: changeStatusManyShopsReducer,
        filtersShops: filterShopReducer,
        // Acciones de perfiles
        createProfile: createProfileReducer,
        getAllProfiles: getAllProfilesReducer,
        editProfile: editProfileReducer,
        deleteProfile: deleteProfileReducer,
        // Acciones de pickups-preferences
        createPickupPreference: createPickupPreferenceReducer,
        getPickupPreferenceByMerchant: getPickupPreferenceByMerchantReducer,
        editPickupPreference: editPickupPreferenceReducer,
        // Acciones de preparations-preferences
        createPreparationPreference: createPreparationPreferenceReducer,
        getPreparationPreferenceByMerchant: getPreparationPreferenceByMerchantReducer,
        getClickAndCollectStates: getClickAndCollectStatesReducer,
        editPreparationPreference: editPreparationPreferenceReducer,
        // Acciones de generals
        createGeneral: createGeneralReducer,
        getGeneralByMerchant: getGeneralByMerchantReducer,
        editGeneral: editGeneralReducer,
        // Acciones de permissions
        getAllPermissions: getAllPermissionsReducer,
        getPermissionsOfCurrentUser: getPermissionsOfCurrentUser,
        // Stripe
        getStripePlans: getStripePlans,
        getStripeCustomer: getStripeCustomer,
        createStripeSession: createStripeSession,
        cancelSubscription: cancelSubscription,
        // External
        getHolidaysForMonths: getHolidaysForMonthsReducer,
        getDataByAddress: getDataByAddressReducer,
        getAutocompleteAddress: getAutocompleteAddressReducer,
        dataByAddress,
        getNewSlaDate,
        // Fulfillments
        createFulfillment: createFulfillment,
        createBatchFulfillments,
        updateFulfillment: updateFulfillment,
        changeStoreFulfillment,
        deliverFulfillment,
        successFulfillments,
        inProgressFulfillments,
        pendingArrivalsFulfillments,
        completeFulfillment,
        pauseFulfillment,
        fulfillment,
        replaceItemsIncidence,
        batchFulfillments,
        batchDeliverFulfillments,
        getCourierRate,
        separateTicketsEnviame,
        cancelFulfillment,
        filtersPreparations,
        filtersDeliveries,
        filtersArrivalsPending,
        // Packages
        addToPackage,
        updatePackage,
        processPackage,
        deletePackage,
        createPackage,
        fulfillments,
        addMarketingProductsToPackage,
        // Stock
        findStockOfLineItemsPerLocation: findStockOfLineItemsPerLocationReducer,
        findProducts: findProductsReducer,
        // Solve Order
        solveOrderData,
        SOreplaceProducts,
        SOwarehouseAvailabilityVerification,
        // Global Actions Order
        globalActionsOrderDrawer,
        changeStoreGAOD,
        // Manual Incidents
        createLineItemsManualIncidence,
        createOrderManualIncidence,
        detailsManualIncidenceDrawer,
        detailsManualIncidenceOrderDrawer,
        // Change store
        changeStore,
        // Logs
        [logsApi.reducerPath]: logsApi.reducer,
        // Metafields,
        [metafieldsApi.reducerPath]: metafieldsApi.reducer,
        // Reports
        [reportsApi.reducerPath]: reportsApi.reducer,
        // Package Types
        [packageTypesApi.reducerPath]: packageTypesApi.reducer,
        // Webhooks
        [webhooksApi.reducerPath]: webhooksApi.reducer,
        // Master States
        [masterStatesApi.reducerPath]: masterStatesApi.reducer,
        // Actividades / Giros
        [activitiesApi.reducerPath]: activitiesApi.reducer,
        // Errores
        [errorsApi.reducerPath]: errorsApi.reducer,
        // Update Order Customer
        [updateOrderCustomerApi.reducerPath]: updateOrderCustomerApi.reducer,
        // Order Reports
        [orderReportsApi.reducerPath]: orderReportsApi.reducer,
        // Update email reports
        [emailNotificationApi.reducerPath]: emailNotificationApi.reducer,
        // Mappings
        [mappingsApi.reducerPath]: mappingsApi.reducer,
        // Mappings
        [deliveriesApi.reducerPath]: deliveriesApi.reducer,
        // Batch Delivers
        [batchDeliversApi.reducerPath]: batchDeliversApi.reducer,
        // Sales channels
        [salesChannelAPI.reducerPath]: salesChannelAPI.reducer,
        // users
        [usersApi.reducerPath]: usersApi.reducer,
        // Arrivals Pending
        [ArrivalsPendingApi.reducerPath]: ArrivalsPendingApi.reducer,
        // Notifications
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        // Courier Exceptions
        [courierExceptionsApi.reducerPath]: courierExceptionsApi.reducer,
        // Trazabilidad en cambios de estados
        [stateChangeTraceabilityApi.reducerPath]: stateChangeTraceabilityApi.reducer,
        // Ordenes
        [ordersApi.reducerPath]: ordersApi.reducer,
        // Fulfillments
        [fulfillmentsApi.reducerPath]: fulfillmentsApi.reducer,
        [ordersConfigurationApi.reducerPath]: ordersConfigurationApi.reducer,
        // Preparaciones
        [preparationsApi.reducerPath]: preparationsApi.reducer,
        // Stocks
        [stocksApi.reducerPath]: stocksApi.reducer,
        // sap
        [sapApi.reducerPath]: sapApi.reducer,
        //json importer
        [JsonImporterApi.reducerPath]: JsonImporterApi.reducer,
        // Integrations
        [integrationApi.reducerPath]: integrationApi.reducer,
        [getAllDocuments.reducerPath]: getAllDocuments.reducer,
        [logMessagesApi.reducerPath]: logMessagesApi.reducer,
        [incidentsApi.reducerPath]: incidentsApi.reducer,
        [masterCouriersApi.reducerPath]: masterCouriersApi.reducer,
        // Courier Address Mappings
        [courierAddressMappingsApi.reducerPath]: courierAddressMappingsApi.reducer,
        // Locations
        [locationsApi.reducerPath]: locationsApi.reducer,
        [manualIncidencesApi.reducerPath]: manualIncidencesApi.reducer,
        // Couriers
        [couriersApi.reducerPath]: couriersApi.reducer,
        // Promise Engine
        [promiseEngineApi.reducerPath]: promiseEngineApi.reducer,
        // Feature Flag
        [featureFlagApi.reducerPath]: featureFlagApi.reducer,
        // Feature Flag
        [generalApi.reducerPath]: generalApi.reducer,
        // Return Preference
        [returnPreferenceApi.reducerPath]: returnPreferenceApi.reducer,
        // States Validation
        [statesValidationApi.reducerPath]: statesValidationApi.reducer,
    },
});

setupListeners(store.dispatch);

export default store;
