import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getOrderById } from './getOrderById';
import { toggleDrawer, toggleModal } from '../Utils';

export const updateOrderSacStatus = createAsyncThunk('orders/update', async (data, state) => {
    try {
        const promise = AndesClient.post('/ordersv2/change-status-sac', data);
        const states = state.getState();

        console.log({ data, promise, state, states });

        data = data?.order_id ? data : data[0];

        toast.promise(promise, {
            loading: (
                <b>
                    Actualizando el estado SAC del {data?.fulfillment_id ? 'fulfillment' : 'pedido'}
                </b>
            ),
            success: (
                <b>
                    Estado SAC del {data?.fulfillment_id ? 'fulfillment' : 'pedido'} actualizado con
                    éxito
                </b>
            ),
            error: (
                <b>
                    Ocurrió un error al cambiar el estado SAC del{' '}
                    {data?.fulfillment_id ? 'fulfillment' : 'pedido'}.
                </b>
            ),
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(
                toggleModal(
                    data?.fulfillment_id
                        ? 'fulfillment-change-sac-status-modal'
                        : 'order-change-sac-status-modal'
                )
            );
            state.dispatch(getOrderById(data.order_id));

            // Cerramos el modal de preparacion global
            if (states.utils?.drawer['global-actions-order-drawer']) {
                state.dispatch(toggleDrawer('global-actions-order-drawer'));
            }
        }

        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const updateOrderSacStatusSlice = createSlice({
    name: 'updateOrderSacStatus',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [updateOrderSacStatus.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [updateOrderSacStatus.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [updateOrderSacStatus.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default updateOrderSacStatusSlice.reducer;
