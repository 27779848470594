import {
    deliveriesFulfillmentStatusOptions,
    deliveriesDeliveryMethodOptions,
    deliveriesMxNeighborhoodOptions,
    deliveriesMxZipOptions,
} from './values';
import { deliveriesFilterFields } from './fields';

export const deliveriesFilters = {
    fields: [...deliveriesFilterFields],
    values: {
        status: [...deliveriesFulfillmentStatusOptions],
        delivery_method: [...deliveriesDeliveryMethodOptions],
        'destination.neighborhood': [...deliveriesMxNeighborhoodOptions],
        'destination.zip_code': [...deliveriesMxZipOptions],
    },
};
