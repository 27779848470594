/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
    mutation CreateMessage(
        $action: String
        $merchant_id: String
        $message: String
        $user_id: String
    ) {
        createMessage(
            action: $action
            merchant_id: $merchant_id
            message: $message
            user_id: $user_id
        ) {
            action
            id
            merchant_id
            message
            user_id
            __typename
        }
    }
`;
export const createRoom = /* GraphQL */ `
    mutation CreateRoom($input: CreateRoomInput!) {
        createRoom(input: $input) {
            id
            block_actions
            block_reason
            merchant_id
            target_id
            user_id
            active_users
            __typename
        }
    }
`;
export const updateRoom = /* GraphQL */ `
    mutation UpdateRoom($input: UpdateRoomInput!) {
        updateRoom(input: $input) {
            id
            block_actions
            block_reason
            merchant_id
            target_id
            user_id
            active_users
            __typename
        }
    }
`;
export const deleteRoom = /* GraphQL */ `
    mutation DeleteRoom($input: DeleteRoomInput!) {
        deleteRoom(input: $input) {
            id
            block_actions
            block_reason
            merchant_id
            target_id
            user_id
            active_users
            __typename
        }
    }
`;
