// import useStatesValidation from 'hooks/useStatesValidation';
import { toggleModal } from 'redux/features/Utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'components/Modal';
import { useStatesValidationMutation } from 'redux/features/StatesValidation';
import { BounceLoader } from 'react-spinners';
import { CheckIcon } from '@heroicons/react/outline';

const StatesValidationModal = ({
    fulfillmentIds,
    action,
    showStatesValidationModal,
    setShowStatesValidationModal,
    setStatesValidationSuccess,
    setFulfillmentIdsValidated,
    handleRefetch,
    justRefetchAfterClose = false,
}) => {
    const [successChanges, setSuccessChanges] = useState([]);
    const [errorChanges, setErrorChanges] = useState([]);
    const [cancelButtonText, setCancelButtonText] = useState('Cerrar');
    const [showOkButton, setShowOkButton] = useState(false);
    const [showCheckIcon, setShowCheckIcon] = useState(false);

    const modalName = 'states-validation-modal';
    const dispatch = useDispatch();

    const [statesValidation, { data, isLoading }] = useStatesValidationMutation({});

    const unmountComponent = (opening) => {
        if (!opening) {
            setShowStatesValidationModal(false);
            setSuccessChanges([]);
            setErrorChanges([]);
        } else {
            setFulfillmentIdsValidated([]);
            setSuccessChanges([]);
            setErrorChanges([]);
            setCancelButtonText('Cerrar');
        }
    };

    const closeModal = () => {
        if (!successChanges.length && errorChanges.length && handleRefetch) {
            handleRefetch(true);
        }

        dispatch(toggleModal(modalName));
        unmountComponent();
    };

    const handleContinue = () => {
        setStatesValidationSuccess(true);

        if (handleRefetch && !justRefetchAfterClose) {
            handleRefetch(true);
        }

        closeModal();
    };

    useEffect(() => {
        if (data) {
            setErrorChanges(Array.from(data?.error ?? []));
            setSuccessChanges(Array.from(data?.success ?? []));

            if (data?.success?.length && !data?.error?.length) {
                setShowCheckIcon(true);

                setTimeout(() => {
                    setShowCheckIcon(false);
                    unmountComponent();
                    handleContinue();
                }, 800);
            }

            const validFulfillments = data?.success.map((item) => item.fulfillment_id);

            setFulfillmentIdsValidated(validFulfillments);

            if (data?.success?.length && data?.error?.length) {
                setCancelButtonText('Cancelar');
                setShowOkButton(true);
            }

            if (data?.error?.length && !data?.success?.length) {
                setCancelButtonText('Cerrar');
                setShowOkButton(false);
            }

            if (data?.success?.length && !data?.error?.length) {
                setShowOkButton(false);
            }
        }
    }, [data]);

    useEffect(() => {
        if (showStatesValidationModal) {
            statesValidation({
                fulfillment_ids: fulfillmentIds,
                action,
            });
        }

        return () => {
            unmountComponent(true);
        };
    }, [showStatesValidationModal]);

    return (
        <Modal
            modalName={modalName}
            open={showStatesValidationModal}
            onCancel={closeModal}
            title="Verificando los estados de los fulfillments"
            okText="Confirmar"
            cancelText={cancelButtonText ?? 'Cerrar'}
            onOk={handleContinue}
            cancelButtonProps={{
                style: {
                    display:
                        isLoading || (successChanges.length && !errorChanges.length)
                            ? 'none'
                            : 'block',
                },
            }}
            okButtonProps={{
                disabled: isLoading || !successChanges.length,
                style: {
                    display: showOkButton && !isLoading ? 'block' : 'none',
                },
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <BounceLoader size="50" loading={isLoading} color="rgb(3, 104, 150)" />
                {showCheckIcon ? <CheckIcon width="50px" height="50px" color="#089460" /> : null}
            </div>

            <div
                style={{
                    display: successChanges.length && !errorChanges.length ? 'none' : 'block',
                }}
            >
                {errorChanges.length ? (
                    <div style={{ marginBottom: '20px' }}>
                        <h3
                            style={{
                                color: 'red',
                                marginBottom: '10px',
                            }}
                        >
                            Por favor verifique que los siguientes fulfillments tengan el estado
                            correcto para ejecutar esta acción:
                        </h3>
                        <ul>
                            {errorChanges.map((item) => (
                                <li key={item.name}>{item.name}</li>
                            ))}
                        </ul>

                        {successChanges.length ? (
                            <hr
                                style={{
                                    marginTop: '20px',
                                }}
                            />
                        ) : null}
                    </div>
                ) : null}

                {successChanges.length ? (
                    <div>
                        <h3
                            style={{
                                color: 'rgb(45, 61, 118)',
                                marginBottom: '10px',
                            }}
                        >
                            Los siguientes fulfillments cumplen con los criterios para ejecutar esta
                            acción:
                        </h3>

                        <ul style={{ marginBottom: '20px' }}>
                            {successChanges.map((item) => (
                                <li key={item.name}>{item.name}</li>
                            ))}
                        </ul>

                        {successChanges.length && errorChanges.length ? (
                            <span
                                style={{
                                    color: '#666666',
                                    fontSize: '12px',
                                }}
                            >
                                <b>Nota:</b> Únicamente seran procesados los fulfillments que
                                cumplan con los criterios para ejecutar esta acción
                            </span>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </Modal>
    );
};

export default StatesValidationModal;
