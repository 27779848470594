import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import OrderJsonPage from '../OrderJson';
import { useGetOrderQuery } from 'redux/features/Orders';
import Header from './components/Header/Header';
import FulfillmentsSection from './components/Fulfillments';
import OrderData from './components/OrderData';
import OrderStates from './components/OrderStates';
import Container from 'components/Grid/Container';
import { useEffect, useState } from 'react';
// import { OrderSummary } from './components/Fulfillments/orderSummary';
import NotFoundPage from 'pages/NotFound';
import { LoadingLayout } from 'pages/NotFound/loadingLayout';
import { useGetOrdersConfigurationQuery } from 'redux/features/OrdersConfiguration';
import { useGetReturnPreferenceByMerchantQuery } from 'redux/features/ReturnPreference';
import useRoomListener from 'hooks/useRoomListener';
// import useCurrentUser from 'hooks/useCurrentUser';
import LockScreen from 'components/LockScreen';

import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';

const PageLayout = styled.div`
    padding: 1rem 1rem 2.5rem 1rem;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr minmax(300px, 500px);

    @media (max-width: 1080px) {
        grid-template-columns: 1fr;
    }

    .order-page__header {
        grid-row: 1;
        grid-column: span 2;

        @media (max-width: 1080px) {
            grid-row: auto;
            grid-column: auto;
        }
    }

    .order-page__fulfillments {
        grid-row: 2;
        grid-column: 1;

        @media (max-width: 1080px) {
            grid-row: 2;
            grid-column: auto;
        }
    }

    .order-page__order-data {
        grid-row: span 3;
        grid-column: 2;

        @media (max-width: 1080px) {
            grid-row: auto;
            grid-column: auto;
        }
    }
    .order-page_summary {
        grid-row: 3;
        grid-column: auto;
        margin-top: -18px;
        @media (max-width: 1080px) {
            grid-row: 3;
            grid-column: auto;
        }
    }

    .order-page__order-timeline {
        grid-row: auto;
        grid-column: auto;
    }
`;

const OrderPage = () => {
    const { order_id } = useParams();

    const [hasLock, setHasLock] = useState(false);

    const queryStrings = new URLSearchParams(window.location.search);
    const fulfillmentId = queryStrings.get('fulfillment_id');

    const hasRoomEnabled = useGetValueFeatureFlag('rooms');

    const [infoSelected, setInfoSelected] = useState(null);

    if (String(order_id ?? '').includes('.json') || String(order_id ?? '').includes('.logs')) {
        return (
            <OrderJsonPage
                order_id={String(order_id).replace('.json', '').replace('.logs', '')}
                onlyLogs={String(order_id ?? '').includes('.logs')}
            />
        );
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const queryStrings = new URLSearchParams(window.location.search);
            const lock = queryStrings.get('lock');
            if (lock === 'true') setHasLock(true);
        }, 500);

        return () => {
            const url = new URL(window.location.href);
            url.searchParams.delete('lock');
            window.location.href = url.toString();
            clearInterval(interval);
        };
    }, []);

    const {
        isLoading,
        data = {},
        isFetching,
        refetch,
    } = useGetOrderQuery(order_id, { refetchOnMountOrArgChange: true });

    // const user = useCurrentUser();

    const { data: returnPreferences } = useGetReturnPreferenceByMerchantQuery(
        {
            merchant_id: data.merchant_id,
        },
        { skip: data.merchant_id }
    );

    const { data: orderConfiguration } = useGetOrdersConfigurationQuery();

    const deliveryPromiseDateFormat = orderConfiguration?.delivery_promise_date_format;

    const room = useRoomListener(data?._id);

    console.log({ room });

    useEffect(() => {
        const initializeRoom = async () => {
            try {
                if (room.isInitialized && hasRoomEnabled) {
                    await new Promise((resolve) => setTimeout(resolve, Math.random() * 500));
                    await room.createRoom({
                        block_actions: false,
                        block_reason: 'esto no se usara de momento',
                    });
                }
            } catch (error) {
                console.log({ error });
            }
        };

        initializeRoom();
    }, [data, room.isInitialized, hasRoomEnabled]);

    const showLoading = isLoading && isFetching;
    // || room.isLoading;

    useEffect(() => {
        const forceRefetchWithButton = (event) => {
            const key = event?.key;
            if (key === '.' && !showLoading && data?._id) {
                refetch(true);
            }
        };

        document.addEventListener('keypress', forceRefetchWithButton);

        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton);
        };
    }, [showLoading, data]);

    useEffect(() => {
        if (fulfillmentId && data?.fulfillments) {
            const element = document.getElementById(`fulfillment_${fulfillmentId}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                window.history.replaceState({}, document.title, window.location.pathname);
            }
        }
    }, [fulfillmentId, data]);

    // const block_actions = room?.lastRoomState?.block_actions && !room.isSameUser;
    // const block_reason = room?.lastRoomState?.block_reason;

    const activeUsers = room?.lastRoomState?.active_users ?? [];

    return (
        <>
            {hasLock && <LockScreen />}
            {showLoading ? (
                <LoadingLayout />
            ) : Object.keys(data).length === 0 ? (
                <NotFoundPage />
            ) : (
                <Container
                    extraTitle={
                        !showLoading ? `Pedido ${data?.remote_order_id ?? ''}` : 'Cargando...'
                    }
                >
                    <PageLayout>
                        <div className="order-page__header">
                            <Header
                                loading={showLoading}
                                data={data}
                                refetch={refetch}
                                // block_actions={block_actions}
                                // block_reason={block_reason}
                            />
                        </div>
                        <div className="order-page__order-data">
                            <OrderData
                                returnPreferences={returnPreferences}
                                data={data}
                                infoSelected={infoSelected}
                                loading={showLoading}
                                refetch={refetch}
                                setInfoSelected={setInfoSelected}
                                active_users={hasRoomEnabled ? activeUsers : []}
                            />
                        </div>

                        <div className="order-page__fulfillments">
                            <FulfillmentsSection
                                returnPreferences={returnPreferences}
                                deliveryPromiseDateFormat={deliveryPromiseDateFormat}
                                loading={showLoading}
                                data={data}
                                setInfoSelected={setInfoSelected}
                                infoSelected={infoSelected}
                                refetch={refetch}
                                // block_actions={block_actions}
                            />
                        </div>

                        {/* <div className="order-page_summary">
                            <OrderSummary orderData={data} loading={showLoading} />
                        </div> */}

                        <div className="order-page__order-timeline">
                            <OrderStates data={data} loading={showLoading} />
                        </div>
                    </PageLayout>
                </Container>
            )}
        </>
    );
};

export default OrderPage;
