import { Skeleton, Tooltip } from 'antd';
import PageHeader from 'components/Layout/PageHeader';
import Container from 'components/Grid/Container';
import {
    CountReports,
    GraphicsReports,
    GraphicCard,
    LineSac,
    LineSacFilled,
    PageLayout,
    Report,
    ReportCardTitle,
    SacContent,
    SkeletonLayout,
    SmallTitle,
    TitleDashboard,
    TitleGraphs,
    TitleReport,
    CountReportsCardsDown,
    // DotDashboard,
    CountReportsSAC,
    CountReportsCardsUP,
    ReportsWithBackground,
    ReportCount,
} from './styles';
import Flex from 'components/Utils/Flex';
import { useGetDashboardReportsQuery } from 'redux/features/Reports';
import dayjs from 'helpers/dayjs';
import React, { Suspense, useEffect, useState } from 'react';

import { FiltersDashboard } from './filtersDashboard';
import qs from 'qs';

// icons
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { ReactComponent as DeliveredShippingIcon } from 'assets/entrega-courier.svg';
import { ReactComponent as DeliveredPickupIcon } from 'assets/entrega-cliente.svg';
import { ReactComponent as StoreIcon } from 'assets/Store.svg';
import { ReactComponent as FulfilledIcon } from 'assets/box-unpackec.svg';
import { ReactComponent as DeliveredToCourierIcon } from 'assets/trolley-2.svg';
import { ReactComponent as TimerSand } from 'assets/timer-sand.svg';
import { ReactComponent as TimerSandInProgress } from 'assets/inprogress-sand.svg';
import { ReactComponent as TooltipIcon } from 'assets/tooltipIcon.svg';
import { FiPauseCircle } from 'react-icons/fi';
import { RiArrowGoBackFill } from 'react-icons/ri';
import usePermissionAdmin from 'hooks/usePermissionAdmin';
// import { AiOutlineArrowDown } from 'react-icons/ai';

const FillRateGraph = React.lazy(() => import('./Grafics/FillRateItems'));
const FillPieGraphic = React.lazy(() => import('./Grafics/FillPie'));
const FillCicleGraph = React.lazy(() => import('./Grafics/FillCicle'));

export const SkeletonsLayout = () => {
    return (
        <SkeletonLayout>
            <Skeleton className="loading-total-orders" active />
            <Skeleton className="loading-order_states" active />
            <Skeleton className="loading-delivered_to_courier" active />
            <Skeleton className="loading-delivered" active />
            <Skeleton className="loading-paused_report" active />
            <Skeleton className="loading-canceled" active />
            <Skeleton className="loading-sac" active />
            <Skeleton className="loading-graph-fill-rate" active />
            <Skeleton className="loading-graph-fill-cicle" active />
            <Skeleton className="loading-fill-pie" active />
        </SkeletonLayout>
    );
};

const IndexPage = () => {
    const [dateCalendar, setDateCalendar] = useState({});
    const [shopSelected, setShopSelected] = useState('');

    const [isAdmin, , authParams] = usePermissionAdmin();

    useEffect(() => {
        console.log({ isAdmin });
        if (!isAdmin && !authParams?.isLoading) window.location.href = '/orders';
    }, [isAdmin, authParams]);

    const {
        data = {},
        isLoading,
        isFetching,
    } = useGetDashboardReportsQuery(
        qs.stringify({
            ...dateCalendar,
            // location_id: !['', 'all'].includes(shopSelected) ? shopSelected : undefined,
            location_id: shopSelected !== 'all' ? shopSelected : [],
        })
    );

    const loading = isLoading || isFetching;

    const {
        order_reports = {},
        fill_orders_report = {},
        fill_orders_items = [],
        last_update = '',
    } = data;
    const {
        fulfillment_statuses_count = {},
        total_orders = 0,
        sac_statuses_count = {},
    } = order_reports;

    const [onGoingOrders, setOnGoingOrders] = useState(0);
    const [totalOrdersSAC, setTotalOrdersSAC] = useState(0);
    const [sacPendingAttention, setSacPendingAttention] = useState(0);
    const [sacProcessSolution, setSacProcessSolution] = useState(0);

    useEffect(() => {
        const on_going_orders =
            (fulfillment_statuses_count?.delivered_to_courier ?? 0) +
            (fulfillment_statuses_count?.fulfilled ?? 0) +
            (fulfillment_statuses_count?.in_progress ?? 0) +
            (fulfillment_statuses_count?.unfulfilled ?? 0) +
            (fulfillment_statuses_count?.paused ?? 0) +
            (fulfillment_statuses_count?.ready_for_deliver ?? 0) +
            (fulfillment_statuses_count?.partially_delivered ?? 0) +
            (fulfillment_statuses_count?.partially_fulfilled ?? 0) +
            (fulfillment_statuses_count?.sac ?? 0);
        setOnGoingOrders(on_going_orders);
    }, [fulfillment_statuses_count]);

    useEffect(() => {
        if (!isLoading) {
            const { order_reports = {} } = data;
            const { sac_statuses_count = {} } = order_reports;
            const values = Object?.keys(sac_statuses_count).map((key) => sac_statuses_count[key]);
            const sum = values?.reduce((acc, cur) => acc + cur, 0);
            setTotalOrdersSAC(sum);
        }
    }, [data, isLoading]);
    useEffect(() => {
        setSacPendingAttention(sac_statuses_count?.in_normal_process);

        const valuesSacStatus = Object?.keys(sac_statuses_count)
            .filter((key) => key !== 'in_normal_process')
            .map((key) => sac_statuses_count[key]);
        const sumValues = valuesSacStatus?.reduce((acc, cur) => acc + cur, 0);
        setSacProcessSolution(sumValues);
    }, [sac_statuses_count]);

    const linkToPendingReports = () => {
        const element = document.getElementById('downReports');
        element.scrollIntoView({ behavior: 'smooth' });
    };

    const title = <TitleDashboard>Dashboard</TitleDashboard>;

    return (
        <Container extraTitle="Dashboard">
            <PageHeader
                withMarginBottom={false}
                title={title}
                subtitle={
                    <Flex direction="column">
                        <SmallTitle>
                            Última actualización:{' '}
                            {last_update ? dayjs(last_update).format('DD/MM/YYYY HH:mm') : ''}
                        </SmallTitle>
                    </Flex>
                }
            />
            <FiltersDashboard
                setShopSelected={setShopSelected}
                shopSelected={shopSelected}
                setDateCalendar={setDateCalendar}
            />
            {isLoading ? (
                SkeletonsLayout()
            ) : (
                <PageLayout>
                    <CountReports className="grid1">
                        <CountReportsCardsUP className="count_reports_card_up">
                            <Report className="total_orders_report">
                                <TitleReport>
                                    <p>
                                        <span>
                                            <b style={{ paddingTop: '0.3rem' }}>Pedidos totales</b>
                                        </span>{' '}
                                    </p>
                                </TitleReport>
                                <ReportCount style={{ paddingTop: '1px' }}>
                                    {total_orders}
                                </ReportCount>
                            </Report>
                            <Report className="ongoing_orders_report">
                                <TitleReport>
                                    <p>
                                        <span>
                                            <u>
                                                <b role="button" onClick={linkToPendingReports}>
                                                    Pedidos en curso
                                                </b>
                                            </u>
                                        </span>{' '}
                                    </p>
                                </TitleReport>
                                <ReportCount>{onGoingOrders ?? 0}</ReportCount>
                            </Report>
                            <div className="report-spacer"></div>
                            <Report className="delivered_pickup_orders_report">
                                <TitleReport>
                                    <p>
                                        <span>
                                            <b>
                                                Entregados al cliente
                                                <br /> con retiro{' '}
                                                <DeliveredPickupIcon style={{ width: '22px' }} />
                                            </b>
                                        </span>{' '}
                                    </p>
                                </TitleReport>
                                <ReportCount>
                                    {order_reports.delivered_pickup_orders ?? 0}
                                </ReportCount>
                            </Report>
                            <Report className="delivered_shipping_orders_report">
                                <TitleReport>
                                    <p>
                                        <span>
                                            <b>
                                                Entregados al cliente
                                                <br /> con despacho
                                                <DeliveredShippingIcon style={{ width: '22px' }} />
                                            </b>
                                        </span>{' '}
                                    </p>
                                </TitleReport>
                                <ReportCount>
                                    {order_reports?.delivered_shipping_orders ?? 0}
                                </ReportCount>
                            </Report>

                            <Report className="delivered_third_point_orders_report">
                                <TitleReport>
                                    <p>
                                        <span>
                                            <b>
                                                Entregados al cliente
                                                <br /> en punto de tercero
                                                <StoreIcon style={{ width: '20px' }} />
                                            </b>
                                        </span>{' '}
                                    </p>
                                </TitleReport>
                                <ReportCount>
                                    {order_reports?.delivered_third_point_orders ?? 0}
                                </ReportCount>
                            </Report>

                            <Report className="cancelled_orders_report">
                                <TitleReport>
                                    <p>
                                        <span>
                                            <b>Pedidos cancelados</b>
                                            <AiOutlineCloseCircle style={{ width: '22px' }} />
                                        </span>{' '}
                                    </p>
                                </TitleReport>
                                <ReportCount>
                                    {fulfillment_statuses_count?.cancelled ?? 0}
                                </ReportCount>
                            </Report>
                            <Report className="returned_orders_report">
                                <TitleReport>
                                    <p>
                                        <span>
                                            <b>Pedidos devueltos</b>
                                            <RiArrowGoBackFill style={{ width: '22px' }} />
                                        </span>{' '}
                                    </p>
                                </TitleReport>
                                <ReportCount>
                                    {order_reports?.total_orders_with_refunded_items ?? 0}
                                </ReportCount>
                            </Report>
                            <div></div>
                        </CountReportsCardsUP>
                    </CountReports>
                    <CountReports className="grid2" id="downReports">
                        <CountReportsCardsDown className="count_reports_card_down">
                            {/* <div className="div1"> </div> */}
                            <div className="count_reports_card_down_left">
                                <Report className="in_progress_orders_report_principal">
                                    <TitleReport>
                                        <p>
                                            <span style={{ paddingTop: '1rem' }}>
                                                <b>Pedidos en curso</b>
                                            </span>{' '}
                                        </p>
                                    </TitleReport>
                                    <ReportCount>{onGoingOrders ?? 0}</ReportCount>
                                </Report>
                                <CountReportsSAC className="count_reports_card_sac">
                                    <Report className="sac_orders_report">
                                        <TitleReport>
                                            <p>
                                                <span>
                                                    <b>Pedidos en SAC</b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount>
                                            <b>{totalOrdersSAC ?? 0}</b>
                                            <h5>
                                                (
                                                {Number(
                                                    Number(totalOrdersSAC / total_orders) * 100
                                                ).toFixed(2)}
                                                %)
                                            </h5>
                                        </ReportCount>
                                    </Report>

                                    <SacContent className="sac_pending_attention">
                                        <p className="title_sac">
                                            {' '}
                                            Pendientes
                                            <br /> de atención
                                        </p>
                                        <p className="quantity_sac">{sacPendingAttention}</p>

                                        <LineSac>
                                            <LineSacFilled
                                                style={{
                                                    width: `${
                                                        (sacPendingAttention / totalOrdersSAC) * 100
                                                    }%`,
                                                }}
                                            />
                                        </LineSac>

                                        <p className="sac_percentage_pending_attention">
                                            {' '}
                                            {isNaN((sacPendingAttention / totalOrdersSAC) * 100)
                                                ? 0
                                                : (
                                                      (sacPendingAttention / totalOrdersSAC) *
                                                      100
                                                  )?.toFixed(1)}
                                            %
                                        </p>
                                    </SacContent>

                                    <SacContent className="sac_process_solution">
                                        <p className="title_sac">
                                            {' '}
                                            En proceso <br /> de solución
                                        </p>
                                        <p className="quantity_sac quantity_process_solution">
                                            {sacProcessSolution}
                                        </p>

                                        <LineSac>
                                            <LineSacFilled
                                                $backgroundColor="#E3CD06"
                                                style={{
                                                    width: `${
                                                        (sacProcessSolution / totalOrdersSAC) * 100
                                                    }%`,
                                                }}
                                            />
                                        </LineSac>

                                        <p className="sac_percentage_process_solution">
                                            {isNaN((sacProcessSolution / totalOrdersSAC) * 100)
                                                ? 0
                                                : (
                                                      (sacProcessSolution / totalOrdersSAC) *
                                                      100
                                                  )?.toFixed(1)}
                                            %
                                        </p>
                                    </SacContent>
                                </CountReportsSAC>
                            </div>

                            <div className="count_reports_card_down_right">
                                {/* ------ */}
                                <Report className="unfulfilled_orders_report">
                                    <TitleReport>
                                        <p>
                                            <span>
                                                <b>Pendientes</b>
                                                <TimerSand
                                                    style={{ width: '17px', height: '19px' }}
                                                />
                                            </span>{' '}
                                            <small>(de preparación)</small>
                                        </p>
                                    </TitleReport>
                                    <ReportCount>
                                        {fulfillment_statuses_count?.unfulfilled ?? 0}
                                    </ReportCount>
                                </Report>
                                <Report className="in_progress_orders_report">
                                    <TitleReport>
                                        <p>
                                            <span>
                                                <b>En proceso</b>
                                                <TimerSandInProgress style={{ width: '20px' }} />
                                            </span>{' '}
                                            <small>(de preparación)</small>
                                        </p>
                                    </TitleReport>

                                    <ReportCount>
                                        {fulfillment_statuses_count?.in_progress ?? 0}
                                    </ReportCount>
                                </Report>
                                <Report className="paused_orders_report">
                                    <TitleReport>
                                        <p>
                                            <span>
                                                <b>Pausados</b>
                                                <FiPauseCircle style={{ width: '22px' }} />
                                            </span>
                                            <br />
                                        </p>
                                    </TitleReport>
                                    <ReportCount>
                                        {fulfillment_statuses_count?.paused ?? 0}
                                    </ReportCount>
                                </Report>
                                {/* ------ */}

                                <ReportsWithBackground className="count_reports_background_1">
                                    <Report className="fulfilled_orders_report">
                                        <TitleReport>
                                            <p>
                                                <span>
                                                    <b>Preparados</b>
                                                    <FulfilledIcon style={{ width: '20px' }} />
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount>
                                            {fulfillment_statuses_count?.fulfilled ?? 0}
                                        </ReportCount>
                                    </Report>

                                    <Report className="shipping_in_progress_orders_report">
                                        <TitleReport $isSecondary>
                                            <p>
                                                <span>
                                                    <b>Despacho</b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount $isSecondary style={{ paddingTop: '10px' }}>
                                            {order_reports.fulfilled_shipping_orders ?? 0}
                                        </ReportCount>
                                    </Report>

                                    <Report className="pickup_in_progress_order_report">
                                        <TitleReport $isSecondary>
                                            <p>
                                                <span>
                                                    <b>Retiro</b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount $isSecondary style={{ paddingTop: '10px' }}>
                                            {order_reports.fulfilled_pickup_orders ?? 0}
                                        </ReportCount>
                                    </Report>
                                    <Report className="delivered_third_point_in_progress_orders_report">
                                        <TitleReport $isSecondary>
                                            <p>
                                                <span>
                                                    <b>Entrega en punto de tercero</b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount $isSecondary style={{ paddingTop: '10px' }}>
                                            {order_reports.fulfilled_third_point_orders ?? 0}
                                        </ReportCount>
                                    </Report>
                                    <Report className="partially_fulfilled_orders_report">
                                        <TitleReport $isSecondary>
                                            <p>
                                                <span>
                                                    <b>Parcialmente preparado</b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount $isSecondary style={{ paddingTop: '10px' }}>
                                            {order_reports.fulfillment_statuses_count
                                                ?.partially_fulfilled ?? 0}
                                        </ReportCount>
                                    </Report>
                                </ReportsWithBackground>
                                {/* ------ */}
                                <ReportsWithBackground className="count_reports_background_2">
                                    <Report className="delivered_to_courier_orders_reports">
                                        <TitleReport>
                                            <p>
                                                <span>
                                                    <b>
                                                        Despachos entregados
                                                        <br /> al courier{' '}
                                                        <DeliveredToCourierIcon
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                        />
                                                    </b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount>
                                            {fulfillment_statuses_count?.delivered_to_courier ?? 0}
                                        </ReportCount>
                                    </Report>

                                    <Report className="shipping_delivered_to_courier_in_progress">
                                        <TitleReport $isSecondary>
                                            <p>
                                                <span>
                                                    <b>Despacho a domicilio</b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount $isSecondary style={{ paddingTop: '30px' }}>
                                            {order_reports.delivered_to_courier_shipping_orders ??
                                                0}
                                        </ReportCount>
                                    </Report>

                                    <Report className="pickup_third_point_delivered_to_courier_in_progress">
                                        <TitleReport $isSecondary>
                                            <p>
                                                <span>
                                                    <b>Retiro en punto de tercero</b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount $isSecondary style={{ paddingTop: '30px' }}>
                                            {order_reports.delivered_to_courier_third_point_orders ??
                                                0}
                                        </ReportCount>
                                    </Report>
                                    <Report className="partially_delivered">
                                        <TitleReport $isSecondary>
                                            <p>
                                                <span>
                                                    <b>Parcialmente entregado</b>
                                                </span>{' '}
                                            </p>
                                        </TitleReport>
                                        <ReportCount $isSecondary style={{ paddingTop: '30px' }}>
                                            {order_reports.fulfillment_statuses_count
                                                ?.partially_delivered ?? 0}
                                        </ReportCount>
                                    </Report>
                                </ReportsWithBackground>
                            </div>
                        </CountReportsCardsDown>
                    </CountReports>
                    {/* ----------------------------------------------------------------------------------------------------------------- */}
                    <GraphicsReports className="grid-graphics">
                        <GraphicCard className="home-dashboard__fill-rate">
                            <TitleGraphs>
                                <ReportCardTitle>Tasa de cumplimiento</ReportCardTitle>
                                <Tooltip
                                    title={
                                        'Tasa que mide la capacidad de una empresa para satisfacer las necesidades de sus clientes (total de artículos entregados VS total de artículos solicitados).'
                                    }
                                    placement="topRight"
                                    align={{ offset: [16, 0] }}
                                >
                                    <TooltipIcon color="#7f8082" />
                                </Tooltip>
                            </TitleGraphs>
                            <Suspense fallback={<></>}>
                                <FillRateGraph loading={loading} data={fill_orders_items} />
                            </Suspense>
                        </GraphicCard>
                        <GraphicCard className="home-dashboard__fill-cycle">
                            <TitleGraphs>
                                <ReportCardTitle>Ciclo del fulfillment</ReportCardTitle>
                                <Tooltip
                                    title={
                                        'Promedio de horas: desde el pedido creado hasta su entrega.'
                                    }
                                    placement="topRight"
                                    align={{ offset: [16, 0] }}
                                    overlayStyle={{ maxWidth: '260px' }}
                                >
                                    <TooltipIcon style={{ fontSize: '1.2rem' }} color="#7f8082" />
                                </Tooltip>
                            </TitleGraphs>
                            <Suspense fallback={<></>}>
                                <FillCicleGraph
                                    loading={loading}
                                    data={fill_orders_report}
                                    shopSelected={shopSelected}
                                />
                            </Suspense>
                        </GraphicCard>
                        <GraphicCard className="home-dashboard__perfect-order">
                            <TitleGraphs>
                                <ReportCardTitle>Tasa de pedido perfecto</ReportCardTitle>
                                <Tooltip
                                    title={
                                        'Pedido perfecto en: todos los ítems, en dirección, en condición y sin reclamos.'
                                    }
                                    placement="topRight"
                                    align={{ offset: [16, 0] }}
                                >
                                    <TooltipIcon style={{ fontSize: '1.2rem' }} color="#7f8082" />
                                </Tooltip>
                            </TitleGraphs>
                            <Suspense fallback={<></>}>
                                <FillPieGraphic loading={loading} data={fill_orders_report} />
                            </Suspense>
                        </GraphicCard>
                    </GraphicsReports>
                </PageLayout>
            )}
        </Container>
    );
};

export default IndexPage;
