import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const statesValidationApi = createApi({
    reducerPath: 'statesValidationMappings',
    baseQuery,
    tagTypes: ['statesValidationMappings'],
    endpoints: (builder) => ({
        statesValidation: builder.mutation({
            query: (data) => {
                return {
                    url: '/helpers/statesValidation',
                    method: 'post',
                    body: data,
                };
            },
            providesTags: ['statesValidationMappings'],
        }),
    }),
});

export const { useStatesValidationMutation } = statesValidationApi;
