import React, { useState, useEffect, Suspense, useMemo } from 'react';
import { Divider, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetUserPreferencesQuery } from 'redux/features/Users';
import InfoFilter from 'components/V2/Filters/InfoFilter';

import {
    removeFilter,
    clearFilters,
    overrideFilters,
    setValueOptionByField,
} from 'redux/features/PendingArrivals/pendingArrivalsFilters';
import { setPendingArrivalsFilters } from 'redux/features/Utils';
import useCurrentUser from 'hooks/useCurrentUser';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';
import CollapsibleContainer from 'components/V2/CollapsibleContainer';
import LocationPicker from 'components/V2/LocationPicker';
import { getDateFilterToSend } from 'components/V2/DatePicker/utils';
import PendingArrivalsMetrics from 'components/V2/Metrics/PendingArrivalsMetrics';
import ViewTabs from 'components/V2/ViewTabs';
import SearchInput from 'components/V2/SearchInput';
import FilterButton from 'components/V2/Buttons/FilterButton';
import ColumnsButton from 'components/V2/Buttons/ColumnsButton';
import ShowQuantityItems from 'components/V2/ShowQuantityItems';
import PendingArrivalsActions from './PendingArrivalsActions';
import { defaultColumnsView, INITIAL_PAGE_SIZE } from './config';
import store from '../../redux';
import { promisedDateRanges } from 'data/promised_date_ranges';
import { RangePicker } from 'components/RangePicker';
import { useLocation } from 'react-router-dom';
import {
    useLazyGetPendingArrivalFulfillmentsAllIdsQuery,
    useLazyGetPendingArrivalFulfillmentsQuery,
} from 'redux/features/Fulfillments';
const DraggableTable = React.lazy(() => import('./Table/DraggableTable'));

const PendingArrivals = () => {
    const PREFERENCES = 'pendingArrivals';
    // Redux queries
    const {
        data: userPreferences,
        isLoading: userLoading,
        isFetching: userFetching,
    } = useGetUserPreferencesQuery();
    const [getPendingArrivalsFulfillments, { data, isFetching, isLoading }] =
        useLazyGetPendingArrivalFulfillmentsQuery();

    const [getPendingArrivalsFulfillmentsAllIds, { data: allIds }] =
        useLazyGetPendingArrivalFulfillmentsAllIdsQuery();
    const params = useLocation();
    // Redux state and get data from local storage
    const { filters, valueOptionByField } = useSelector((state) => state.pendingArrivalsFilters);
    // const { massiveFulfillmentPreparation } = useSelector((state) => state.utils);
    const { userData } = useCurrentUser();
    const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;
    const objectDateToSend = getDateFilterToSend(
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter,
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter_custom
    );
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('all');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [hasStatusNotAllowedRow, setHasStatusNotAllowedRow] = useState(false);
    const [tabColumns, setTabColumns] = useState(defaultColumnsView);
    const [tablePageSize, setTablePageSize] = useState(INITIAL_PAGE_SIZE);
    const [selectedDateRange, setSelectedDateRange] = useState({
        fromDate: objectDateToSend?.start ?? dayjs().format('YYYY-MM-DD'),
        toDate: objectDateToSend?.end ?? dayjs().format('YYYY-MM-DD'),
    });
    const [selectedLocation, setSelectedLocation] = useState([
        userData?.store_warehouse_id || 'all',
    ]);
    const [massiveFulfillmentPendingArrival, setMassiveFulfillmentPendingArrival] = useState([]);
    const [fulfillmentUrlParams, setFulfillmentUrlParams] = useState({});

    const [scanMode, setScanMode] = useState(false);

    const loading = isLoading || isFetching || userLoading || userFetching;

    const forceRefresh = new URLSearchParams(window.location.search).get('forceRefresh') === 'true';

    const handlePreparationsRefetch = (forceRefetch) => {
        // refetch();

        if (forceRefetch) {
            setSelectedRows([]);
        }

        const utils = store.getState().utils;

        const filters = utils.pendingArrivalsFilters;
        getPendingArrivalsFulfillments(filters, !forceRefetch);
        getPendingArrivalsFulfillmentsAllIds(filters, !forceRefetch);
    };
    // Funcion para actualizar el estado de la tab seleccionada a traves de los hijos
    const handleChangeTab = (tabValue) => {
        const selectedTabIndex = userPreferences?.preferences[PREFERENCES]?.tabs?.findIndex(
            (tab) => tab.value === tabValue
        );
        setSelectedTab(tabValue);
        setSelectedTabIndex(
            selectedTabIndex === -1
                ? userPreferences?.preferences[PREFERENCES]?.tabs?.length
                : selectedTabIndex
        );
        const filtersToOverride = structuredClone(
            userPreferences?.preferences[PREFERENCES]?.tabs[selectedTabIndex]?.filters ?? []
        );
        filtersToOverride.forEach((filter) => {
            filter.compromiseValues = valueOptionByField[filter.field];
        });
        dispatch(overrideFilters(filtersToOverride));
        // Despachamos los filtros para que se apliquen
        dispatch(
            setPendingArrivalsFilters({
                filters: [
                    ...(filtersToOverride?.map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })) ?? []),
                ],
                page: 1,
            })
        );
        // refetch();
        handlePreparationsRefetch();
    };
    const handleChangeDateRange = (updatedDateRange) => {
        setSelectedDateRange(updatedDateRange);
        dispatch(
            setPendingArrivalsFilters({
                date_range: [updatedDateRange?.fromDate, updatedDateRange?.toDate],
                page: 1,
            })
        );
        handlePreparationsRefetch();
    };
    const handleChangeLocations = (updatedLocation) => {
        setSelectedLocation(updatedLocation);
        dispatch(
            setPendingArrivalsFilters({
                pickup_location: updatedLocation,
                page: 1,
            })
        );
        handlePreparationsRefetch();
    };
    const handleChangeTabColumns = (updatedColumns) => {
        setTabColumns(updatedColumns);
    };
    const handleChangePageSize = (sizePage) => {
        setTablePageSize(sizePage);
    };
    const handleSelectRows = (selectedRowKeys, selectedRows) => {
        const hasStatusNotAllowed = selectedRows.some(
            (fulfillment) =>
                fulfillment?.preparationcd_transfer_pickupstore?.status !==
                'delivered_to_pickup_location'
        );

        const hasStatusNotAllowedByConsolidation = selectedRows.some(
            (fulfillment) => fulfillment?.consolidation?.status !== 'delivered_to_cd_location'
        );

        setHasStatusNotAllowedRow(hasStatusNotAllowed && hasStatusNotAllowedByConsolidation);
        setSelectedRows(selectedRowKeys);
    };
    const handleChangeColumns = (columns) => {
        setTabColumns(columns);
    };
    const handleDeleteFilter = (filterId) => {
        if (fulfillmentUrlParams?.fulfillment_id) {
            setFulfillmentUrlParams({});
        }
        dispatch(removeFilter(filterId));
        dispatch(
            setPendingArrivalsFilters({
                filters: filters
                    ?.filter((filter) => filter.id !== filterId)
                    .map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })),
                page: 1,
            })
        );
        handlePreparationsRefetch(true);
    };

    const searchFulfillmentByUrlParams = (fulfillmentId, remoteOrderId) => {
        const state = store.getState();
        const utils = state.utils;
        const filters = utils.pendingArrivalsFilters;
        setFulfillmentUrlParams({
            fulfillment_id: fulfillmentId,
            remote_order_id: remoteOrderId,
        });
        getPendingArrivalsFulfillmentsAllIds(
            {
                ...filters,
                pickup_location: null,
                searchTerm: remoteOrderId,
                page: 1,
                date_range: [],
            },
            false
        );
        getPendingArrivalsFulfillments(
            {
                ...filters,
                pickup_location: null,
                searchTerm: remoteOrderId,
                page: 1,
                date_range: [],
            },
            false
        ).then((response) => {
            console.log({ response });
            const fulfillment = response?.data?.docs?.[0];
            const filter = {
                andOr: '',
                condition: 'is',
                field: 'name',
                value: fulfillment?.name ?? '',
                consolidation: false,
                isRemovable: true,
                id: 'fulfillment_url_params',
                compromiseValues: [],
            };
            dispatch(overrideFilters([filter]));
        });
    };

    const handleOnInit = () => {
        const searchParams = new URLSearchParams(params.search);
        const fulfillmentId = searchParams.get('fulfillment_id');
        const remoteOrderId = searchParams.get('remote_order_id');
        if (fulfillmentId && remoteOrderId) {
            searchFulfillmentByUrlParams(fulfillmentId, remoteOrderId);
            return;
        }
        handlePreparationsRefetch();
    };

    useEffect(() => {
        if (forceRefresh) {
            handlePreparationsRefetch(true);

            const url = new URL(window.location.href);
            url.searchParams.delete('forceRefresh');
            window.history.replaceState({}, '', url);
        }
    }, [forceRefresh]);

    useEffect(() => {
        const tab = userPreferences?.preferences[PREFERENCES]?.tabs?.find(
            (tab) => tab.value === selectedTab
        );
        if (!tab || tab?.typeOfTab === 'basic') {
            setTabColumns(defaultColumnsView);
            return;
        }
        setTabColumns(tab.columns);
    }, [selectedTab, selectedTabIndex]);
    // Para actualizar con el punto
    const forceRefetchWithButton = (event) => {
        const key = event?.key;
        if (key === '.') {
            handlePreparationsRefetch(true);
        }
    };
    useEffect(() => {
        document.addEventListener('keypress', forceRefetchWithButton);
        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton, true);
            dispatch(clearFilters());
            dispatch(setPendingArrivalsFilters({}));
        };
    }, []);

    useEffect(() => {
        if (loading) return;
        const fieldValuesObj = {
            promised_date_range: promisedDateRanges,
        };
        dispatch(setValueOptionByField(fieldValuesObj));
    }, [loading]);

    useEffect(() => {
        if (Array.from(massiveFulfillmentPendingArrival ?? []).length > 0) {
            setSelectedRows(massiveFulfillmentPendingArrival.map((item) => item._id));
        }
    }, [massiveFulfillmentPendingArrival]);

    useEffect(() => {
        if (!scanMode) {
            setSelectedRows([]);
            setMassiveFulfillmentPendingArrival([]);
        }
    }, [scanMode]);

    useEffect(() => {
        setSelectedRows([]);
    }, [selectedTab, selectedTabIndex, filters, selectedDateRange, scanMode]);

    useEffect(() => {
        handleOnInit();
    }, []);

    const filtersToShow = useMemo(() => {
        return filters.filter((filter) => filter.field);
    }, [filters]);

    return (
        <Container extraTitle="Pendientes de recepción">
            <PageHeader title="Pendientes de recepción" withMarginBottom={false} />
            <p style={{ margin: '0.5rem 0 0.2rem' }}>Filtrar métricas y tabla por:</p>
            <Flex direction="row" columnGap="1rem" paddingBottom="2rem">
                <RangePicker loading={loading} setDateCalendar={handleChangeDateRange} />
                <LocationPicker handleChangeLocations={handleChangeLocations} loading={loading} />
            </Flex>
            {/* Container con fechas, ubicaciones y Metricas */}
            <Flex direction="column">
                <CollapsibleContainer>
                    <PendingArrivalsMetrics
                        selectedDateRange={selectedDateRange}
                        selectedLocation={selectedLocation}
                    />
                </CollapsibleContainer>
            </Flex>
            {/* Vistas personalizadas */}
            <ViewTabs
                userPreferences={userPreferences}
                selectedTab={selectedTab}
                handleChangeTab={handleChangeTab}
                preferences={PREFERENCES}
            />
            {/* Searchbar y botones de acciones */}
            <Flex
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                // marginBottom="1.5rem"
                padding="1rem 0 2rem"
            >
                <Flex direction="column" rowGap="0.5rem">
                    <Flex direction="row" gap="1rem">
                        <Switch onChange={(scan) => setScanMode(scan)} checked={scanMode} />
                        <span style={{ fontSize: '14px' }}>
                            Modo scan {scanMode ? 'habilitado' : 'deshabilitado'}
                        </span>
                    </Flex>
                    <SearchInput
                        value={searchTerm ?? undefined}
                        onChange={(e) => setSearchTerm(e?.target?.value)}
                        refetch={handlePreparationsRefetch}
                        dispatchersType={PREFERENCES}
                        disabled={loading}
                        scanMode={scanMode}
                        setMassiveFulfillmentPendingArrival={setMassiveFulfillmentPendingArrival}
                        massiveFulfillmentPendingArrival={massiveFulfillmentPendingArrival}
                        name="pendingArrivals-search-input"
                    />
                </Flex>
                <Flex gap="1rem">
                    <FilterButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        refetch={handlePreparationsRefetch}
                        data={data}
                        modalName="pendingArrivals-filter-modal"
                        formName="pendingArrivals-filter-form"
                        filters={filters}
                        valueOptionByField={valueOptionByField}
                        dispatchersType={PREFERENCES}
                    />
                    <ColumnsButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        userFetching={userFetching}
                        tabColumns={tabColumns}
                        handleChangeTabColumns={handleChangeTabColumns}
                        formName="pendingArrivals-column-form"
                        modalName="pendingArrivals-column-modal"
                        dispatchersType={PREFERENCES}
                    />
                    <ShowQuantityItems
                        tablePageSize={tablePageSize}
                        handleChangePageSize={handleChangePageSize}
                        disabled={loading}
                        dispatchersType={PREFERENCES}
                        refetch={handlePreparationsRefetch}
                    />
                    <PendingArrivalsActions
                        hasStatusNotAllowedRow={hasStatusNotAllowedRow}
                        disabled={loading || selectedRows.length < 1}
                        selected={selectedRows}
                        data={data}
                        handleRefetch={handlePreparationsRefetch}
                    />
                </Flex>
            </Flex>
            {/* Filtros seleccionados */}
            <Flex gap="0.75rem" marginBottom="1.5rem">
                <span>Filtros: </span>
                {filtersToShow.length === 0 ? (
                    <InfoFilter loading={loading} />
                ) : (
                    filtersToShow
                        ?.filter((filter) => {
                            console.log({ filter });
                            return filter?.hide !== true;
                        })
                        ?.map((filter) => (
                            <InfoFilter
                                key={filter.id}
                                filter={filter}
                                onDelete={handleDeleteFilter}
                                loading={loading}
                                showDeleteIcon
                                preferences={PREFERENCES}
                                valueOptionByField={valueOptionByField}
                            />
                        ))
                )}
            </Flex>

            <Flex direction="row" alignItems="center">
                {selectedRows.length === 0 ? (
                    <b style={{ display: 'block' }} className="text-sm">
                        No tienes pedidos seleccionados
                    </b>
                ) : (
                    <b style={{ display: 'block' }} className="text-sm">
                        En esta vista tienes seleccionado{' '}
                        <strong>
                            {selectedRows.length} pedido(s) de {data?.totalDocs}
                        </strong>{' '}
                        {filtersToShow.length > 0 && '(Aplican filtros)'}
                    </b>
                )}
                <Divider type="vertical" style={{ marginBottom: '25px' }} />
                {data?.totalDocs > 0 && data?.totalPages > 1 && (
                    <b
                        className="text-sm"
                        role="button"
                        onClick={() => {
                            if (selectedRows.length === data?.totalDocs) {
                                setSelectedRows([]);
                            } else {
                                setSelectedRows(allIds?.ids);
                            }
                        }}
                        style={{ display: 'block' }}
                    >
                        <u>
                            {data?.totalDocs === selectedRows.length
                                ? 'Deseleccionar todos'
                                : 'Seleccionar todos'}{' '}
                            ({data?.totalDocs})
                        </u>
                    </b>
                )}
            </Flex>
            {/* Table */}
            <Suspense fallback={<></>}>
                <DraggableTable
                    data={data}
                    loading={loading}
                    userPreferences={userPreferences}
                    selectedTab={selectedTab}
                    refetch={handlePreparationsRefetch}
                    selectedRows={selectedRows}
                    handleSelectRows={handleSelectRows}
                    tabColumns={tabColumns}
                    handleChangeColumns={handleChangeColumns}
                    preferences={PREFERENCES}
                    massiveFulfillmentPreparation={massiveFulfillmentPendingArrival}
                    scanMode={scanMode}
                    fulfillmentSelected={fulfillmentUrlParams.fulfillment_id}
                />
            </Suspense>
        </Container>
    );
};
export default PendingArrivals;
