/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Divider, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetUserPreferencesQuery } from 'redux/features/Users';
import {
    removeFilter,
    clearFilters,
    overrideFilters,
    setValueOptionByField,
} from 'redux/features/Manifests/manifestsFilters';
import { setManifestsFilters } from 'redux/features/Utils';
import useCurrentUser from 'hooks/useCurrentUser';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';
import CollapsibleContainer from 'components/V2/CollapsibleContainer';
import LocationPicker from 'components/V2/LocationPicker';
import { getDateFilterToSend } from 'components/V2/DatePicker/utils';
import ManifestsMetrics from 'components/V2/Metrics/ManifestsMetrics';
import ViewTabs from 'components/V2/ViewTabs';
import SearchInput from 'components/V2/SearchInput';
import FilterButton from 'components/V2/Buttons/FilterButton';
import ColumnsButton from 'components/V2/Buttons/ColumnsButton';
import ShowQuantityItems from 'components/V2/ShowQuantityItems';
import InfoFilter from 'components/V2/Filters/InfoFilter';
import DraggableTable from './Table/DraggableTable';
import ManifestsActions from './ManifestsActions';
import { defaultColumnsView, INITIAL_PAGE_SIZE } from './config';
import store from '../../redux';
import { useLazyGetAllManifestsByFiltersQuery } from 'redux/features/Deliveries';
import useGetPickupPreference from 'hooks/useGetPickupPreference';
import { removeAccents } from 'helpers/removeAccents';
import useAllSalesChannel from 'hooks/useAllSalesChannel';
import useAllShops from 'hooks/useAllShops';
import useAllCouriers from 'hooks/useAllCouriers';
import ManifestScanInput from 'components/V2/SearchInput/ManifestScanInput';
import { RangePicker } from 'components/RangePicker';
import { useLocation } from 'react-router-dom';

const Manifests = () => {
    const PREFERENCES = 'manifests';

    // Redux queries
    const {
        data: userPreferences,
        isLoading: userLoading,
        isFetching: userFetching,
    } = useGetUserPreferencesQuery();

    const params = useLocation();

    const [loadingDeliveryPreferences, deliveryPreferences] = useGetPickupPreference();

    const [getAllManifests, { data, isFetching, isLoading }] =
        useLazyGetAllManifestsByFiltersQuery();

    // Redux state and get data from local storage
    const { filters, valueOptionByField } = useSelector((state) => state.manifestsFilters);

    const { massiveManifestsToProcess } = useSelector((state) => state.utils);
    const { userData } = useCurrentUser();
    const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;
    const objectDateToSend = getDateFilterToSend(
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter,
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter_custom
    );

    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = useState('pending_delivery');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [tabColumns, setTabColumns] = useState(defaultColumnsView);
    const [tablePageSize, setTablePageSize] = useState(INITIAL_PAGE_SIZE);
    const [selectedDateRange, setSelectedDateRange] = useState({
        fromDate: objectDateToSend?.start ?? dayjs().format('YYYY-MM-DD'),
        toDate: objectDateToSend?.end ?? dayjs().format('YYYY-MM-DD'),
    });
    const [salesChannelsLoading, salesChannels] = useAllSalesChannel();
    const [locationsLoading, locations] = useAllShops();
    const [couriersLoading, couriers] = useAllCouriers();
    const [selectedLocation, setSelectedLocation] = useState([
        userData?.store_warehouse_id || 'all',
    ]);
    const [scanMode, setScanMode] = useState(false);
    const [fulfillmentUrlParams, setFulfillmentUrlParams] = useState({});
    const [isMounted, setIsMounted] = useState(false);

    const loading =
        isLoading ||
        isFetching ||
        userLoading ||
        userFetching ||
        loadingDeliveryPreferences ||
        salesChannelsLoading ||
        locationsLoading ||
        couriersLoading;

    const handleManifestsRefetch = (forceRefetch) => {
        // refetch();

        if (forceRefetch) {
            setSelectedRows([]);
        }

        const utils = store.getState().utils;
        const filters = utils.manifestsFilters;
        getAllManifests(filters, !forceRefetch);
    };

    // Efecto para obtener las opciones de los filtros segun el campo
    useEffect(() => {
        if (loading) return;
        const fieldValuesObj = {
            sales_channel: salesChannels.map((sc) => ({
                label: sc?.alias ?? sc?.integration?.api_name,
                value: sc?._id,
            })),
            location: [{ label: 'Todas', value: 'all' }].concat(
                Array.from(locations ?? [])
                    .filter((l) => l.is_enabled)
                    ?.sort((a, b) =>
                        removeAccents(a.name.toUpperCase()).localeCompare(
                            removeAccents(b.name.toUpperCase())
                        )
                    )
                    .map((location) => ({
                        label: location?.name || '',
                        value: location._id,
                    }))
            ),
            courier: couriers.map((courier) => ({
                label: courier?.public_name ?? courier?.courier_name ?? '',
                value: courier?._id,
            })),
        };
        dispatch(setValueOptionByField(fieldValuesObj));
    }, [loading]);

    // Funcion para actualizar el estado de la tab seleccionada a traves de los hijos
    const handleChangeTab = (tabValue, notFetch) => {
        console.log('handleChangeTab');
        const selectedTabIndex = userPreferences?.preferences[PREFERENCES]?.tabs?.findIndex(
            (tab) => tab.value === tabValue
        );

        setSelectedTab(tabValue);
        setSelectedTabIndex(
            selectedTabIndex === -1
                ? userPreferences?.preferences[PREFERENCES]?.tabs?.length
                : selectedTabIndex
        );
        const filtersToOverride = structuredClone(
            userPreferences?.preferences[PREFERENCES]?.tabs[selectedTabIndex]?.filters ?? []
        );

        filtersToOverride.forEach((filter) => {
            filter.compromiseValues = valueOptionByField[filter.field];
        });

        dispatch(overrideFilters(filtersToOverride));
        // Despachamos los filtros para que se apliquen
        dispatch(
            setManifestsFilters({
                filters: [
                    ...(filtersToOverride?.map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })) ?? []),
                ],
                page: 1,
            })
        );

        // refetch();
        !notFetch && handleManifestsRefetch();
    };

    const handleChangeDateRange = (updatedDateRange) => {
        console.log('handleChangeDateRange');

        setSelectedDateRange(updatedDateRange);

        dispatch(
            setManifestsFilters({
                date_range: [updatedDateRange?.fromDate, updatedDateRange?.toDate],
                page: 1,
            })
        );
        handleManifestsRefetch();
    };

    const handleChangeLocations = (updatedLocation) => {
        console.log('handleChangeLocations');

        setSelectedLocation(updatedLocation);

        dispatch(
            setManifestsFilters({
                location: updatedLocation,
                page: 1,
            })
        );
        handleManifestsRefetch();
    };

    const handleChangeTabColumns = (updatedColumns) => {
        setTabColumns(updatedColumns);
    };

    const handleChangePageSize = (sizePage) => {
        setTablePageSize(sizePage);
    };

    const handleSelectRows = (selectedRowKeys) => {
        setSelectedRows(selectedRowKeys);
    };

    const handleChangeColumns = (columns) => {
        setTabColumns(columns);
    };

    const handleDeleteFilter = (filterId) => {
        if (fulfillmentUrlParams?.fulfillment_id) {
            setFulfillmentUrlParams({});
        }
        dispatch(removeFilter(filterId));
        dispatch(
            setManifestsFilters({
                filters: filters
                    ?.filter((filter) => filter.id !== filterId)
                    .map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })),
                page: 1,
            })
        );
        handleManifestsRefetch(true);
    };

    const handleSelectAllRows = () => {
        const allRows = data?.ids ?? [];
        setSelectedRows(allRows);
    };

    const searchFulfillmentByUrlParams = (fulfillmentId, remoteOrderId) => {
        const state = store.getState();
        const utils = state.utils;
        const filters = utils.manifestsFilters;
        setFulfillmentUrlParams({
            fulfillment_id: fulfillmentId,
            remote_order_id: remoteOrderId,
        });
        getAllManifests(
            {
                ...filters,
                location: null,
                searchTerm: remoteOrderId,
                page: 1,
                date_range: [],
            },
            false
        ).then((response) => {
            console.log({ response });
            const filter = {
                andOr: '',
                condition: 'is',
                field: 'remote_order_id',
                value: remoteOrderId,
                consolidation: false,
                isRemovable: true,
                id: 'fulfillment_url_params',
                compromiseValues: [],
            };
            dispatch(overrideFilters([...filters.filters, filter]));
        });
        // window.history.replaceState(null, null, window.location.pathname);
    };

    const handleOnInit = () => {
        const searchParams = new URLSearchParams(params.search);
        const fulfillmentId = searchParams.get('fulfillment_id');
        const remoteOrderId = searchParams.get('remote_order_id');
        if (fulfillmentId && remoteOrderId) {
            handleChangeTab('pending_delivery', true);
            searchFulfillmentByUrlParams(fulfillmentId, remoteOrderId);
            return;
        }

        handleChangeTab('pending_delivery');
        if (!userPreferences?._id) return;
        if (deliveryPreferences?.delivery_options && !haveShipping) {
            handleChangeTab('pending_pickup');
        }
        if (deliveryPreferences?.delivery_options && !pickupHaveTwoStep && !shippingHaveTwoStep)
            handleChangeTab('delivered_to_courier');

        handleManifestsRefetch();
    };

    useEffect(() => {
        const tab = userPreferences?.preferences[PREFERENCES]?.tabs?.find(
            (tab) => tab.value === selectedTab
        );

        if (!tab || tab?.typeOfTab === 'basic') {
            setTabColumns(defaultColumnsView);
            return;
        }

        setTabColumns(tab.columns);
    }, [selectedTab, selectedTabIndex]);

    // Para actualizar con el punto
    const forceRefetchWithButton = (event) => {
        const key = event?.key;
        if (key === '.') {
            handleManifestsRefetch(true);
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', forceRefetchWithButton);
        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton, true);
            dispatch(clearFilters());
            dispatch(setManifestsFilters({}));
        };
    }, []);

    useEffect(() => {
        if (Array.from(massiveManifestsToProcess ?? []).length > 0) {
            setSelectedRows(massiveManifestsToProcess.map((item) => item._id));
        }
    }, [massiveManifestsToProcess]);

    useEffect(() => {
        setSelectedRows([]);
    }, [selectedTab, selectedTabIndex, filters, selectedDateRange]);

    const { excludeTabs, haveShipping, havePickup, shippingHaveTwoStep, pickupHaveTwoStep } =
        useMemo(() => {
            const excludeTabs = [];
            if (!userPreferences?._id) return {};

            const shippingHaveTwoStep =
                deliveryPreferences?.delivery_options?.shipping?.enable_two_step_delivery;
            const pickupHaveTwoStep =
                deliveryPreferences?.delivery_options?.pickup?.enable_two_step_delivery;

            const haveShipping = deliveryPreferences?.delivery_method_shipping;
            const havePickup = deliveryPreferences?.delivery_method_pickup;

            if (!haveShipping) excludeTabs.push('pending_delivery');
            if (!pickupHaveTwoStep) excludeTabs.push('pending_pickup');

            return {
                excludeTabs,
                shippingHaveTwoStep,
                pickupHaveTwoStep,
                haveShipping,
                havePickup,
            };
        }, [userPreferences, deliveryPreferences]);

    useEffect(() => {
        if (!userLoading && !loadingDeliveryPreferences) {
            handleOnInit();
        }
    }, [
        shippingHaveTwoStep,
        pickupHaveTwoStep,
        haveShipping,
        havePickup,
        userPreferences,
        deliveryPreferences,
    ]);

    const filtersToShow = useMemo(() => {
        return filters.filter((filter) => filter.field);
    }, [filters]);

    return (
        <Container extraTitle="Manifiestos">
            <PageHeader title="Manifiestos" withMarginBottom={false} />

            <p style={{ margin: '0.5rem 0 0.2rem' }}>Filtrar métricas y tabla por:</p>
            <Flex direction="row" columnGap="1rem" paddingBottom="2rem">
                <RangePicker loading={loading} setDateCalendar={handleChangeDateRange} />
                <LocationPicker handleChangeLocations={handleChangeLocations} loading={loading} />
            </Flex>
            {/* Container con fechas, ubicaciones y Metricas */}
            <Flex direction="column">
                <CollapsibleContainer>
                    <ManifestsMetrics
                        selectedDateRange={selectedDateRange}
                        selectedLocation={selectedLocation}
                        deliveryPreferences={deliveryPreferences}
                    />
                </CollapsibleContainer>
            </Flex>

            {/* Vistas personalizadas */}
            <ViewTabs
                excludeTabs={excludeTabs}
                userPreferences={userPreferences}
                selectedTab={selectedTab}
                handleChangeTab={handleChangeTab}
                preferences={PREFERENCES}
            />

            {/* Searchbar y botones de acciones */}
            <Flex
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                marginBottom="1.5rem"
                padding="1rem 0px 2rem"
            >
                <Flex className="mt-4" direction="column" rowGap="0.5rem">
                    <Flex direction="row" gap="1rem" alignItems="center">
                        {/** ocultar esto */}
                        <Switch onChange={(checked) => setScanMode(checked)} checked={scanMode} />
                        <span style={{ fontSize: '14px' }}>
                            Modo scan {scanMode ? 'habilitado' : 'deshabilitado'}
                        </span>
                    </Flex>

                    {scanMode ? (
                        <ManifestScanInput
                            refetch={handleManifestsRefetch}
                            dispatchersType={PREFERENCES}
                            disabled={loading}
                            scanMode={scanMode}
                        />
                    ) : (
                        <SearchInput
                            refetch={handleManifestsRefetch}
                            dispatchersType={PREFERENCES}
                            disabled={loading}
                        />
                    )}
                </Flex>
                <Flex gap="1rem">
                    <FilterButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        refetch={handleManifestsRefetch}
                        data={data}
                        modalName="manifests-filter-modal"
                        formName="manifests-filter-form"
                        filters={filters}
                        valueOptionByField={valueOptionByField}
                        dispatchersType={PREFERENCES}
                    />
                    <ColumnsButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        userFetching={userFetching}
                        tabColumns={tabColumns}
                        handleChangeTabColumns={handleChangeTabColumns}
                        formName="manifests-column-form"
                        modalName="manifests-column-modal"
                        dispatchersType={PREFERENCES}
                    />
                    <ShowQuantityItems
                        tablePageSize={tablePageSize}
                        handleChangePageSize={handleChangePageSize}
                        disabled={loading}
                        dispatchersType={PREFERENCES}
                        refetch={handleManifestsRefetch}
                    />
                    <ManifestsActions
                        disabled={loading || selectedRows.length < 1}
                        selected={selectedRows}
                        selectedTab={selectedTab}
                        deliveryPreferences={deliveryPreferences}
                        data={data?.docs}
                        handleRefetch={handleManifestsRefetch}
                    />
                </Flex>
            </Flex>

            {/* Filtros seleccionados */}
            <Flex gap="0.75rem" marginBottom="1rem">
                <span>Filtros: </span>
                {filtersToShow.length === 0 ? (
                    <InfoFilter loading={loading} />
                ) : (
                    filtersToShow?.map((filter) => (
                        <InfoFilter
                            key={filter.id}
                            filter={filter}
                            onDelete={handleDeleteFilter}
                            loading={loading}
                            showDeleteIcon
                            preferences={PREFERENCES}
                            valueOptionByField={valueOptionByField}
                        />
                    ))
                )}
            </Flex>

            <Flex direction="row" alignItems="center">
                {selectedRows.length === 0 ? (
                    <b style={{ display: 'block' }} className="text-sm">
                        No tienes pedidos seleccionados
                    </b>
                ) : (
                    <b style={{ display: 'block' }} className="text-sm">
                        En esta vista tienes seleccionado{' '}
                        <strong>
                            {selectedRows.length} pedido(s) de {data?.totalDocs}
                        </strong>{' '}
                        {filtersToShow.length > 0 && '(Aplican filtros)'}
                    </b>
                )}
                <Divider type="vertical" style={{ marginBottom: '25px' }} />
                {data?.totalDocs > 0 && data?.totalPages > 1 && (
                    <b
                        className="text-sm"
                        role="button"
                        onClick={() => {
                            if (selectedRows.length === data?.totalDocs) {
                                setSelectedRows([]);
                            } else {
                                setSelectedRows(data.ids);
                            }
                        }}
                        style={{ display: 'block' }}
                    >
                        <u>
                            {data?.totalDocs === selectedRows.length
                                ? 'Deseleccionar todos'
                                : 'Seleccionar todos'}{' '}
                            ({data?.totalDocs})
                        </u>
                    </b>
                )}
            </Flex>
            {/* Table */}
            {!loadingDeliveryPreferences && (
                <DraggableTable
                    data={data}
                    loading={loading}
                    userPreferences={userPreferences}
                    selectedTab={selectedTab}
                    refetch={handleManifestsRefetch}
                    selectedRows={selectedRows}
                    handleSelectRows={handleSelectRows}
                    tabColumns={tabColumns}
                    handleChangeColumns={handleChangeColumns}
                    preferences={PREFERENCES}
                    deliveryPreferences={deliveryPreferences}
                    massiveManifestsToProcess={massiveManifestsToProcess}
                    scanMode={scanMode}
                />
            )}
        </Container>
    );
};

export default Manifests;
