import { Button } from '@andescommercecloud/beflow-design-system';
import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import axios from 'axios';
import CreateManualIncidenceOrderDrawer from 'components/ManualIncidences/Order/CreateIncidenceDrawer';
import ModalWithButton from 'components/ModalWithButton';
import Flex from 'components/Utils/Flex';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import MenuItem from 'components/V2/Menu/MenuItem';
import history from 'helpers/history';
import printMassiveZpl from 'helpers/printMassiveZpl';
import usePrintFulfillmentsDocuments from 'hooks/usePrintFulfillmentsDocuments';
import ChangeFulfillmentsCourier from 'pages/Deliveries/components/DeliveriesDataTable/ChangeFulfillmentsCourier';
import MassiveTags from 'pages/Deliveries/components/DeliveriesDataTable/MassiveTags';
import printJS from 'print-js';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useGetMassiveTicketsMutation } from 'redux/features/Deliveries';
import {
    useCreateFulfillmentListMutation,
    useGeneratePickingListPDFMutation,
    useSentFulfillmentsToDeliveryMutation,
} from 'redux/features/Fulfillments';

import { toggleModal } from 'redux/features/Utils';
import StatesValidationModal from 'components/StatesValidationModal';
import { useEffect, useState } from 'react';

const ToDeliveryModal = ({
    fulfillments = [],
    sentToDelivery,
    handleMassivePrint,
    isEnabledShippingNoLabel,
    handlePreparationsRefetch,
}) => {
    const dispatch = useDispatch();
    const modalName = 'fulfillments-to-delivery-tickets-confirmation';
    const [showStatesValidationModal, setShowStatesValidationModal] = useState(false);
    const [statesValidationSuccess, setStatesValidationSuccess] = useState(false);
    const [fulfillmentIdsValidated, setFulfillmentIdsValidated] = useState([]);

    const hasFulfillmentWithoutLabel = fulfillments.some(
        (ful) =>
            ful?.delivery_method === 'shipping' &&
            !ful?.labels?.pdf &&
            !ful?.order?.flags?.disabled_courier_integration
    );

    const showAlert = isEnabledShippingNoLabel && hasFulfillmentWithoutLabel;

    useEffect(() => {
        if (statesValidationSuccess && fulfillmentIdsValidated.length) {
            setStatesValidationSuccess(false);

            sentToDelivery({
                fulfillments: fulfillmentIdsValidated,
            }).then(() => {
                handlePreparationsRefetch(true);
            });
        }
    }, [statesValidationSuccess, fulfillmentIdsValidated]);

    return (
        <>
            <StatesValidationModal
                fulfillmentIds={fulfillments.map((ful) => ful._id)}
                action="sent_fulfillments_to_delivery"
                showStatesValidationModal={showStatesValidationModal}
                setShowStatesValidationModal={setShowStatesValidationModal}
                setStatesValidationSuccess={setStatesValidationSuccess}
                setFulfillmentIdsValidated={setFulfillmentIdsValidated}
                handleRefetch={handlePreparationsRefetch}
                justRefetchAfterClose={true}
            />

            <ModalWithButton
                buttonType="menuitem"
                modalProps={{
                    name: modalName,
                    title: (
                        <span style={{ fontWeight: 'bold' }}>
                            {' '}
                            Enviar ({fulfillments.length}) pedido(s) a entregas{' '}
                        </span>
                    ),
                    destroyOnClose: true,
                    okText: 'Aceptar',
                    footer: null,
                }}
                buttonProps={{
                    buttonText: `Enviar a entregas (${fulfillments.length})`,
                    disabled: fulfillments.length === 0,
                }}
            >
                <Flex direction="column">
                    {showAlert ? (
                        <>
                            <b>
                                Estás intentando enviar a entregas uno o más pedidos que no cuentan
                                con etiquetas de courier.
                            </b>
                            <br />
                            <b>¿Confirmas el envío a entregas aunque no cuente con etiquetas?</b>
                            <br />
                        </>
                    ) : (
                        <>
                            <b>
                                ¿Confirma que imprimió y pegó las etiquetas de couriers para las
                                órdenes seleccionadas?
                            </b>
                            <br />
                        </>
                    )}
                    <Flex justifyContent="flex-end" direction="row" columnGap="1rem">
                        <Button
                            onClick={() => {
                                !showAlert && handleMassivePrint(fulfillments);
                                dispatch(toggleModal(modalName));
                            }}
                            variation="secondary"
                        >
                            <b>{showAlert ? 'Cancelar' : 'Imprimir'}</b>
                        </Button>
                        <Button
                            onClick={() => {
                                dispatch(toggleModal(modalName));

                                setShowStatesValidationModal(true);
                            }}
                        >
                            <b>Confirmar</b>
                        </Button>
                    </Flex>
                </Flex>
            </ModalWithButton>
        </>
    );
};

const PreparationActions = ({
    selected = [],
    disabled = false,
    fulfillments = [],
    preparationPreferences,
    handlePreparationsRefetch,
    setSelectedRows,
}) => {
    const [getTickets] = useGetMassiveTicketsMutation();
    const [createFulfillmentList] = useCreateFulfillmentListMutation();
    const [sentToDelivery] = useSentFulfillmentsToDeliveryMutation();
    const [generatePrintList] = useGeneratePickingListPDFMutation();
    const handlePrintDocuments = usePrintFulfillmentsDocuments();
    const [showStatesValidationModal, setShowStatesValidationModal] = useState(false);
    const [statesValidationSuccess, setStatesValidationSuccess] = useState(false);
    const [fulfillmentIdsValidated, setFulfillmentIdsValidated] = useState([]);

    const handleMultiplePreparations = async (data) => {
        const [fulfillmentToPrepared, hasFulfillmentWithoutDteCannotPrepared] = data.reduce(
            (acc, ful) => {
                const orderWithoutDteCannotPrepared =
                    ful?.sales_channel?.order_without_dte_cannot_prepared;
                if (orderWithoutDteCannotPrepared) {
                    const hasDTE = ful?.order?.documents?.find((doc) =>
                        ['DTE_TICKET', 'DTE_INVOICE'].includes(doc?.type)
                    );
                    if (!hasDTE) {
                        acc[1].push(ful);
                    } else {
                        acc[0].push(ful);
                    }
                } else {
                    acc[0].push(ful);
                }
                return acc;
            },
            [[], []]
        );

        if (hasFulfillmentWithoutDteCannotPrepared.length) {
            for (const element of hasFulfillmentWithoutDteCannotPrepared) {
                toast.error(
                    `El fulfillment ${element?.name} no puede ser preparado debido a facturación electrónica pendiente.`
                );
            }
            return;
        }

        const response = await createFulfillmentList({
            fulfillments: fulfillmentToPrepared?.map((ful) => ful._id),
            type: 'preparation',
        });

        if (response?.data) {
            const first_fulfillment = response.data.fulfillments[0];
            history.push(`/fulfillments/${first_fulfillment}/${response.data._id}`);
        }
    };

    const isEnabledShippingNoLabel = preparationPreferences?.enable_shipping_no_label;
    const fulfillmentsForDelivery = Array.from(fulfillments).filter((ful) => {
        if (selected.includes(ful._id) && ful?.status === 'ready_for_deliver') {
            if (ful?.delivery_method === 'pickup') return true;
            if (ful?.delivery_method === 'shipping') {
                return (
                    isEnabledShippingNoLabel ||
                    ful?.labels?.pdf ||
                    ful?.order?.flags?.disabled_courier_integration
                );
            }
            return false;
        }
        return false;
    });

    const fulfillmentsForPreparation = Array.from(fulfillments)
        .filter((ful) => selected.includes(ful?._id))
        .filter((ful) => !['ready_for_deliver'].includes(ful?.status));

    const handleMassivePrint = async (data) => {
        const beFlowCourierId = '641cc035af2a3b7aac65545b';
        const shippingFulfillmentWithoutTickets = Array.from(data)
            .filter((ful) => selected.includes(ful?._id))
            .filter((ful) => ful?.delivery_method === 'shipping')
            .filter(
                (ful) =>
                    ful?.courier?._id === beFlowCourierId ||
                    ful?.courier?.public_name.includes('be-flow') ||
                    ful?.labels?.pdf === 'dummy.pdf' ||
                    [null, '', undefined].includes(ful?.labels?.pdf)
            );

        if (shippingFulfillmentWithoutTickets.length > 0) {
            toast.error(
                'Ha seleccionado pedidos donde al menos uno de ellos no posee etiquetas de operador logístico, por favor verifique la selección y reintente.'
            );
        } else {
            const { data } = await getTickets(selected);
            printJS(data?.url);
            if (data.zpl_url) {
                try {
                    const response = await axios.get(data.zpl_url);
                    setTimeout(() => {
                        printMassiveZpl(response.data);
                    }, 1000);
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    const generateMassivePrintListPDF = async () => {
        const pickingList = await generatePrintList({
            fulfillments: selected,
        });
        if (pickingList?.data?.url) {
            printJS(pickingList?.data?.url);
        }
    };

    const handleIncidenceDrawerClose = ({ idsWithIncidences }) => {
        setSelectedRows((state) => state.filter((id) => !idsWithIncidences.includes(id)));
    };

    useEffect(() => {
        if (statesValidationSuccess && fulfillmentIdsValidated.length) {
            const fulfillmentsForPreparationClean = fulfillmentsForPreparation.filter((ful) =>
                fulfillmentIdsValidated.includes(ful._id)
            );

            setStatesValidationSuccess(false);
            handleMultiplePreparations(fulfillmentsForPreparationClean);
        }
    }, [statesValidationSuccess, fulfillmentIdsValidated]);

    const menu = (
        <Menu>
            <MenuItem
                onClick={() => {
                    setShowStatesValidationModal(true);
                }}
                key={1}
                disabled={fulfillmentsForPreparation.length === 0}
            >
                Preparar ({fulfillmentsForPreparation.length})
            </MenuItem>

            <ToDeliveryModal
                isEnabledShippingNoLabel={isEnabledShippingNoLabel}
                selectedRows={selected}
                fulfillments={fulfillmentsForDelivery}
                handleMassivePrint={handleMassivePrint}
                sentToDelivery={sentToDelivery}
                key={2}
                handlePreparationsRefetch={handlePreparationsRefetch}
            />

            <CreateManualIncidenceOrderDrawer
                isMenuItem
                ids={selected}
                fulfillments={fulfillments}
                viewPage="preparation"
                onCloseDrawer={handleIncidenceDrawerClose}
            />

            <MenuItem onClick={() => handlePrintDocuments(fulfillments, selected)} key={4}>
                Imprimir Boletas ({selected?.length})
            </MenuItem>

            <MassiveTags
                selectedRows={selected}
                fulfillments={fulfillments}
                isMenuItem={true}
                key={5}
            />

            <MenuItem onClick={() => handleMassivePrint(fulfillments)} key={6}>
                Imprimir Etiquetas de courier ({selected?.length})
            </MenuItem>

            <ChangeFulfillmentsCourier
                onSuccess={handlePreparationsRefetch}
                fulfillments={fulfillments}
                selectedRows={selected}
                key={7}
                module="preparation"
            />

            <MenuItem onClick={generateMassivePrintListPDF} key={8}>
                Imprimir lista de preparación
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <StatesValidationModal
                fulfillmentIds={fulfillmentsForPreparation.map((ful) => ful._id)}
                action="prepare_fulfillments"
                showStatesValidationModal={showStatesValidationModal}
                setShowStatesValidationModal={setShowStatesValidationModal}
                setStatesValidationSuccess={setStatesValidationSuccess}
                setFulfillmentIdsValidated={setFulfillmentIdsValidated}
                handleRefetch={handlePreparationsRefetch}
                justRefetchAfterClose={true}
            />

            <Dropdown disabled={disabled} overlay={menu}>
                <PrimaryButton
                    icon={<ChevronDownIcon width={24} height={24} />}
                    iconPosition="right"
                >
                    Acciones ({selected.length})
                </PrimaryButton>
            </Dropdown>
        </>
    );
};

export default PreparationActions;
