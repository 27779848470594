import { Button, DatePicker, Select } from 'antd';
// import TCard from 'components/TCard';
import styled, { css } from 'styled-components';

export const CountReports = styled.section``;

export const Report = styled.div`
    padding: 1rem .2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const TitleReport = styled.div`
    p {
        small {
            font-size:0.86rem;
        }
    }
    span {
        display: flex;
        align-items: center;
        gap: 0.26rem;
        
        b {
            font-size: 1rem;
            font-weight: 500;
            color: #142c4a;
            line-height: 1.2rem;

            ${({ $isSecondary }) =>
                $isSecondary &&
                css`
                    font-size: 0.86rem;
                    font-weight: 500;
                    color: #536d8f;
                    line-height: 1.2rem;
                `}}

            svg {
                display: inline-block;
                width: 24px;
                height: 24px;
                vertical-align: sub;
                margin-left: 6px;
                color: #2D3D76;
            }
        }
    }
`;

export const CountReportsCardsUP = styled.div`
    display: grid;
    min-width: 0;
    min-height: 0;
    overflow: visible;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: 1rem;
    background: #fdfdfd;
    border: 1px solid #e4e8ec;
    box-shadow: 2px 0px 2px rgba(45, 61, 118, 0.2);
    border-radius: 6px;

    .total_orders_report {
        background: #2d3d76;
        border-radius: 6px 0px 80px;
        width: 210px;
        b {
            font-size: 20px;
        }
        b,
        h1 {
            color: white !important;
        }
    }

    .ongoing_orders_report {
        b {
            text-decoration-line: underline;
            color: #f27127 !important;
        }
    }

    @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        .report-spacer {
            display: none;
        }
    }
`;

export const CountReportsCardsDown = styled.div`
    display: grid;
    min-width: 0;
    min-height: 0;
    overflow: visible;
    grid-template-columns: 210px 1fr;
    grid-template-rows: repeat(3, 1fr);

    background: #fdfdfd;
    border: 1px solid #e4e8ec;
    box-shadow: 2px 0px 2px rgba(45, 61, 118, 0.2);
    border-radius: 6px;

    justify-items: stretch;
    align-items: stretch;

    .in_progress_orders_report_principal {
        background: #f27127;
        border-radius: 6px 0px 80px;
        width: 100%;
        max-width: 210px;
        gap: 1.6rem;
        b {
            font-size: 20px;
        }
        b,
        h1 {
            color: white !important;
        }
    }

    .count_reports_card_down_left {
        display: grid;
        min-width: 0;
        min-height: 0;
        overflow: visible;
        grid-row: span 3;
        grid-column: 1;
        background: #ffffff;
        border-right: 2px solid #f5f5f5;
        border-radius: 0px 0px 0px 5px;
        filter: drop-shadow(4px 0px 4px rgba(225, 225, 225, 0.05));
    }

    .count_reports_card_down_right {
        display: grid;
        min-width: 0;
        min-height: 0;
        overflow: visible;
        grid-row: span 3;
        grid-column: 2;
        align-items: baseline;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        padding: 0rem 1rem;
        .fulfilled_orders_report,
        .delivered_to_courier_orders_reports {
            position: relative;
            padding-right: 2rem;

            &:after {
                content: '';
                position: absolute;
                top: 10%;
                right: 0;
                width: 0;
                height: 80%;
                border-right: 3px solid rgba(3, 104, 150, 0.3);
                box-sizing: border-box;
            }
            @media (max-width: 1200px) {
                padding-right: 1rem;
            }
        }

        .unfulfilled_orders_report {
            grid-column: 1;
            grid-row: 1;
        }
        .in_progress_orders_report {
            grid-column: 2;
            grid-row: 1;
        }
        .paused_orders_report {
            grid-column: 3;
            grid-row: 1;
        }
        .count_reports_background_1 {
            grid-column: span 3;
            grid-row: 2;
            background: #f7f9fb;
            border: 1px solid #f5f5f5;
            border-radius: 6px;
            padding: 1rem 0rem;
        }
        .count_reports_background_2 {
            grid-column: span 3;
            margin-bottom: 1rem;
            grid-row: 3;
            background: #f7f9fb;
            border: 1px solid #f5f5f5;
            border-radius: 6px;
            padding: 1rem 0rem;
        }
    }
`;

export const CountReportsSAC = styled.div`
    display: grid;
    min-width: 0;
    min-height: 0;
    overflow: visible;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .sac_orders_report {
        grid-row: 1;
        grid-column: 1;
        color: #2d3d76;
        p {
            color: #036896;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            margin-top: 17px;
        }
    }
    .sac_pending_attention {
        grid-row: 2;
        grid-column: 1;
        padding: 10px;
    }
    .sac_process_solution {
        grid-row: 3;
        grid-column: 1;
        padding: 10px;
    }
`;

export const ReportBodySac = styled.div`
    display: flex;
    gap: 0.8rem;

    h5 {
        margin-top: 30px;
        color: #536d8f;
    }
`;
// ------------------------------------------------
export const ReportCardTitle = styled.b`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #142c4a;
    margin-top: ${({ $MarginTop }) => ($MarginTop ? $MarginTop : '')};
    margin-bottom: 1rem;
`;

export const ReportTitle = styled.b`
    font-weight: 500;
    font-size: 14px;
    color: #7998a7;
    display: flex;
    flex-direction: row;
    margin-top: ${({ $MarginTop }) => ($MarginTop ? $MarginTop : '1rem')};
`;

export const GraphicsReports = styled.main`
    display: grid;
    min-width: 0;
    min-height: 0;
    overflow: visible;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1.2rem;

    .home-dashboard__fill-rate {
        grid-column: span 2;
        height: 100%;
        max-height: 400px;
        .graph {
            height: 94%;
        }
    }

    .home-dashboard__fill-cycle {
        grid-column: 1;
        grid-row: 2;
        height: 100%;
        max-height: 400px;
        .graph {
            height: 94%;
        }
    }

    .home-dashboard__perfect-order {
        grid-column: 2;
        grid-row: 2;
        height: 100%;
        max-height: 400px;
        .graph {
            height: 94%;
        }
    }

    @media (max-width: 1640px) {
        .home-dashboard__fill-rate {
            grid-column: span 2;
            grid-row: 1;
        }
        .home-dashboard__fill-cycle {
            grid-column: span 2;
            grid-row: 2;
        }
        .home-dashboard__perfect-order {
            grid-column: span 2;
            grid-row: 3;
        }
    }
`;

export const GraphicCard = styled.div`
    background: #f7f9fb;
    border-radius: 16px;
    padding: 1rem;
    width: 100%;
    height: 100%;
`;

export const PageLayout = styled.main`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    min-width: 0;
    min-height: 0;
    overflow: visible;
    width: 100%;
    max-width: 2440px;

    .grid1 {
        grid-row: 1;
        grid-column: 1;
    }
    .grid2 {
        grid-row: 2;
        grid-column: 1;
    }
    .grid-graphics {
        grid-row: span 3;
        grid-column: 2;
    }

    @media (max-width: 1640px) {
        grid-template-columns: 1fr 0.5fr;
    }

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
        max-width: 1200px;
        .grid1 {
            grid-row: 1;
            grid-column: 1;
        }
        .grid2 {
            grid-row: 2;
            grid-column: 1;
        }
        .grid-graphics {
            grid-row: 3;
            grid-column: 1;
        }
    }
`;

export const ReportsWithBackground = styled.div`
    background-color: #f7f9fb;
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    @media (max-width: 1200px) {
        gap: 0px;
    }
`;

export const ReportCount = styled.h1`
    font-size: 36px;
    color: #2d3d76;
    font-weight: 700;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 36px;

    ${({ $isSecondary }) =>
        $isSecondary &&
        css`
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2rem;
        `}}

    h5 {
        color: #536d8f;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin: 0px;
        margin-left: 0.6rem;
    }
`;

export const ReportOrdersBody = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-width: 0;
    min-height: 0;
    overflow: visible;
    ${ReportCount} {
        border-right: 1px solid #e4e8ec;
        margin-right: 1rem;
        &:last-child {
            border-right: none;
            margin-right: 0;
        }
    }
`;

export const ReportCardContent = styled.div`
    background: #f7f9fb;
    ${({ $color }) =>
        $color
            ? css`
                  border-left: 4px solid ${$color};
              `
            : ''}

    ${({ $withoutShadow }) =>
        $withoutShadow ? '' : 'box-shadow: 2px 0px 2px rgba(45, 61, 118, 0.2);'}

    border-radius: 6px;
    height: 100%;
    padding: 1rem;
    width: calc(100% - 30px);
    /* 
    display: flex;
    flex-direction: column;
    place-content: center; */

    .loader {
        position: absolute;
        top: 70%;
        right: 10%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner {
        position: absolute;
        top: 50%;
        right: 50%;
        // transform: translate(50%, -50%);
        width: 30px;
        height: 30px;
        border: 2px solid #1890ff;
        border-top-color: transparent;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

export const ReportCardIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    background: #f7f9fb;
    box-shadow: 2px 0px 2px rgba(45, 61, 118, 0.2);
    border-radius: 50%;
    height: 60px;
    width: 60px;
    position: absolute;
    // border: 1px solid red;
 }

    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    z-index: 100;
    display: flex;
`;

export const ReportCard = styled.section`
    position: relative;
`;

export const DotDashboard = styled.div`
    background-color: ${(props) => props.color && props.color};
    width: ${(props) => props.size || '18px'};
    height: ${(props) => props.size || '18px'};
    ${(props) => props.borderColor && `border: 1.5px solid ${props.borderColor}`};
    border-radius: 50%;
    margin-top: 7px;
    margin-right: 5px;
`;

export const PageButton = styled(Button)`
    font-weight: 500;
    border: 1px solid #2d3d76;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
`;

export const PageDatePicker = styled(DatePicker.RangePicker)`
    font-weight: 500;
    border: 1px solid #2d3d76;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
`;

export const TitleDashboard = styled.div`
    color: #2d3d76;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;
`;
export const FiltersContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 1rem;

    b {
        padding-top: 0.4rem;
        // letter-spacing: 1px;
        padding-left: 0.2rem;
        margin-bottom: 1.2rem;
        color: #41515e;
    }
`;
export const MetricCard = styled.article``;

export const MetricWrapper = styled.div`
    display: grid;
    min-width: 0;
    min-height: 0;
    overflow: visible;
    // grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: 90px;
    // grid-auto-rows: ${({ $gridAutoRows }) => ($gridAutoRows ? $gridAutoRows : '90px')};
    // gap: 1.5rem;
    // margin-top: 1rem;
    margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : '1rem')};
`;

export const MetricCardContent = styled.div`
    // width: calc(100% - 30px);
    width: ${({ $width }) => ($width ? $width : 'calc(100% - 30px)')};
    // height: 100%;
    height: ${({ $height }) => ($height ? $height : '100%')};
    background: #f7f9fb;
    border-left: 5px solid ${({ $color }) => ($color ? $color : '#0497db')};
    box-shadow: 2px 0px 2px rgba(45, 61, 118, 0.2);
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .jRmFob iGXNnM {
        grid-template-columns: none !important;
    }

    h4 {
        color: #2d3d76;
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 0;
    }

    h2 {
        color: #0497db;
        font-size: 1.5rem;
        margin-top: 0;
    }
    h5 {
        color: #0497db;
        margin-top: 0.6rem;
    }

    p {
        color: #0497db;
        margin-bottom: 0px;
        font-weight: 500;
    }
`;

export const SacContent = styled.div`
    display: grid;
    min-width: 0;
    min-height: 0;
    overflow: visible;
    grid-template-columns: 1.8fr 0.5fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .title_sac {
        font-size: 14px;
        color: #536d8f;
        margin-top: 20px;
        margin-bottom: 0px;
        font-weight: 500;
        line-height: 16px;
    }
    .quantity_sac {
        font-size: 22px;
        color: #b00303;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 0px;
        margin-left: 10px;
    }
    .quantity_process_solution {
        color: #e3cd06;
        margin-left: 5px;
    }
    .sac_percentage_pending_attention,
    .sac_percentage_process_solution {
        color: #536d8f;
        font-size: 14px;
        p {
            margin-bottom: 0px;
        }
    }
`;

// -------------------------------------------------------SAC--------------------------------------------------
export const SacTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
export const SacBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > * {
        flex-basis: 25%;
        flex-grow: 1;
        flex-shrink: 1;
        margin-right: 30px; /* ajusta el valor del margen según tus necesidades */
    }
    & > *:last-child {
        margin-right: 0;
    }
`;

export const SacPercentage = styled.div`
    color: #0497db;
    font-size: 24px;
    font-weight: 700;
    display: flex;

    p {
        color: #0497db;
        font-size: 18px;
        font-weight: 500;
        margin-top: 0.5rem;
        margin-left: 0.3rem;
    }
`;
export const SacPercentages = styled.div`
    color: #2e3e78;
    font-size: 18px;
    font-weight: 700;
    & > *:last-child {
        margin-right: 0;
    }
`;

export const LineSac = styled.div`
    width: 120px;
    height: 5px;
    border-radius: 0.5rem;
    background-color: #dce5ec;
    margin-top: 0.7rem;
`;

export const LineSacFilled = styled.div`
    height: 0.3rem;
    border-radius: 0.5rem;
    background-color: ${({ $backgroundColor }) =>
        $backgroundColor ? $backgroundColor : '#b00303'};
`;

// -------------------------------------------------------SAC--------------------------------------------------
export const SearchSelectStyled = styled(Select)`
    background: #ffffff;
    border: 1px solid #2d3d76;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    height: 2.5rem;
    width: 11rem;
    .ant-select-selector,
    select {
        height: 100% !important;
        border: none !important;
        display: flex;
        align-items: center;
    }
    .ant-select-selection-item {
        font-weight: 500;
        color: #536d8f !important;
        background-color: transparent;
        border: none;
    }
    .ant-select-selection-placeholder {
        color: #536d8f;
    }
    ${({ type }) =>
        type === 'location' &&
        `.ant-select-arrow {
            right: 4px;
            top: 48%;
        }`}
`;

// export const LabelSpanStyled = styled.span`
//     color: #536d8f;
//     font-size: 14px;
//     font-weight: 500;
// `;

export const LabelSpanStyled = styled.span`
    color: #536d8f;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    // margin-left: 11px;
    position: absolute;
    background: white;
    // top: 113px;
    // left: 211px;
    width: 165px;
    text-align: center;
    cursor: pointer;
`;

export const MetricName = styled.div`
    font-weight: 400;
    font-size: 1rem;
    color: #7998a7;
    margin-bottom: ${({ $marginBottom }) => ($marginBottom ? $marginBottom : '10px')};
`;

export const MetricDetail = styled.div`
    display: flex;
    flex-direction: row;
    color: #2d3d76;
    h1,
    h3 {
        margin: 0;
        color: #2d3d76;
    }
    h1,
    h2 {
        display: block;
        margin-right: 6px;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1rem;
    }
    h3 {
        display: block;
        font-size: 1rem;
        font-weight: 300;
    }
    h2 {
        margin-left: 1rem;
        color: #2d3d76;
    }
`;

export const MetricIconCard = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: ${({ $width }) => ($width ? $width : '60px')};
    height: ${({ $height }) => ($height ? $height : '60px')};
    z-index: 20;
    border-radius: 50%;
    background-color: #f7f9fb;
    box-shadow: 4px 0px 3px 0px rgba(165, 145, 145, 0.68);
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
`;

export const SmallTitle = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #536d8f;
    margin-top: 0.8rem;
`;

export const WrapperGrafics = styled.div`
    background-color: #f7f9fb;
    padding: 1rem;
    // width: 200px;
    // height: 40%;
`;

export const SubtitleGrafic = styled.div`
    color: #2d3d76;
    font-weight: 500;
    text-align: center;
    margin-top: 0.5rem;
`;
export const LebelsGrafics = styled.div`
    color: #142c4a;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 1rem;
`;

export const TitleGraphs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    b {
        margin: 0px;
        display: block;
        margin-right: 0.5rem;
    }
`;

export const TitleTooltip = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 700;
`;

export const SkeletonLayout = styled.div`
    display: grid;
    min-width: 0;
    min-height: 0;
    overflow: visible;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 10px;

    .loading-total-orders {
        grid-area: 1 / 1 / 2 / 2;
    }
    .loading-order_states {
        grid-area: 1 / 2 / 2 / 5;
    }
    .loading-delivered_to_courier {
        grid-area: 2 / 1 / 3 / 3;
    }
    .loading-delivered {
        grid-area: 2 / 3 / 3 / 5;
    }
    .loading-paused_report {
        grid-area: 3 / 1 / 4 / 3;
    }
    .loading-canceled {
        grid-area: 3 / 3 / 4 / 5;
    }
    .loading-sac {
        grid-area: 4 / 1 / 5 / 5;
    }
    .loading-graph-fill-rate {
        grid-area: 3 / 5 / 5 / 7;
    }
    .loading-graph-fill-cicle {
        grid-area: 3 / 7 / 5 / 9;
    }
    .loading-fill-pie {
        grid-area: 1 / 5 / 3 / 9;
    }
`;
