import React, { Suspense, useEffect, useState } from 'react';
import { Button, ConfigProvider, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactDragListView from 'react-drag-listview';
import dayjs from 'dayjs';
import AntdTable from 'components/AntdTable';
import TableEmpty from 'components/Table/Empty';
import Flex from 'components/Utils/Flex';
import { useUpdateUserPreferencesMutation } from 'redux/features/Users';
import { setDeliveriesFilters } from 'redux/features/Utils';
import { LeftOutlined, RightOutlined, WarningOutlined } from '@ant-design/icons';
import TableHeaderExtension from '../TableHeaderExtension';
import Subtable from '../Subtable';
import { FiChevronDown } from 'react-icons/fi';
import Loading from 'components/Loading';
import { DialogCourierDiff } from 'pages/DeliveriesV2/dialogs';
import toast from 'react-hot-toast';
import capitalizeStrings from 'helpers/capitalizeStrings';
import { deliveryPromiseDateFormatDic } from 'helpers/constants/deliveryPromiseDateFormat';
import { useGetOrdersConfigurationQuery } from 'redux/features/OrdersConfiguration';
import { isML } from 'helpers/isML';
import useCurrentUser from 'hooks/useCurrentUser';
import FulfillmentsTransferLocations from 'components/TagsV2/FulfillmentTransferLocations';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';
import TokenBasedPagination from 'components/V2/TokenBasedPagination';

const FulfillmentDeadline = React.lazy(() =>
    import('pages/OrdersV2/components/Table/FulfillmentDeadline')
);
const FulfillmentStatus = React.lazy(() => import('../../components/FulfillmentStatus'));

function DraggableTable({
    data,
    loading,
    userPreferences,
    selectedTab,
    refetch,
    selectedRows,
    handleSelectRows,
    tabColumns,
    handleChangeColumns,
    preferences,
    scanMode,
    expandedKeys,
    setExpandedKeys,
    dataPickupsPreferences,
    dataPreparations,
    dataList,
    updateList,
    handleInsertFulfillments,
    fulfillmentSelected,
}) {
    const useTokenBasedPagination = useGetValueFeatureFlag(
        'pagination',
        'use_token_based_pagination'
    );

    const { deliveriesFilters } = useSelector((state) => state.utils);
    const [columnsTable, setColumnsTable] = useState([]);
    const [updateUserPreferences] = useUpdateUserPreferencesMutation();
    const [activeDropdownFilterColumn, setActiveDropdownFilterColumn] = useState(null);
    const { data: orderConfiguration } = useGetOrdersConfigurationQuery();
    const user = useCurrentUser();
    const [page, setPage] = useState(1);

    const deliveryPromiseDateFormat = orderConfiguration?.delivery_promise_date_format;
    const updateUserColumnsPreferencesHandler = async (newColumnsPreferences) => {
        // Debemos verificar si el tab.typeOfTab es basic o custom
        const activeTabIndex = userPreferences?.preferences[preferences]?.tabs?.findIndex(
            (tab) => tab.value === selectedTab
        );

        const newPreferences = structuredClone(userPreferences);

        newPreferences.preferences[preferences].tabs[activeTabIndex].columns = [
            ...newColumnsPreferences,
        ];

        // Eliminar tabs de tipo basic
        newPreferences.preferences[preferences].tabs = newPreferences.preferences[
            preferences
        ].tabs.filter((tab) => tab.typeOfTab !== 'basic');

        await updateUserPreferences(newPreferences);
    };

    useEffect(() => {
        if (columnsTable?.length > 0) {
            const columnsCopy = columnsTable.slice();

            const newColumns = columnsCopy.map((el) => {
                el.className = '';

                if (deliveriesFilters?.sort?.sortBy === el?.dataIndex) {
                    el.className = 'table-column-selected';
                }

                if (activeDropdownFilterColumn && el?.dataIndex === activeDropdownFilterColumn) {
                    el.className = 'table-column-active';
                }

                return el;
            });

            setColumnsTable(newColumns);
        }
    }, [deliveriesFilters, activeDropdownFilterColumn]);

    const dispatch = useDispatch();

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedRows,
        onSelect: (row, selected, selectedRowsTable) => {
            const selectedRowKeys = selectedRowsTable.map(
                (f, index) => f?._id || selectedRows?.[index]
            );

            if (scanMode) {
                if (!selected && row.packages?.find((p) => p.status === 'processed'))
                    return toast.error(
                        'Para poder suprimir el pedido de la entrega debe suprimir el/los bultos escaneados, a través de las acciones por bulto.'
                    );

                const excludeSelectRow = dataList
                    .filter((f) => !selectedRowKeys.includes(f._id))
                    .map((f) => f._id);

                updateList((state) => {
                    const fulfillments = state.filter((f) => !excludeSelectRow.includes(f._id));

                    handleInsertFulfillments(
                        fulfillments,
                        fulfillments.filter((f) => excludeSelectRow.includes(f._id))
                    );
                    return fulfillments;
                });
            }
        },
        onChange: (selectedRows, selectedRowRecords) => {
            return handleSelectRows(selectedRows, true, selectedRowRecords);
        },
    };

    // Function para registrar Cambios de orden en los headers
    const onTableHeaderDragEnd = (fromIndex, toIndex) => {
        if (fromIndex === 0 || toIndex === 0) return;

        const columnsCopy = columnsTable.slice();

        const item = columnsCopy.splice(fromIndex - 1, 1)[0];

        columnsCopy.splice(toIndex - 1, 0, item);

        setColumnsTable(columnsCopy);

        const newColumnsViewStatus = columnsCopy.map((el, i) => ({
            name: el.dataIndex,
            label: el.title,
            status: true,
            position: i + 1,
        }));

        const disabledColumnsStatus = tabColumns.filter((el) => !el.status);

        const columnsViewStatusCopy = [...disabledColumnsStatus, ...newColumnsViewStatus];

        handleChangeColumns(columnsViewStatusCopy);

        updateUserColumnsPreferencesHandler(columnsViewStatusCopy);

        // setColumns(columnsViewStatusCopy)

        // dispatch(addTab(personalizedTab));
    };

    // Funcion para registrar cambios en la tabla
    const handleTableChange = (pagination, filters, sorter) => {
        let actuallySort = {};

        actuallySort = {
            sortBy: sorter.field,
            sortValue: sorter.value,
        };

        if (sorter.value === undefined) {
            actuallySort = {};
        }

        dispatch(
            setDeliveriesFilters({
                sort: actuallySort,
                page: pagination.current,
            })
        );

        refetch();
    };

    const tableLoading = {
        spinning: loading,
        indicator: <Loading loadingText="Cargando entregas..." $center />,
    };

    const handleExpandRow = (row_id) => {
        setExpandedKeys((prevState) =>
            prevState.includes(row_id)
                ? prevState.filter((id) => id !== row_id)
                : [...prevState, row_id]
        );
    };

    const allColumns = [
        {
            dataIndex: 'name',
            title: 'ID Fulfillment',
            align: 'center',
            render: (value, row) => (
                <Flex justifyContent="space-between" alignItems="center">
                    <u role="button" onClick={() => handleExpandRow(row?._id)}>
                        {row?.name}
                    </u>
                    {row.status === 'paused' && <WarningOutlined style={{ color: 'red' }} />}
                </Flex>
            ),
            ...TableHeaderExtension({
                columnKey: 'name',
                type: 'string',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
        {
            dataIndex: 'remote_order_id',
            title: 'ID Ecommerce',
            render: (value, row) => <span>{row?.remote_order_id ?? ''}</span>,
            ...TableHeaderExtension({
                columnKey: 'remote_order_id',
                type: 'string',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
        {
            dataIndex: 'origin',
            title: 'Tienda',
            render: (value, row) => {
                if (row?.preparationcd_transfer_pickupstore?.pickup_location) {
                    return (
                        <FulfillmentsTransferLocations
                            preparationcd_transfer_pickupstore={
                                row?.preparationcd_transfer_pickupstore
                            }
                        />
                    );
                }
                return <span>{value?.name}</span>;
            },
            // ...TableHeaderExtension({
            //     columnKey: 'origin',
            //     type: 'string',
            //     refetch,
            //     setActiveDropdownFilterColumn,
            //     tabColumns,
            // }),
        },
        {
            dataIndex: 'locations.destination',
            title: 'Tienda de destino',
            render: (_, row) => {
                if (Array.from(row?.locations?.destination ?? []).length === 1)
                    return row?.locations?.destination?.[0]?.name ?? 'N/A';
                if (Array.from(row?.locations?.destination ?? []).length === 0) return 'N/A';
                return (
                    <span>
                        <Tooltip
                            overlayStyle={{ maxWidth: '400px' }}
                            title={
                                <ul>
                                    {Array.from(row?.locations?.destination ?? []).map(
                                        (location) => (
                                            <li key={location._id}>{location.name}</li>
                                        )
                                    )}
                                </ul>
                            }
                        >
                            Mixto
                        </Tooltip>
                    </span>
                );
            },
            width: '140px',
            ...TableHeaderExtension({
                columnKey: 'locations.destination',
                type: 'string',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
        {
            dataIndex: 'createdAt',
            title: 'Fecha pedido',
            render: (value, row) => <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>,
            ...TableHeaderExtension({
                columnKey: 'createdAt',
                type: 'date',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
        {
            dataIndex: 'promised_date',
            title: deliveryPromiseDateFormatDic(deliveryPromiseDateFormat).title,
            render: (value, row) => {
                const rowToSend = {
                    fulfillment_status: value ?? row.status ?? '',
                    createdAt: row.createdAt ?? '',
                    promised_date: row.promised_date ?? '',
                    deadline_custom: row.deadline_custom ?? '',
                };
                return (
                    <Flex justifyContent="center" alignItems="center">
                        <Suspense fallback={<div>Cargando...</div>}>
                            <FulfillmentDeadline
                                isDeadline={
                                    deliveryPromiseDateFormatDic(deliveryPromiseDateFormat).isHours
                                }
                                row={rowToSend}
                            />
                        </Suspense>
                    </Flex>
                );
            },
            ...TableHeaderExtension({
                columnKey: 'promised_date',
                type: 'number',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
        ...(isML(user?.userData?.merchant_id?._id)
            ? [
                  {
                      dataIndex: 'promised_date_range',
                      title: 'Horario de entrega',
                      render: (value, row) => (
                          <span style={{ whiteSpace: 'nowrap', width: '160px' }}>
                              {row?.promised_date_range ?? '-'}
                          </span>
                      ),
                      ...TableHeaderExtension({
                          columnKey: 'promised_date_range',
                          type: 'string',
                          refetch,
                          setActiveDropdownFilterColumn,
                          tabColumns,
                      }),
                  },
              ]
            : []),
        {
            dataIndex: 'status',
            title: 'Estado entrega',
            render: (value, row) => {
                const status = row?.consolidation?.status ?? row?.preparationcd_transfer_pickupstore?.status ?? value ?? row.status;

                const statusRow = {
                    status: status,
                    createdAt: row.createdAt ?? '',
                    promisedDate: row.promised_date ?? '',
                };

                return (
                    <Flex justifyContent="center" alignItems="center">
                        <Suspense fallback={<div>Cargando...</div>}>
                            <FulfillmentStatus row={statusRow} />
                        </Suspense>
                    </Flex>
                );
            },
            ...TableHeaderExtension({
                columnKey: 'status',
                type: 'string',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
        {
            dataIndex: 'packages',
            title: 'Bultos',
            // align: 'left',
            width: 50,
            render: (value, row) => {
                return (
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        flex={1}
                        columnGap="1rem"
                        direction="row"
                    >
                        <b style={{ width: '20px' }}>{Array.from(row?.packages ?? []).length}</b>
                        <Button
                            style={{
                                color: '#2D3D76',
                                backgroundColor: 'white',
                                width: 'fit-content',
                                height: 'fit-content',
                                fontWeight: 'bold',
                                border: '1px solid #E4E8EC',
                                borderRadius: '6px',
                                padding: '1px',
                            }}
                            onClick={() => handleExpandRow(row?._id)}
                        >
                            <FiChevronDown
                                className="manifest-table__expand-icon"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                }}
                            />
                        </Button>
                    </Flex>
                );
            },
        },
        {
            dataIndex: 'courier',
            title: 'Courier',
            render: (value, row) => (
                <span>{row?.courier?.public_name ?? row?.courier?.courier_name ?? 'N/A'}</span>
            ),
            ...TableHeaderExtension({
                columnKey: 'courier',
                type: 'string',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
        {
            dataIndex: 'delivery_method',
            title: 'Tipo de entrega',
            render: (value, row) => {
                if (row?.delivery_method === 'pickup' && row?.consolidation?.enabled) {
                    return <span>Retiro con consolidación</span>;
                }

                if (row?.delivery_method === 'shipping' && row?.consolidation?.enabled) {
                    return <span>Despacho con consolidación</span>;
                }

                if (row?.delivery_method === 'shipping' && row?.preparationcd_transfer_pickupstore?.cd_location) {
                    return <span>Retiro</span>;
                }

                if (row?.delivery_method === 'pickup') {
                    return <span>Retiro</span>;
                }

                if (row?.delivery_method === 'shipping') {
                    return <span>Despacho</span>;
                }

                if (row?.delivery_method === 'consolidation') {
                    return <span>Consolidado</span>;
                }

                return <span>{row?.delivery_method ?? ''}</span>;
            },
            ...TableHeaderExtension({
                columnKey: 'delivery_method',
                type: 'string',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
        {
            dataIndex: 'customer_name',
            title: 'Cliente',
            render: (_, value) => {
                return (
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            width: '200px',
                        }}
                    >
                        {value?.order?.customer?.first_name &&
                            capitalizeStrings(value?.order?.customer?.first_name)}{' '}
                        {value?.order?.customer?.last_name &&
                            capitalizeStrings(value?.order?.customer?.last_name)}
                    </span>
                );
            },
            ...TableHeaderExtension({
                columnKey: 'customer_name',
                type: 'string',
                refetch,
                setActiveDropdownFilterColumn,
                tabColumns,
            }),
        },
    ];

    useEffect(() => {
        if (tabColumns) {
            const columnsToShow = tabColumns
                .filter((column) => column.status)
                .map((column) => column.name);

            const newColumns = allColumns
                .filter((column) => columnsToShow.includes(column.dataIndex))
                .map((el) => {
                    el.className = '';

                    if (deliveriesFilters?.sort?.sortBy === el?.dataIndex) {
                        el.className = 'table-column-selected';
                    }

                    return el;
                });

            const viewStatusSorted = tabColumns.concat().sort((a, b) => a.position - b.position);

            newColumns.sort((a, b) => {
                const aIndex = viewStatusSorted.findIndex((item) => item.name === a.dataIndex);
                const bIndex = viewStatusSorted.findIndex((item) => item.name === b.dataIndex);

                return aIndex - bIndex;
            });

            setColumnsTable(newColumns);
        }
    }, [tabColumns, deliveryPromiseDateFormat]);

    const handleResetPage = () => {
        dispatch(
            setDeliveriesFilters({
                searchBefore: '',
                searchAfter: '',
                page: 1,
            })
        );
        setPage(1);
        refetch(true);
    };

    const handlePreviousPage = () => {
        dispatch(
            setDeliveriesFilters({
                searchBefore: data?.prevPageToken,
                searchAfter: '',
                page: page - 1,
            })
        );
        setPage(page - 1);
        refetch(true);
    };

    const handleNextPage = () => {
        dispatch(
            setDeliveriesFilters({
                searchBefore: '',
                searchAfter: data?.nextPageToken,
                page: page + 1,
            })
        );
        setPage(page + 1);
        refetch(true);
    };

    useEffect(() => {
        if (!data?.hasPrevPage) setPage(1);
    }, [data]);

    const TableView = (
        <ConfigProvider
            renderEmpty={() => (
                <TableEmpty
                    loading={loading && !dataList?.length}
                    loadingText="Cargando entregas..."
                />
            )}
        >
            <AntdTable
                showSorterTooltip={false}
                locale={{
                    triggerDesc: 'Click para ver pedidos más recientes',
                    triggerAsc: 'Click para ver pedidos más recientes',
                    cancelSort: 'Click para ver pedidos más antiguos',
                }}
                displayNone={columnsTable?.length < 1}
                sortDirections={['descend', 'ascend', 'descend']}
                scroll={{ x: true }}
                tableName="fulfillment-preparations-table"
                onChange={(pagination, filters) =>
                    handleTableChange(pagination, filters, {
                        field: deliveriesFilters?.sort?.sortBy,
                        value: deliveriesFilters?.sort?.sortValue,
                    })
                }
                multiSelect
                rowSelection={rowSelection}
                rowClassName={(record, index) => {
                    if (fulfillmentSelected === record._id) {
                        return 'fulfillment-selected ';
                    }
                    if (expandedKeys.includes(record?._id)) {
                        if (index % 2 === 0) {
                            return 'manifest_table__row-expanded isEven';
                        }
                        return 'manifest_table__row-expanded isOdd';
                    }
                }}
                loading={dataList && tableLoading}
                styleV2={true}
                rowKey="_id"
                headerBackground
                actionsPadding="0px"
                dataSource={dataList}
                columns={columnsTable}
                // columns={allColumns}
                paginationStyle={true}
                {...(!useTokenBasedPagination
                    ? {
                          pagination: {
                              position: ['bottomCenter'],
                              showSizeChanger: false,
                              showQuickJumper: false,
                              showLessItems: true,
                              ...(scanMode
                                  ? {
                                        total: (dataList ?? [])?.length,
                                        current: 1,
                                        pageSize: (dataList ?? [])?.length + 1,
                                    }
                                  : {
                                        total: data?.totalDocs ?? 0,
                                        current: data?.page ?? 1,
                                        pageSize: data?.limit ?? 25,
                                    }),

                              itemRender: (page, type, originalElement) => {
                                  if (type === 'prev') {
                                      return (
                                          <Button
                                              type="text"
                                              icon={<LeftOutlined />}
                                              disabled={data?.page === 1}
                                              onClick={() => {
                                                  handleTableChange(data?.page ?? 1, null, {
                                                      field: deliveriesFilters?.sort?.sortBy,
                                                      value: deliveriesFilters?.sort?.sortValue,
                                                  });
                                              }}
                                          />
                                      );
                                  } else if (type === 'next') {
                                      return (
                                          <Button
                                              type="text"
                                              icon={<RightOutlined />}
                                              disabled={data?.page === data?.totalPages}
                                              onClick={() => {
                                                  handleTableChange(data?.page ?? 1, null, {
                                                      field: deliveriesFilters?.sort?.sortBy,
                                                      value: deliveriesFilters?.sort?.sortValue,
                                                  });
                                              }}
                                          />
                                      );
                                  }
                                  return <>{originalElement}</>;
                              },
                          },
                      }
                    : {})}
                expandable={{
                    expandedRowClassName: (_, index) => {
                        if (index % 2 === 0) {
                            return 'manifest_table__row-expanded isEven';
                        }
                        return 'manifest_table__row-expanded isOdd';
                    },
                    expandedRowKeys: expandedKeys,
                    expandedRowRender: (record) => (
                        <Subtable
                            fulfillment={record}
                            isSelected={selectedRows.includes(record?._id)}
                            dataPickupsPreferences={dataPickupsPreferences}
                            dataPreparations={dataPreparations}
                            isScan={scanMode}
                            updateList={updateList}
                            handleSelectRows={handleSelectRows}
                            selectedRows={selectedRows}
                            dataList={dataList}
                            handleInsertFulfillments={handleInsertFulfillments}
                        />
                    ),
                    expandIcon: () => null,
                    expandIconColumnIndex: -1,
                }}
            />

            <br />
            {useTokenBasedPagination && (
                <TokenBasedPagination
                    loading={loading}
                    hasPrevPage={data?.hasPrevPage}
                    hasNextPage={data?.hasNextPage}
                    page={page ?? data?.page}
                    totalPages={data?.totalPages}
                    totalDocs={data?.totalDocs}
                    handleResetPage={handleResetPage}
                    handlePreviousPage={handlePreviousPage}
                    handleNextPage={handleNextPage}
                />
            )}
        </ConfigProvider>
    );

    return (
        <>
            <ReactDragListView.DragColumn
                onDragEnd={onTableHeaderDragEnd}
                nodeSelector="th"
                lineClassName={'global-drag-line'}
            >
                {TableView}
            </ReactDragListView.DragColumn>

            <DialogCourierDiff
                selectedRows={selectedRows}
                dataList={dataList}
                handleSelectRows={handleSelectRows}
            />
        </>
    );
}

export default DraggableTable;
