import history from 'helpers/history';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Router, Switch } from 'react-router-dom';

// Theme
import { ThemeProvider } from 'styled-components';
import { themes } from './theme';
import GlobalStyles from './theme/globalStyles';
// Route
import PrivateRoute from 'navigation/PrivateRoute';

// Pages
import { FeatureFlagProvider } from 'app-config/FeatureFlagContext';
import { Auth, I18n } from 'aws-amplify';
import { CountryDirectionProvider } from 'context/CountryDirectionContext';
import useAppSyncListener from 'hooks/useAppSyncListener';
import useGetStartOrdersByPermissions from 'hooks/useGetStartOrdersByPermissions';
import ArrivalPendingPage from 'pages/ArrivalPending';
import BatchDelivers from 'pages/BatchDelivers';
import Crossdocking from 'pages/Crossdocking';
import DeliveriesPage from 'pages/DeliveriesV2';
import DeliveryPage from 'pages/Delivery';
import ErrorsPage from 'pages/Errors';
import IndexPage from 'pages/Home/index';
import { LoginPage } from 'pages/Login';
import ManifestsPage from 'pages/Manifests';
import MetafieldsPage from 'pages/Metafields';
import NotificationsPage from 'pages/Notifications';
import OrderPageV2 from 'pages/OrderV2';
import OrdersPage from 'pages/OrdersProxy';
import PaymentsPage from 'pages/Payments';
import ArrivalsPendingPage from 'pages/PendingArrivalsV2';
import PreScan from 'pages/PreScan';
import PreparationPage from 'pages/Preparation';
import PreparationPageV2 from 'pages/PreparationV2';
import PreparationsPage from 'pages/PreparationsV2';
import ReValidateDeliveryPage from 'pages/ReValidateDelivery';
import SettingsPage from 'pages/Settings';
import SettingsAndPreferences from 'pages/SettingsAndPreferences';
import StockSettings from 'pages/Stock';
import { useEffect, useMemo, useState } from 'react';
import { useGetPackageTypesQuery } from 'redux/features/PackageTypes';
import { useLazyGetUserLoggedQuery } from 'redux/features/Users';
import initAmplifyTranslation from 'translate/amplifyTranslations';
import OrderChangeStorePage from './pages/OrderChangeStore';
import OrdersProxy from 'pages/OrdersProxy';

initAmplifyTranslation();

const AMPLIFY_CONSTANTS = {
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
    SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
};

function getUrlParameter(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

function App() {
    const { themeName } = useSelector((state) => state.utils);

    const loginType = useMemo(() => {
        return getUrlParameter('type') ?? 'normal';
    }, []);

    useGetStartOrdersByPermissions();
    useAppSyncListener();

    useGetPackageTypesQuery(null, {
        skip: loginType === 'multi',
    });

    const [auth, setAuth] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [step, setStep] = useState('signIn');
    const [userLogged, setUserLogged] = useState(undefined);
    const [mount, setMount] = useState(false);
    const [getUserLogged] = useLazyGetUserLoggedQuery();

    useEffect(() => {
        handleCurrentSession().catch((error) => console.log('error', error));
    }, []);

    const handleCurrentSession = async () => {
        Auth.currentSession()
            .then((user) => {
                const idTokenPayload = user.getIdToken().decodePayload() ?? {};
                if (idTokenPayload && idTokenPayload.profile_admin === 'true') {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }
                setAuth(true);
                setMount(true);
            })
            .catch(() => {
                setAuth(false);
                setMount(true);
            });
    };

    const handleLogin = async (email, password) => {
        try {
            setLoading(true);
            const user = await Auth.signIn(email, password);
            setUserLogged(user);

            const response = await getUserLogged(user.username);
            localStorage.setItem('userLogged', JSON.stringify(response?.data ?? {}));

            if (user.challengeName === AMPLIFY_CONSTANTS.NEW_PASSWORD_REQUIRED) {
                setLoading(false);
                return setStep('completedNewPassword');
            }

            if (user.challengeName === AMPLIFY_CONSTANTS.SOFTWARE_TOKEN_MFA) {
                setLoading(false);
                return setStep('AUTH_MFA');
            }

            if (!user.attributes?.email_verified) {
                setLoading(false);
                Auth.verifyCurrentUserAttribute('email');
                return setStep('verifyEmail');
            }

            if (
                user.preferredMFA &&
                user.signInUserSession?.idToken?.payload?.mfa_enabled === 'true'
            ) {
                setLoading(false);
                return setStep('setupTOTP');
            }

            await handleCurrentSession().catch((error) => console.log('error', error));
            setLoading(false);

            window.location.href = '/';
        } catch (error) {
            console.log(error, 'error-message');
            toast.error(I18n.get(error.message));
            setLoading(false);
        }
    };

    if (!mount) return null;
    if (!auth || loginType === 'multi') {
        return (
            <LoginPage
                handleLogin={handleLogin}
                loading={loading}
                step={step}
                setStep={setStep}
                handleCurrentSession={handleCurrentSession}
                userLogged={userLogged}
            />
        );
    }

    return (
        <ThemeProvider theme={themes[themeName]}>
            <GlobalStyles />
            <FeatureFlagProvider>
                <CountryDirectionProvider>
                    <Router history={history}>
                        <Switch>
                            <PrivateRoute path="/errors" component={ErrorsPage} />
                            {/* Home */}
                            <PrivateRoute path="/" exact component={IndexPage} />
                            {/* Ordenes */}
                            <PrivateRoute path="/orders" exact component={OrdersProxy} />
                            {/* <PrivateRoute path="/orders_old/:order_id" exact component={OrderPage} /> */}
                            <PrivateRoute path="/orders/:order_id" exact component={OrderPageV2} />
                            <PrivateRoute
                                path="/orders/:order_id/change-store"
                                exact
                                component={OrderChangeStorePage}
                            />

                            <PrivateRoute path="/pre-scan" exact component={PreScan} />
                            {/* Preparacion */}
                            <PrivateRoute path="/fulfillments" exact component={PreparationsPage} />
                            <PrivateRoute
                                path="/fulfillments_old/:fulfillment_id"
                                exact
                                component={PreparationPage}
                            />
                            <PrivateRoute
                                path="/fulfillments/:fulfillment_id/:fulfillment_list_id?"
                                exact
                                component={PreparationPageV2}
                            />

                            {/* Delivery */}
                            <PrivateRoute path="/deliveries" exact component={DeliveriesPage} />
                            <PrivateRoute path="/manifests" exact component={ManifestsPage} />
                            <PrivateRoute
                                path="/manifests/:batch_delivery_id/:multi_batch_id?"
                                exact
                                component={ReValidateDeliveryPage}
                            />
                            <PrivateRoute
                                path="/deliveries/:fulfillment_id"
                                exact
                                component={DeliveryPage}
                            />
                            {/* PendingsArrivals */}
                            <PrivateRoute
                                path="/pendingArrival"
                                exact
                                component={ArrivalsPendingPage}
                            />
                            <PrivateRoute
                                path="/pendingArrival/:fulfillment_id"
                                exact
                                component={ArrivalPendingPage}
                            />
                            {/* Crossdocking */}
                            <PrivateRoute path="/crossdocking" exact component={Crossdocking} />
                            {/* BatchDelivers */}
                            <PrivateRoute path="/batch-delivers" exact component={BatchDelivers} />
                            {/* Notifications */}
                            <PrivateRoute
                                path="/notifications"
                                exact
                                component={NotificationsPage}
                            />
                            {/* Settings */}
                            <PrivateRoute
                                path="/settings/:section?/:view?"
                                exact
                                component={SettingsPage}
                            />
                            {/* Stock */}
                            <PrivateRoute path="/stocks" exact component={StockSettings} />

                            <PrivateRoute
                                path="/metafields/:category?/:target_id?"
                                exact
                                component={MetafieldsPage}
                            />
                            <PrivateRoute path="/plans" exact component={PaymentsPage} />

                            <PrivateRoute
                                path="/settings-and-preferences"
                                exact
                                component={SettingsAndPreferences}
                            />

                            <PrivateRoute
                                path="/settings-and-preferences"
                                exact
                                component={SettingsAndPreferences}
                            />

                            {/* Default page */}
                            <PrivateRoute component={isAdmin ? IndexPage : OrdersPage} />
                        </Switch>
                    </Router>
                </CountryDirectionProvider>
            </FeatureFlagProvider>
        </ThemeProvider>
    );
}
export default App;
