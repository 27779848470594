import { JsonImporterErrorTimeline } from '../JsonImporterSOLogs';

export const jsonImporterCloseSaleOrderLogs = ({ log, attempts, fulfillmentName }) => ({
    JSON_IMPORTER_CLOSE_SALE_ORDER_PENDING: `Se encuentra disponible un cierre de orden de venta para la integración a ${(
        log.other?.type_erp ?? ''
    ).toUpperCase()} para el ${fulfillmentName ? 'fulfillment' : 'pedido'} ${
        fulfillmentName ?? log.other?.request_data?.idexterno
    } en la sucursal ${log.other?.request_data?.storeno}`,
    JSON_IMPORTER_CLOSE_SALE_ORDER_READ: `Se ha leído el cierre de la orden de venta SID ${
        log.other?.sid
    } para integración a ${log.other?.type_erp ?? ''} para el  ${
        fulfillmentName ? 'fulfillment' : 'pedido'
    } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_CLOSE_SALE_ORDER_COMPLETED: `Se ha integrado el cierre de la orden de venta SID ${
        log.other?.sid
    } a ${(log.other?.type_erp ?? '').toUpperCase()} para el  ${
        fulfillmentName ? 'fulfillment' : 'pedido'
    } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.storeno
    }.`,
    JSON_IMPORTER_CLOSE_SALE_ORDER_ERROR: (
        <JsonImporterErrorTimeline
            log={log}
            entity="del cierre de la orden de venta"
            fulfillmentName={fulfillmentName}
        />
    ),
    JSON_IMPORTER_CLOSE_SALE_ORDER_PENDING_FAILED: log.other?.sid
        ? `Ha fallado el intento la creación del cierre de la orden de venta SID ${
              log.other?.sid
          } para la integración a ${(log.other?.type_erp ?? '').toUpperCase()} para el  ${
              fulfillmentName ? 'fulfillment' : 'pedido'
          } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal ${
              log.other?.request_data?.storeno
          }, el motivo ${log.other.reason}, ${attempts}`
        : `Ha fallado el intento de creación del cierre de la orden de venta para la integración ${(
              log.other.type_erp ?? ''
          ).toUpperCase()}, el motivo ${log.other.reason}, ${attempts}`,
});

export const jsonImporterStockTransferLogs = ({ log, attempts, fulfillmentName }) => ({
    JSON_IMPORTER_STOCK_TRANSFER_PENDING: `Se encuentra disponible una transferencia de stock para integración a Prism en la sucursal ${
        log.other?.request_data?.origstoreno
    } para el ${fulfillmentName ? 'fulfillment' : 'pedido'} ${
        fulfillmentName ?? log.other?.request_data?.idexterno
    } `,
    JSON_IMPORTER_STOCK_TRANSFER_PENDING_FAILED: log.other?.sid
        ? `Ha fallado el intento de creación de la transferencia de stock SID ${
              log.other?.sid
          } para la integración a ${(log.other?.type_erp ?? '').toUpperCase()} para el ${
              fulfillmentName ? 'fulfillment' : 'pedido'
          } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal ${
              log.other?.request_data?.origstoreno
          }, el motivo ${log.other.reason}, ${attempts}`
        : `Ha fallado el intento de creación de una transferencia de stock para la integración ${(
              log.other.type_erp ?? ''
          ).toUpperCase()}, el motivo ${log.other.reason}, ${attempts}`,
    JSON_IMPORTER_STOCK_TRANSFER_READ: `Se ha leído la transferencia de stock SID ${
        log.other?.sid ?? ''
    } para integración a ${log.other?.type_erp ?? ''} para el ${
        fulfillmentName ? 'fulfillment' : 'pedido'
    } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.origstoreno
    }`,
    JSON_IMPORTER_STOCK_TRANSFER_COMPLETED: `Se ha integrado la transferencia de stock SID ${
        log.other?.sid ?? ''
    } a ${(log.other?.type_erp ?? '').toUpperCase()} para el ${
        fulfillmentName ? 'fulfillment' : 'pedido'
    } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.origstoreno
    }.`,
    JSON_IMPORTER_STOCK_TRANSFER_ERROR: (
        <JsonImporterErrorTimeline
            log={log}
            fulfillmentName={fulfillmentName}
            entity="de la transferencia de stock"
        />
    ),
});

export const jsonImporterInvoiceLogs = ({ log, attempts }) => ({
    JSON_IMPORTER_INVOICE_PENDING: `Se encuentra disponible una factura para la integración a ${(
        log.other?.type_erp ?? ''
    ).toUpperCase()} para el pedido ${log.other?.request_data?.idexterno} en la sucursal ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_INVOICE_READ: `Se ha leído la factura SID ${log.other?.sid} para integración a ${
        log.other?.type_erp ?? ''
    } para el pedido ${log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_INVOICE_COMPLETED: `Se ha integrado la factura SID ${log.other?.sid} a ${(
        log.other?.type_erp ?? ''
    ).toUpperCase()} para el pedido ${log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_INVOICE_ERROR: <JsonImporterErrorTimeline log={log} entity="de la factura" />,
    JSON_IMPORTER_INVOICE_PENDING_FAILED: log.other?.sid
        ? `Ha fallado el intento la creación de la factura ${
              log.other?.request_data?.omsno
          } para la integración a ${(log.other?.type_erp ?? '').toUpperCase()} para el pedido ${
              log.other?.request_data?.idexterno
          } en la sucursal ${log.other?.request_data?.storeno}, el motivo ${
              log.other.reason
          }, ${attempts}`
        : `Ha fallado el intento de creación de la factura para la integración ${(
              log.other.type_erp ?? ''
          ).toUpperCase()}, el motivo ${log.other.reason}, ${attempts}`,
});

export const jsonImporterTicketLogs = ({ log, attempts }) => ({
    JSON_IMPORTER_TICKET_PENDING: `Se encuentra disponible una boleta para la integración a ${(
        log.other?.type_erp ?? ''
    ).toUpperCase()} para el pedido ${log.other?.request_data?.idexterno} en la sucursal ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_TICKET_READ: `Se ha leído la boleta SID ${log.other?.sid} para integración a ${
        log.other?.type_erp ?? ''
    } para el pedido ${log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_TICKET_COMPLETED: `Se ha integrado la boleta SID ${log.other?.sid} a ${(
        log.other?.type_erp ?? ''
    ).toUpperCase()} para el pedido ${log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.storeno
    }.`,
    JSON_IMPORTER_TICKET_ERROR: <JsonImporterErrorTimeline log={log} entity="de la boleta" />,
    JSON_IMPORTER_TICKET_PENDING_FAILED: log.other?.sid
        ? `Ha fallado el intento la creación de la boleta SID ${
              log.other?.sid
          } para la integración a ${(log.other?.type_erp ?? '').toUpperCase()} para el pedido ${
              log.other?.request_data?.idexterno
          } en la sucursal ${log.other?.request_data?.storeno}, el motivo ${
              log.other.reason
          }, ${attempts}`
        : `Ha fallado el intento de creación de la boleta para la integración ${(
              log.other.type_erp ?? ''
          ).toUpperCase()}, el motivo ${log.other.reason}, ${attempts}`,
});

export const jsonImporterCreditNoteLogs = ({ log, attempts, fulfillmentName }) => ({
    JSON_IMPORTER_CREDIT_NOTE_READ: `Se ha leído la nota de crédito SID ${
        log.other?.sid
    } para integración a ${log.other?.type_erp ?? ''} para el ${
        fulfillmentName ? 'fulfillment' : 'pedido'
    } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_CREDIT_NOTE_COMPLETED: `Se ha integrado la nota de crédito SID ${
        log.other?.sid
    } a ${(log.other?.type_erp ?? '').toUpperCase()} para el ${
        fulfillmentName ? 'fulfillment' : 'pedido'
    } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal  ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_CREDIT_NOTE_ERROR: (
        <JsonImporterErrorTimeline
            log={log}
            entity="de la nota de crédito"
            fulfillmentName={fulfillmentName}
        />
    ),
    JSON_IMPORTER_CREDIT_NOTE_PENDING: `Se encuentra disponible una nota de crédito para integración a Prism, para el ${
        fulfillmentName ? 'fulfillment' : 'pedido'
    } ${fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal ${
        log.other?.request_data?.storeno
    }`,
    JSON_IMPORTER_CREDIT_NOTE_PENDING_FAILED: log.other?.sid
        ? `Ha fallado el intento de creación de una nota de crédito para la integración a ${(
              log.other?.type_erp ?? ''
          ).toUpperCase()} para el ${fulfillmentName ? 'fulfillment' : 'pedido'} ${
              fulfillmentName ?? log.other?.request_data?.idexterno
          } en la sucursal ${log.other?.request_data?.storeno}, el motivo ${
              log.other.reason
          }, ${attempts}`
        : `Ha fallado el intento de la creación de la nota de crédito para la integración ${(
              log.other.type_erp ?? ''
          ).toUpperCase()}, el motivo ${log.other.reason}, ${attempts}`,
});
