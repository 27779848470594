import { Tooltip } from 'antd';
import styled from 'styled-components';

const ActiveUsersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
`;

const ActiveUsersList = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    padding: 4px;
`;

const AvataStyled = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    flex-shrink: 0;
    background-color: #fff;
    // show title on hover
    &:hover {
        cursor: pointer;
    }
`;

const Avatar = ({ user }) => {
    const initials = String(user ?? '')
        .toUpperCase()
        .split(' ')
        .map((name) => name?.[0] ?? '')
        .join('');

    return (
        <Tooltip title={user}>
            <AvataStyled>{initials}</AvataStyled>
        </Tooltip>
    );
};

const ActiveUsers = ({ active_users = [] }) => {
    if (!active_users || active_users.length === 0) return null;

    return (
        <ActiveUsersWrapper>
            <ActiveUsersList>
                {active_users.map((user) => (
                    <Avatar key={user} user={user} />
                ))}
            </ActiveUsersList>
            <small style={{ fontSize: '13px', fontWeight: '500' }}>
                {active_users.length > 1
                    ? `${active_users.length} usuarios estan viendo este pedido`
                    : '1 usuario esta viendo este pedido'}
            </small>
        </ActiveUsersWrapper>
    );
};

export default ActiveUsers;
