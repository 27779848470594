import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import CreateManualIncidenceOrderDrawer from 'components/ManualIncidences/Order/CreateIncidenceDrawer';
import StatesValidationModal from 'components/StatesValidationModal';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import MenuItem from 'components/V2/Menu/MenuItem';
import history from 'helpers/history';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setFulfillmentForDelivery } from 'redux/features/Utils';

const PendingArrivalsActions = ({
    selected = [],
    disabled = false,
    data: dataParaPDF,
    hasStatusNotAllowedRow,
    handleRefetch,
}) => {
    const dispatch = useDispatch();
    const [showStatesValidationModal, setShowStatesValidationModal] = useState(false);
    const [statesValidationSuccess, setStatesValidationSuccess] = useState(false);
    const [fulfillmentIdsValidated, setFulfillmentIdsValidated] = useState([]);

    const handleMultipleDeliveries = () => {
        const arraySeleccionados = dataParaPDF?.docs.filter((d) => selected.includes(d?._id));

        const noPermitidos = arraySeleccionados?.filter((a) =>
            ['created', 'ready_for_deliver_to_pickup_location'].includes(
                a?.preparationcd_transfer_pickupstore?.status
            )
        );

        if (noPermitidos?.length > 0) {
            toast.error(
                'Ha seleccionado fulfillments donde al menos uno de ellos no puede ser recepcionado.'
            );
        } else {
            if (selected?.length > 0) {
                const fulfillmentsClean = selected.filter((f) =>
                    fulfillmentIdsValidated.includes(f)
                );

                dispatch(setFulfillmentForDelivery(fulfillmentsClean));
                history.push(`/pendingArrival/multiple`);
            }
        }
    };

    useEffect(() => {
        if (statesValidationSuccess && fulfillmentIdsValidated.length) {
            setStatesValidationSuccess(false);
            handleMultipleDeliveries();
        }
    }, [statesValidationSuccess, fulfillmentIdsValidated]);

    const menu = (
        <Menu>
            <MenuItem
                disabled={hasStatusNotAllowedRow}
                onClick={() => {
                    //handleMultipleDeliveries
                    setShowStatesValidationModal(true);
                }}
            >
                Recepcionar entrega ({selected.length})
            </MenuItem>
            <CreateManualIncidenceOrderDrawer
                isMenuItem
                ids={selected}
                fulfillments={dataParaPDF?.docs}
                viewPage="pending_arrival"
            />
        </Menu>
    );

    return (
        <>
            <StatesValidationModal
                fulfillmentIds={selected}
                action="arrival"
                showStatesValidationModal={showStatesValidationModal}
                setShowStatesValidationModal={setShowStatesValidationModal}
                setStatesValidationSuccess={setStatesValidationSuccess}
                setFulfillmentIdsValidated={setFulfillmentIdsValidated}
                handleRefetch={handleRefetch}
                justRefetchAfterClose={true}
            />

            <Dropdown disabled={disabled} overlay={menu}>
                <PrimaryButton
                    icon={<ChevronDownIcon width={24} height={24} />}
                    iconPosition="right"
                >
                    Acciones ({selected.length})
                </PrimaryButton>
            </Dropdown>
        </>
    );
};

export default PendingArrivalsActions;
