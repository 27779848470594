import { useState } from 'react';
import { TextUnderline } from '../styles';
import { ModalJsonImporterSOErrorDetail } from './ModalJsonImporterSOErrorDetail';

export const JsonImporterErrorTimeline = ({ log, entity, fulfillmentName }) => {
    const [isOpenModalErrorDetail, setIsOpenModalErrorDetail] = useState(false);

    return (
        <>
            <div>
                Se han encontrado{' '}
                <TextUnderline onClick={() => setIsOpenModalErrorDetail(true)}>
                    errores
                </TextUnderline>{' '}
                en el intento de integración a {(log.other?.type_erp ?? '').toUpperCase()} {entity}{' '}
                SID {log.other?.sid} para el
                {fulfillmentName ? ' fulfillment ' : ' pedido '}
                {fulfillmentName ?? log.other?.request_data?.idexterno} en la sucursal{' '}
                {log.other?.request_data?.storeno}
            </div>
            <ModalJsonImporterSOErrorDetail
                isOpen={isOpenModalErrorDetail}
                onClose={() => setIsOpenModalErrorDetail(false)}
                data={log?.other?.errors ?? {}}
            />
        </>
    );
};
