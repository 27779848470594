import { useEffect, useState } from 'react';
import useCurrentUser from './useCurrentUser';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateRoom, onUpdateRoom, onDeleteRoom } from 'graphql/subscriptions';
import { createRoom, updateRoom, deleteRoom } from 'graphql/mutations';
import { listRooms } from 'graphql/queries';

const useRoomListener = (room_id = null, callback = () => {}) => {
    const user = useCurrentUser();
    const [lastRoomState, setLastRoomState] = useState(null);
    const [isSameUser, setIsSameUser] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState({
        create: null,
        update: null,
        delete: null,
    });

    const checkSameUser = (roomData) => {
        const isCurrentUser = String(roomData?.user_id) === String(user?.userData?._id);
        setIsSameUser(isCurrentUser);
        return isCurrentUser;
    };

    const getLastRoomState = async () => {
        try {
            const response = await API.graphql(
                graphqlOperation(listRooms, {
                    filter: {
                        target_id: { eq: room_id },
                        merchant_id: { eq: user?.userData?.merchant_id?._id },
                    },
                    limit: 1,
                })
            );

            const rooms = response.data.listRooms.items;
            if (rooms && rooms.length > 0) {
                const lastRoom = rooms[0];
                setLastRoomState(lastRoom);
                checkSameUser(lastRoom);
                return lastRoom;
            }
            return null;
        } catch (error) {
            console.error('Error getting last room state:', error);
            return null;
        }
    };

    useEffect(() => {
        let subscriptionsObj = {};
        let timeoutId;
        let isSubscribed = true;

        const setupSubscriptions = async () => {
            try {
                if (!user?.userData?._id || !user?.userData?.merchant_id?._id || !room_id) {
                    setIsLoading(false);
                    return;
                }

                timeoutId = setTimeout(() => {
                    setIsLoading(false);
                }, 6000);

                await getLastRoomState();
                setIsInitialized(true);

                const subscriptionParams = {
                    merchant_id: String(user?.userData?.merchant_id?._id),
                    target_id: String(room_id),
                };

                if (subscriptions.create) subscriptions.create.unsubscribe();
                if (subscriptions.update) subscriptions.update.unsubscribe();
                if (subscriptions.delete) subscriptions.delete.unsubscribe();

                const handleRoomEvent = async (eventData = {}, type) => {
                    const roomData =
                        eventData?.value?.data?.onCreateRoom ||
                        eventData?.value?.data?.onUpdateRoom ||
                        eventData?.value?.data?.onDeleteRoom;

                    if (type === 'delete') {
                        setLastRoomState(null);
                        setIsSameUser(false);
                        callback({ type, ...{}, sameUser: false }, user);
                        await roomActions.createRoom();
                        return;
                    }

                    if (roomData && isSubscribed) {
                        const sameUser = checkSameUser(roomData);
                        setLastRoomState(roomData);
                        callback({ type, ...roomData, sameUser }, user);
                    }
                };

                subscriptionsObj.create = API.graphql(
                    graphqlOperation(onCreateRoom, subscriptionParams)
                ).subscribe({
                    next: (eventData) => handleRoomEvent(eventData, 'create'),
                    error: (error) => console.error('Create subscription error:', error),
                });

                subscriptionsObj.update = API.graphql(
                    graphqlOperation(onUpdateRoom, subscriptionParams)
                ).subscribe({
                    next: (eventData) => handleRoomEvent(eventData, 'update'),
                    error: (error) => console.error('Update subscription error:', error),
                });

                subscriptionsObj.delete = API.graphql(
                    graphqlOperation(onDeleteRoom, subscriptionParams)
                ).subscribe({
                    next: (eventData) => handleRoomEvent(eventData, 'delete'),
                    error: (error) => console.error('Delete subscription error:', error),
                });

                if (isSubscribed) {
                    setSubscriptions(subscriptionsObj);
                    setIsLoading(false);
                    clearTimeout(timeoutId);
                }
            } catch (error) {
                console.error('Setup subscription error:', error);
                if (isSubscribed) {
                    setIsLoading(false);
                    clearTimeout(timeoutId);
                }
            }
        };

        setupSubscriptions();

        const cleanup = () => {
            isSubscribed = false;
            if (user?.userData) {
                console.log('DELETE ROOM');
                roomActions.deleteRoom().catch((error) => {
                    console.error('Error in cleanup:', error);
                });
            }

            if (timeoutId) clearTimeout(timeoutId);
            if (subscriptionsObj.create) subscriptionsObj.create.unsubscribe();
            if (subscriptionsObj.update) subscriptionsObj.update.unsubscribe();
            if (subscriptionsObj.delete) subscriptionsObj.delete.unsubscribe();
        };

        const handleBeforeUnload = () => {
            cleanup();
        };

        // const handleVisibilityChange = () => {
        //     if (document.visibilityState === 'hidden') {
        //         cleanup();
        //     }
        // };

        // const handleLocationChange = () => {
        //     cleanup();
        // };

        window.addEventListener('beforeunload', handleBeforeUnload);
        // window.addEventListener('visibilitychange', handleVisibilityChange);
        // window.addEventListener('popstate', handleLocationChange);

        return () => {
            cleanup();
            window.removeEventListener('beforeunload', handleBeforeUnload);
            // window.removeEventListener('visibilitychange', handleVisibilityChange);
            // window.removeEventListener('popstate', handleLocationChange);
        };
    }, [user?.userData?._id, user?.userData?.merchant_id?._id, room_id]);

    const roomActions = {
        createRoom: async (roomData = {}) => {
            try {
                const existingRoom = await getLastRoomState();
                const currentUsername = `${user?.userData?.firstname} ${user?.userData?.lastname}`;

                if (existingRoom) {
                    const updatedActiveUsers = [
                        ...new Set([...(existingRoom.active_users || []), currentUsername]),
                    ];

                    console.log({
                        updatedActiveUsers,
                        existingRoom,
                        currentUsername,
                    });

                    return await roomActions.updateRoom({
                        active_users: updatedActiveUsers,
                        block_actions: false,
                        block_reason: `${updatedActiveUsers.length} usuarios están viendo este pedido`,
                    });
                }

                const response = await API.graphql(
                    graphqlOperation(createRoom, {
                        input: {
                            target_id: String(room_id),
                            merchant_id: String(user?.userData?.merchant_id?._id),
                            user_id: String(user?.userData?._id),
                            active_users: [currentUsername],
                            block_actions: false,
                            block_reason: '1 usuario está viendo este pedido',
                            ...roomData,
                        },
                    })
                );

                const newRoom = response.data.createRoom;
                checkSameUser(newRoom);
                setLastRoomState(newRoom);
                return newRoom;
            } catch (error) {
                console.error('Error creating room:', error);
                if (
                    error.message?.includes('already exists') ||
                    error.message?.includes('ConflictException')
                ) {
                    await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000));
                    const existingRoom = await getLastRoomState();
                    if (existingRoom) {
                        return existingRoom;
                    }
                }
                return null;
            }
        },

        updateRoom: async (roomData) => {
            try {
                const currentRoom = await getLastRoomState();
                if (!currentRoom) return null;

                const response = await API.graphql(
                    graphqlOperation(updateRoom, {
                        input: {
                            id: currentRoom.id,
                            target_id: String(room_id),
                            merchant_id: String(user?.userData?.merchant_id?._id),
                            ...roomData,
                        },
                    })
                );

                const updatedRoom = response.data.updateRoom;
                checkSameUser(updatedRoom);
                setLastRoomState(updatedRoom);
                return updatedRoom;
            } catch (error) {
                console.error('Error updating room:', error);
                return null;
            }
        },

        deleteRoom: async () => {
            try {
                console.log('DELETE ROOM');
                const currentRoom = await getLastRoomState();
                if (!currentRoom) return null;

                const currentUsername = `${user?.userData?.firstname} ${user?.userData?.lastname}`;
                const updatedActiveUsers = (currentRoom.active_users || []).filter(
                    (username) => username !== currentUsername
                );

                console.log({
                    currentRoom,
                    currentUsername,
                    updatedActiveUsers,
                });

                if (updatedActiveUsers.length > 0) {
                    return await roomActions.updateRoom({
                        active_users: updatedActiveUsers,
                        block_actions: false,
                        block_reason: `${updatedActiveUsers.length} usuarios están viendo este pedido`,
                    });
                }

                const response = await API.graphql(
                    graphqlOperation(deleteRoom, {
                        input: {
                            id: currentRoom.id,
                        },
                    })
                );

                setLastRoomState(null);
                return response.data.deleteRoom;
            } catch (error) {
                console.error('Error deleting room:', error);
                return null;
            }
        },
    };

    return {
        subscriptions,
        lastRoomState,
        isSameUser,
        isInitialized,
        isLoading,
        ...roomActions,
    };
};

export default useRoomListener;
