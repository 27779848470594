import { Button } from '@andescommercecloud/beflow-design-system';
import styled from 'styled-components';
import LogoSVG from 'assets/LogoSimple.png';
import { useEffect, useState } from 'react';
const LockScreenContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000000;
`;

const LockScreenContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

const LockScreenTitle = styled.h1`
    font-size: 1.5rem;
    color: white;
`;

const LockScreenDescription = styled.p`
    font-size: 1rem;
    color: white;
    text-align: center;
`;

const LockScreenLogo = styled.img`
    width: 100px;
    height: 100px;

    @keyframes blink {
        0% {
            opacity: 0.5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.5;
        }
    }

    animation: blink 0.6s linear infinite;
`;

const LockScreenFooter = styled.p`
    font-size: 0.8rem;
    color: white;
`;

const LockScreen = ({ returnUrl }) => {
    const reloadPage = () => {
        if (returnUrl) {
            window.location.href = returnUrl;
        } else {
            const url = new URL(window.location.href);
            url.searchParams.delete('lock');
            window.location.href = url.toString();
        }
    };

    const [countdown, setCountdown] = useState(30);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (countdown === 0) reloadPage();
    }, [countdown]);

    return (
        <LockScreenContainer>
            <LockScreenContent>
                <LockScreenLogo src={LogoSVG} alt="Logo" />

                <LockScreenTitle>Por favor, refresque la página.</LockScreenTitle>

                <LockScreenDescription>
                    Se han detectado cambios en el pedido actual.
                    <br /> Es necesario refrescar para visualizar los últimos cambios realizados.
                </LockScreenDescription>

                <Button variation="secondary" onClick={() => reloadPage()} style={{}}>
                    <b>Refrescar</b>
                </Button>

                <LockScreenFooter>Refrescando en {countdown} segundos</LockScreenFooter>
            </LockScreenContent>
        </LockScreenContainer>
    );
};

export default LockScreen;
