import { Col, Form, Input, Select } from 'antd';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';
import { CountryDirectionContext } from 'context/CountryDirectionContext';
import useCountryData from 'hooks/useCountryData';
import { useContext, useMemo } from 'react';

export const LocationByCountryForm = ({
    form,
    valueCountry,
    nameCountry,
    nameProvince,
    nameCity,
    nameNeighborhood,
    nameZipCode,
}) => {
    const { getCountryInfo, getMexicoZips, getMexicoAddressByZip, countries } =
        useContext(CountryDirectionContext);
    const country = valueCountry ?? Form.useWatch(nameCountry || 'chile', form);
    const province = Form.useWatch(nameProvince, form);
    const municipality = Form.useWatch('municipality', form);
    const city = Form.useWatch(nameCity, form);
    const neighborhood = Form.useWatch(nameNeighborhood, form);
    const countryData = useCountryData(country);

    const activeDirectionMexico = useGetValueFeatureFlag('enable_mexico');

    const { provinces, municipalities, districts, settlements, mxZips } = useMemo(
        () => ({
            provinces: getCountryInfo(country),
            municipalities: getCountryInfo(country, province || 'NA'),
            districts: getCountryInfo(country, province || 'NA', city),
            settlements: getCountryInfo(country, province || 'NA', municipality, city),
            mxZips: getMexicoZips(),
        }),
        [countries, country, province, city]
    );

    return (
        <>
            {activeDirectionMexico && countryData.country === 'mexico' && (
                <Col span={12}>
                    <Form.Item name="zip" initialValue="" label="Código Postal">
                        <Select
                            showSearch={true}
                            placeholder="Selecciona un código postal"
                            optionFilterProp="label"
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '')
                                    .toLowerCase()
                                    .localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            onChange={(value) => {
                                const mexicoAddressByZip = getMexicoAddressByZip(value);

                                form.setFieldValue(
                                    nameProvince,
                                    mexicoAddressByZip?.state ?? undefined
                                );

                                form.setFieldValue(
                                    'municipality',
                                    mexicoAddressByZip?.municipality ?? undefined
                                );

                                form.setFieldValue('city', mexicoAddressByZip?.city ?? undefined);

                                !!nameNeighborhood?.length &&
                                    form.setFieldValue(
                                        nameNeighborhood,
                                        mexicoAddressByZip?.settlement ?? undefined
                                    );
                            }}
                            options={
                                mxZips.map((r) => ({
                                    label: r,
                                    value: r,
                                })) ?? []
                            }
                        />
                    </Form.Item>
                </Col>
            )}
            <Col span={12}>
                <Form.Item
                    name={nameProvince}
                    initialValue=""
                    label={countryData.provinceLabel}
                    rules={[
                        {
                            required: true,
                            message: `Se necesita una ${countryData.provinceLabel?.toLowerCase()}`,
                        },
                    ]}
                >
                    <Select
                        placeholder={`Selecciona una ${countryData.provinceLabel.toLowerCase()}`}
                        onChange={() => {
                            if (country === 'mexico') {
                                form.setFieldValue('municipality', undefined);
                            }

                            form.setFieldValue(nameCity, undefined);
                            !!nameNeighborhood?.length &&
                                form.setFieldValue(nameNeighborhood, undefined);
                        }}
                    >
                        {provinces.map((r, i) => (
                            <Select.Option key={i} value={r.id}>
                                {r.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>

            {countryData.country !== 'mexico' && (
                <Col span={12}>
                    <Form.Item
                        name={nameCity}
                        label={countryData.cityLabel}
                        rules={[
                            {
                                required: true,
                                message: `Se necesita un ${countryData.cityLabel?.toLowerCase()}`,
                            },
                        ]}
                    >
                        <Select
                            placeholder={`Selecciona un ${countryData.cityLabel?.toLowerCase()}`}
                            disabled={!province}
                            onChange={() =>
                                !!nameNeighborhood?.length &&
                                form.setFieldValue(nameNeighborhood, undefined)
                            }
                        >
                            {municipalities?.map((r, i) => (
                                <Select.Option key={i} value={r.id}>
                                    {r.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            )}

            {activeDirectionMexico && country === 'mexico' && (
                <>
                    <Col span={12}>
                        <Form.Item
                            name="municipality"
                            label="Municipio"
                            rules={[
                                {
                                    required: true,
                                    message: `Se necesita un municipio`,
                                },
                            ]}
                        >
                            <Select
                                placeholder={`Selecciona un municipio`}
                                disabled={!province}
                                onChange={() => {
                                    form.setFieldValue(nameCity, undefined);
                                    !!nameNeighborhood?.length &&
                                        form.setFieldValue(nameNeighborhood, undefined);
                                }}
                            >
                                {municipalities?.map((r, i) => (
                                    <Select.Option key={i} value={r.id}>
                                        {r.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="city" initialValue="" label="Ciudad">
                            <Select
                                disabled={!province || !municipality}
                                onChange={() => {
                                    form.setFieldValue(nameCity, undefined);
                                    !!nameNeighborhood?.length &&
                                        form.setFieldValue(nameNeighborhood, undefined);
                                }}
                                placeholder="Selecciona una ciudad"
                            >
                                {districts?.map((r, i) => (
                                    <Select.Option key={i} value={r.id}>
                                        {r.name}
                                    </Select.Option>
                                )) ?? []}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={nameNeighborhood}
                            initialValue=""
                            label={countryData.neighborhoodLabel}
                        >
                            <Select
                                placeholder={`Selecciona un ${countryData.neighborhoodLabel?.toLowerCase()}`}
                                disabled={
                                    !province || !form.getFieldValue('municipality')
                                    // !form.getFieldValue('city')
                                }
                            >
                                {settlements.map((district) => (
                                    <Select.Option key={district.id} value={district.id}>
                                        {district.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </>
            )}

            {country === 'peru' && !!nameNeighborhood?.length && (
                <Col span={12}>
                    <Form.Item
                        name={nameNeighborhood}
                        initialValue=""
                        label={countryData.neighborhoodLabel}
                        rules={[
                            {
                                required: true,
                                message: `Se necesita una ${countryData.neighborhoodLabel?.toLowerCase()}`,
                            },
                        ]}
                    >
                        <Select
                            placeholder={`Selecciona un ${countryData.neighborhoodLabel?.toLowerCase()}`}
                            disabled={!province || !city}
                        >
                            {districts.map((district) => (
                                <Select.Option key={district.id} value={district.id}>
                                    {district.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            )}
            {!!nameZipCode?.length && (
                <Col span={12}>
                    <Form.Item name={nameZipCode} initialValue="" label={countryData.zipLabel}>
                        <Input
                            disabled={!province || !city || (!neighborhood && country === 'peru')}
                            placeholder={`Selecciona un ${countryData.zipLabel?.toLowerCase()}`}
                        />
                    </Form.Item>
                </Col>
            )}
        </>
    );
};
