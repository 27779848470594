import { useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, Col, DatePicker, Form, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { useUpdateUserPreferencesMutation } from 'redux/features/Users';
import { toggleModal } from 'redux/features/Utils';

import { andOrOptions, conditions, fieldsValues } from 'helpers/constants/filters';
import { defaultColumnsView } from 'helpers/constants/defaultColumnsView';
import { getLastNewViewIndex } from 'helpers/getLastViewIndex';
import { removeBasicTabs } from 'helpers/removeBasicTabs';

import Select from 'components/V2/Select';
import SelectOption from 'components/V2/Select/SelectOption';
import ClosableTag from 'components/Tag/ClosableTag';
import Input from 'components/V2/Input';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import SecondaryButton from 'components/V2/Buttons/SecondaryButton';
import { dispatchers } from 'components/V2/Filters/config';
import Flex from 'components/Utils/Flex';
import { ReactComponent as RemoveIcon } from 'assets/remove-minus-icon.svg';
import { RowFilterStyled } from './styles';
import moment from 'moment';
import useCountryData from 'hooks/useCountryData';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';

function FilterForm({
    userPreferences,
    selectedTabIndex,
    modalName,
    refetch,
    memoizedFiltersBeforeModal,
    filters = [],
    valueOptionByField = {},
    dispatchersType,
}) {
    const [form] = Form.useForm();
    const { addFilter, overrideFilters, removeFilter, updateFilter, setFilters } =
        dispatchers[dispatchersType];
    const dispatch = useDispatch();
    const [updateUserPreferences] = useUpdateUserPreferencesMutation();
    const isApplyFiltersLoading = useRef(false);
    const isSaveNewViewLoading = useRef(false);
    const [fieldsValuesCopy, setFieldsValuesCopy] = useState([]);
    const countryData = useCountryData();

    const activeDirectionMexico = useGetValueFeatureFlag('enable_mexico');

    useEffect(() => {
        setFieldsValuesCopy(
            structuredClone(fieldsValues[dispatchersType]).reduce((acc, el) => {
                // En caso que el país sea mexico y el campo sea destination.province (region), entonces reemplazamos region por asentamiento y código postal (requerimiento de historia #27849)

                if (
                    activeDirectionMexico &&
                    countryData?.country === 'mexico' &&
                    el.value === 'destination.province'
                ) {
                    acc = [
                        ...acc,
                        {
                            label: 'Asentamiento',
                            value: 'destination.neighborhood',
                        },
                        {
                            label: 'Código postal',
                            value: 'destination.zip_code',
                        },
                    ];

                    return acc;
                }

                acc.push(el);

                return acc;
            }, [])
        );
    }, [fieldsValues, countryData]);

    const createTab = () => {
        const value = `Nueva vista ${getLastNewViewIndex(
            userPreferences?.preferences[dispatchersType]?.tabs
        )}`;

        const newTab = {
            name: value,
            value: value?.toLowerCase().replace(/ /g, '_') + Date.now(),
            typeOfTab: 'custom',
            filters: [...filters],
            columns: [
                ...(userPreferences?.preferences[dispatchersType].tabs[selectedTabIndex]?.columns ??
                    defaultColumnsView[dispatchersType]),
            ],
        };

        return newTab;
    };

    const handleUpateFiltersQuery = (updatedFilters) => {
        // Despachamos los filtros para que se apliquen
        dispatch(
            setFilters({
                filters: [
                    ...(updatedFilters?.map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })) ?? []),
                ],
                page: 1,
            })
        );
        refetch(true);
    };

    const handleAddFilter = () => {
        const newFilter = {
            andOr: '',
            condition: '',
            field: '',
            value: '',
            isRemovable: true,
            id: nanoid(),
            compromiseValues: [],
        };
        dispatch(addFilter(newFilter));
    };

    const handleApplyFilters = async () => {
        const tab = userPreferences?.preferences[dispatchersType]?.tabs[selectedTabIndex];

        if (tab.typeOfTab === 'custom') {
            handleSaveFiltersCustomTab();
            return;
        }
        isApplyFiltersLoading.current = true;
        const filtersToApply = structuredClone(filters);
        dispatch(overrideFilters(filtersToApply));
        handleUpateFiltersQuery(filtersToApply);
        isApplyFiltersLoading.current = false;
        dispatch(toggleModal(modalName));
    };

    const handleSaveFiltersCustomTab = async () => {
        isApplyFiltersLoading.current = true;
        const filtersToSave = structuredClone(filters);
        const tabsCopy = structuredClone(userPreferences?.preferences[dispatchersType]?.tabs);

        tabsCopy[selectedTabIndex].filters = [...filtersToSave];

        const tabsCopyFiltered = tabsCopy.filter((tab) => tab.typeOfTab !== 'basic');

        const userPreferencesNoBasicTabs = removeBasicTabs(userPreferences);

        await updateUserPreferences({
            ...userPreferencesNoBasicTabs,
            preferences: {
                ...userPreferencesNoBasicTabs.preferences,
                [dispatchersType]: {
                    ...userPreferencesNoBasicTabs.preferences[dispatchersType],
                    tabs: [...tabsCopyFiltered],
                },
            },
        });

        handleUpateFiltersQuery(filtersToSave);
        isApplyFiltersLoading.current = false;
        dispatch(toggleModal(modalName));
    };

    const handleFormSubmit = async () => {
        isSaveNewViewLoading.current = true;
        const filtersToSave = structuredClone(filters);
        const newUserPreferences = structuredClone(userPreferences);
        const newCustomTab = createTab();

        // Debemos mapear todos los filtros para ser eliminables
        filtersToSave.forEach((filter) => {
            filter.isRemovable = true;
        });

        // Los filtros de newCustomTab deben ser filtersToSave
        newCustomTab.filters = [...filtersToSave];

        const userPreferencesNoBasicTabs = removeBasicTabs(newUserPreferences);

        await updateUserPreferences({
            ...userPreferencesNoBasicTabs,
            preferences: {
                ...userPreferencesNoBasicTabs.preferences,
                [dispatchersType]: {
                    ...userPreferencesNoBasicTabs.preferences[dispatchersType],
                    tabs: [
                        ...userPreferencesNoBasicTabs.preferences[dispatchersType].tabs,
                        newCustomTab,
                    ],
                },
            },
        });

        isSaveNewViewLoading.current = false;
        // Volvemos a nuestro filtros originales antes de abrir el modal
        dispatch(overrideFilters(memoizedFiltersBeforeModal));

        dispatch(toggleModal(modalName));
    };

    const handleSelectAndOr = (value, filterId) => {
        const filterToUpdate = structuredClone(filters.find((filter) => filter.id === filterId));
        filterToUpdate.andOr = value;
        dispatch(updateFilter(filterToUpdate));
    };

    const handleSelectField = (value, filterId) => {
        const filterToUpdate = structuredClone(filters.find((filter) => filter.id === filterId));
        filterToUpdate.field = value;
        // Limpiamos los campos condition y value
        filterToUpdate.condition = '';
        filterToUpdate.value = '';

        filterToUpdate.compromiseValues = valueOptionByField[value] ?? [];
        dispatch(updateFilter(filterToUpdate));
    };

    const handleSelectCondition = (value, filterId) => {
        const filterToUpdate = structuredClone(filters.find((filter) => filter.id === filterId));
        filterToUpdate.condition = value;

        // Limpiamos el campo value
        filterToUpdate.value = ['is', 'is_not'].includes(value) ? '' : [];

        dispatch(updateFilter(filterToUpdate));
    };

    const handleSelectValue = (value, filterId) => {
        const filterToUpdate = structuredClone(filters.find((filter) => filter.id === filterId));
        filterToUpdate.value = value;
        dispatch(updateFilter(filterToUpdate));
    };

    const handleSelectMultipleValues = (value, filterId) => {
        const filterToUpdate = structuredClone(filters.find((filter) => filter.id === filterId));
        filterToUpdate.value = [...filterToUpdate.value, value];
        dispatch(updateFilter(filterToUpdate));
    };

    const handleDeselectMultipleValues = (value, filterId) => {
        const filterToUpdate = structuredClone(filters.find((filter) => filter.id === filterId));
        filterToUpdate.value = filterToUpdate.value.filter((item) => item !== value);
        dispatch(updateFilter(filterToUpdate));
    };

    const getFilterFieldLabel = (field) => {
        return Array.from(fieldsValues[dispatchersType] ?? []).find((item) => item.value === field)
            ?.label;
    };

    const isCustomTab = userPreferences?.tabs[selectedTabIndex]?.typeOfTab === 'custom';

    const isButtonEnabled = useMemo(() => {
        return filters.every((filter, index) => {
            let isAndOrFilled = true;

            const hasDataFilterValue = Array.isArray(filter.value)
                ? filter.value.length > 0
                : filter.value;

            if (index > 0) {
                isAndOrFilled = filter.andOr;
            }
            return isAndOrFilled && filter.field && filter.condition && hasDataFilterValue;
        });
    }, [filters]);

    const fieldSelected = useMemo(
        () =>
            filters.reduce((acc, filter) => {
                return {
                    ...acc,
                    [filter.field]: true,
                };
            }, {}),
        [filters]
    );

    const isDateFilter = (field) => {
        return ['created_at', 'updated_at', 'custom_promised_date'].includes(field);
    };

    if (filters.length === 0) handleAddFilter();

    return (
        <>
            <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                {filters
                    ?.filter((filter) => filter?.hide !== true)
                    .map((filter, index) => {
                        return (
                            <RowFilterStyled key={filter.id} gutter={32}>
                                <Col span={4} key="and_or" className="and_or">
                                    {index > 0 && (
                                        <Form.Item label="Y/O">
                                            <Select
                                                options={[...andOrOptions]}
                                                onSelect={(value) =>
                                                    handleSelectAndOr(value, filter.id)
                                                }
                                                value={filter.andOr}
                                                disabled={index === 0}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col span={7} key="field" className="field">
                                    <Form.Item label="Campo:">
                                        <Select
                                            options={fieldsValuesCopy.map((field) => ({
                                                ...field,
                                                disabled: !!fieldSelected[field.value],
                                            }))}
                                            onSelect={(value) =>
                                                handleSelectField(value, filter.id)
                                            }
                                            value={getFilterFieldLabel(filter.field)}
                                            disabled={!filter.isRemovable}
                                            placeholder="-Seleccione-"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6} key="condition" className="condition">
                                    <Form.Item label={`Condición ${index + 1}:`}>
                                        <Select
                                            options={[...conditions]}
                                            onSelect={(value) =>
                                                handleSelectCondition(value, filter.id)
                                            }
                                            value={filter.condition || null}
                                            disabled={!filter.field || !filter.isRemovable}
                                            placeholder="-Seleccione-"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6} key="value" className="value">
                                    <Form.Item label="Valor:">
                                        {isDateFilter(filter.field) ? (
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                disabled={!filter.condition || !filter.isRemovable}
                                                value={filter.value ? moment(filter.value) : ''}
                                                onChange={(_, dateString) => {
                                                    handleSelectValue(dateString, filter.id);
                                                }}
                                            />
                                        ) : !filter?.compromiseValues ||
                                          filter?.compromiseValues?.length === 0 ? (
                                            <Input
                                                disabled={!filter.condition || !filter.isRemovable}
                                                value={filter.value}
                                                onChange={(e) =>
                                                    handleSelectValue(e.target.value, filter.id)
                                                }
                                                style={{ width: '100%', height: '30px' }}
                                            />
                                        ) : (
                                            <Select
                                                showArrow
                                                showSearch={
                                                    activeDirectionMexico &&
                                                    [
                                                        'destination.neighborhood',
                                                        'zip_code',
                                                    ].includes(filter.field) &&
                                                    countryData?.country === 'mexico'
                                                }
                                                optionLabelProp="title"
                                                maxTagCount="responsive"
                                                placeholder="-Seleccione-"
                                                value={filter.value || null}
                                                disabled={!filter.condition || !filter.isRemovable}
                                                onSelect={(value) => {
                                                    ['includes', 'not_include'].includes(
                                                        filter.condition
                                                    )
                                                        ? handleSelectMultipleValues(
                                                              value,
                                                              filter.id
                                                          )
                                                        : handleSelectValue(value, filter.id);
                                                }}
                                                onDeselect={(value) =>
                                                    handleDeselectMultipleValues(value, filter.id)
                                                }
                                                mode={
                                                    ['includes', 'not_include'].includes(
                                                        filter.condition
                                                    )
                                                        ? 'multiple'
                                                        : ''
                                                }
                                                tagRender={(props) => (
                                                    <ClosableTag
                                                        onClose={() =>
                                                            handleDeselectMultipleValues(
                                                                props.value,
                                                                filter.id
                                                            )
                                                        }
                                                        style={{ fontWeight: 400 }}
                                                    >
                                                        {props.label}
                                                    </ClosableTag>
                                                )}
                                            >
                                                {filter?.compromiseValues?.map(
                                                    (compromise, index) => (
                                                        <SelectOption
                                                            key={index}
                                                            value={compromise.value}
                                                            title={compromise.label}
                                                        >
                                                            {['includes', 'not_include'].includes(
                                                                filter.condition
                                                            ) && (
                                                                <Checkbox
                                                                    value={compromise.value}
                                                                    checked={
                                                                        [
                                                                            'includes',
                                                                            'not_include',
                                                                        ].includes(
                                                                            filter?.condition
                                                                        )
                                                                            ? filter?.value?.includes(
                                                                                  compromise?.value
                                                                              )
                                                                            : filter?.value ===
                                                                              compromise?.value
                                                                    }
                                                                />
                                                            )}
                                                            <label style={{ paddingLeft: 8 }}>
                                                                {compromise?.label}
                                                            </label>
                                                        </SelectOption>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col
                                    span={1}
                                    className={`delete_condition ${
                                        !filter.isRemovable || (isCustomTab && index === 0)
                                            ? 'disabled'
                                            : ''
                                    }`}
                                >
                                    <Form.Item>
                                        {isCustomTab && index === 0 ? (
                                            <Tooltip
                                                title="La vista personalizada debe tener al menos un filtro"
                                                color="#FFFFFF"
                                                overlayInnerStyle={{
                                                    color: '#2D3D76',
                                                }}
                                            >
                                                <RemoveIcon className="delete_condition_button disabled" />
                                            </Tooltip>
                                        ) : (
                                            <RemoveIcon
                                                onClick={() => {
                                                    if (filter.isRemovable) {
                                                        dispatch(removeFilter(filter.id));
                                                    }
                                                }}
                                                className="delete_condition_button"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </RowFilterStyled>
                        );
                    })}
            </Form>
            <Flex justifyContent="flex-end" margin="15px 0 27px" paddingRight="16px">
                <SecondaryButton onClick={handleAddFilter} style={{ height: 32 }}>
                    Agregar filtro
                </SecondaryButton>
            </Flex>
            <Flex
                paddingTop="0.5rem"
                justifyContent="flex-end"
                gap="0.5rem"
                style={{ borderTop: '1px solid #e4e8ec' }}
            >
                <SecondaryButton
                    loading={isSaveNewViewLoading.current}
                    disabled={
                        !isButtonEnabled || isApplyFiltersLoading.current || filters.length === 0
                    }
                    onClick={() => {
                        form.submit();
                    }}
                    variation="link"
                >
                    Guardar en vista nueva
                </SecondaryButton>
                <PrimaryButton
                    loading={isApplyFiltersLoading.current}
                    disabled={
                        !isButtonEnabled || isSaveNewViewLoading.current || filters.length === 0
                    }
                    onClick={handleApplyFilters}
                >
                    Aplicar filtros
                </PrimaryButton>
            </Flex>
        </>
    );
}

export default FilterForm;
