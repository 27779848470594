// import { pdf } from '@react-pdf/renderer';
import { ReactComponent as PrintIcon } from 'assets/print-icon.svg';
import { BsChevronRight } from 'react-icons/bs';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
// import DeliverPDF from 'components/PDF/DeliveryPDF';
import Flex from 'components/Utils/Flex';
import { Button } from 'antd';
import printJS from 'print-js';
import DrawerWithButton from 'components/DrawerWithButton';
// import useGetGenerals from 'hooks/useGetGenerals';
import { fulfillmentsApi, useFulfillmentsBatchDeliveryMutation } from 'redux/features/Fulfillments';
import { fetchBatchFulfillments } from 'redux/features/Fulfillments/getBatchFulfillments';
import history from 'helpers/history';

// const calculatePackagesForManifest = (fulfillments) => {
//     const allPackages = fulfillments
//         .filter((fulfillment) =>
//             [
//                 'ready_for_deliver',
//                 'all_packages_processed',
//                 'delivered_to_courier',
//                 'delivered',
//             ].includes(fulfillment.status)
//         )
//         .reduce((acc, ele) => {
//             acc.push(...ele.packages.filter((pkg) => pkg.status === 'processed'));
//             return acc;
//         }, []);

//     const chunck_size = 25;
//     const chunkPackages = allPackages.reduce((resultArray, item, index) => {
//         const chunkIndex = Math.floor(index / chunck_size);
//         if (!resultArray[chunkIndex]) {
//             resultArray[chunkIndex] = [];
//         }

//         const fulfillment = fulfillments.find(
//             (fulfillment) => fulfillment._id === item.fulfillment_id
//         );

//         resultArray[chunkIndex].push({
//             package_number: index + 1,
//             fulfillment_packages: fulfillment?.packages?.length ?? 0,
//             fulfillment_name: fulfillment?.name ?? '',
//             tracking_number: fulfillment?.tracking?.tracking_number ?? '',
//             ...item,
//         });

//         return resultArray;
//     }, []);

//     return {
//         allPackages,
//         chunkPackages,
//     };
// };

const ProcessDeliveryModal = ({ fulfillments = [], disabled }) => {
    const [loadingDeliver, setLoadingDeliver] = useState(false);
    // const [, generalData] = useGetGenerals();
    // const business_name = generalData?.billing_data?.business_name ?? '';

    const dispatch = useDispatch();

    const [batchFulfillmentsDeliver, batchDeliverFulfillmentsState] =
        useFulfillmentsBatchDeliveryMutation();

    const handleSubmit = async () => {
        setLoadingDeliver(true);

        const fulfillment_ids = fulfillments
            .filter((f) => ['ready_for_deliver', 'all_packages_processed'].includes(f.status))
            .map((f) => f._id);

        const response = await batchFulfillmentsDeliver({
            fulfillment_ids,
        });

        if (response?.data?.manifest_url) {
            window.localStorage.setItem('lastManifestUrl', response.data.manifest_url);
            printJS(response.data.manifest_url);
        }

        console.log('manifest response', response);

        if (response?.data) {
            setLoadingDeliver(false);
            dispatch(fetchBatchFulfillments());
            dispatch(
                fulfillmentsApi.util.invalidateTags([
                    'fulfillmentsToDelivery',
                    'fulfillmentsToPreparation',
                ])
            );

            history.push('/deliveries');
        }
    };

    const allDelivered =
        fulfillments.every(
            (f) => f.status === 'delivered' || f.status === 'delivered_to_courier'
        ) || false;

    return (
        <Flex direction="row" columnGap="1rem">
            <DrawerWithButton
                buttonProps={{
                    type: 'primary',
                    disabled:
                        disabled ||
                        fulfillments.length === 0 ||
                        allDelivered ||
                        batchDeliverFulfillmentsState.isLoading ||
                        loadingDeliver,
                    buttonText: 'Procesar',
                    style: {
                        height: '44px',
                        fontSize: '1rem',
                        padding: '0rem 1rem',
                        borderRadius: '0.25rem',
                    },
                    Icon: BsChevronRight,
                }}
                drawerProps={{
                    /* title:
                        fulfillments.length === 1
                            ? `Detalles del pedido ${fulfillments[0].name}`
                            : `Detalles de los (${fulfillments.length}) pedidos seleccionados`, */
                    title: <span style={{ color: '' }}>Procesar entrega</span>,
                    toSubmit: handleSubmit,
                    name: 'packages-details-drawer',
                    okText: 'Procesar',
                    width: '400px',
                    okButtonProps: {
                        disabled:
                            fulfillments.length === 0 ||
                            batchDeliverFulfillmentsState.isLoading ||
                            loadingDeliver,
                    },
                }}
            >
                <p style={{ fontSize: '14px', fontWeight: 500 }}>
                    Los siguientes pedidos (
                    {
                        fulfillments.filter((f) =>
                            ['ready_for_deliver', 'all_packages_processed'].includes(f.status)
                        ).length
                    }
                    ) están en óptima condición para procesarlos:
                </p>

                <div
                    style={{
                        maxHeight: '240px',
                        overflowY: 'auto',
                    }}
                >
                    {fulfillments
                        .filter((f) =>
                            ['ready_for_deliver', 'all_packages_processed'].includes(f.status)
                        )
                        .map((fulfillment) => (
                            <Fragment key={fulfillment._id}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: '0.5rem',
                                        borderBottom: '1px solid #DCE5EC',
                                        padding: '0.65rem',
                                    }}
                                >
                                    <b className="underline">
                                        PEDIDO {fulfillment.name} |{' '}
                                        {fulfillment.delivery_method === 'pickup' && 'F.R'}
                                        {fulfillment.delivery_method === 'shipping' && 'F.D'}{' '}
                                        XX/XX/XX |{' '}
                                        {fulfillment.delivery_method === 'pickup' && 'RETIRO'}
                                        {fulfillment.delivery_method === 'shipping' &&
                                            `DESPACHO | ${
                                                fulfillment?.courier?.public_name ??
                                                fulfillment?.courier?.courier_name
                                            }`}
                                    </b>
                                    <ul style={{ padding: '6px' }}>
                                        {fulfillment.packages.map((pkg) => (
                                            <li key={pkg.code} className="flex items-center ml-3">
                                                <div
                                                    style={{
                                                        width: '3px',
                                                        height: '3px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'black',
                                                        marginRight: '0.5rem',
                                                    }}
                                                ></div>
                                                <span>
                                                    <b>BULTO: {' #'}</b>
                                                    {pkg.code}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Fragment>
                        ))}
                </div>
                <br />
                <p style={{ fontSize: '14px', fontWeight: 500 }}>
                    ¿Desea confirmar el proceso de entrega de estos pedidos?
                </p>
            </DrawerWithButton>
            {allDelivered && (
                <Button
                    type="primary"
                    style={{ height: '44px' }}
                    onClick={() => {
                        const lastManifestUrl = window.localStorage.getItem('lastManifestUrl');
                        if (lastManifestUrl) {
                            printJS(lastManifestUrl);
                        }
                    }}
                >
                    <Flex direction="row" alignItems="center" columnGap="10px">
                        <PrintIcon style={{ width: '24px', height: '24px', fill: 'white' }} />{' '}
                        <span>
                            <b>Re-Imprimir actas</b>
                        </span>
                    </Flex>
                </Button>
            )}
        </Flex>
    );
};

export default ProcessDeliveryModal;
