/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessages = /* GraphQL */ `
  query GetMessages {
    getMessages {
      action
      id
      merchant_id
      message
      user_id
      __typename
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      block_actions
      block_reason
      merchant_id
      target_id
      user_id
      active_users
      __typename
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: TableRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        block_actions
        block_reason
        merchant_id
        target_id
        user_id
        active_users
        __typename
      }
      nextToken
      __typename
    }
  }
`;
