import { Modal, Tooltip } from 'antd';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
import PropTypes from 'prop-types';
import NormalButton from 'components/Buttons/NormalButton';
import MenuItem from 'components/V2/Menu/MenuItem';

import { LinkStyled } from './styles';

const ModalWithButton = ({
    children,
    modalProps,
    buttonProps,
    onOk,
    onButtonClick,
    buttonType = 'button',
    classN,
}) => {
    const { name, extraOnOkAction } = modalProps;

    const dispatch = useDispatch();
    const state = useSelector((state) => state.utils);
    const isActive = (state.modal[name] && !buttonProps.disabled) || false;

    const handleToggleModal = () => dispatch(toggleModal(name));

    useEffect(() => {
        if (!isActive || !modalProps?.okButtonProps?.id) return;

        setTimeout(() => {
            const elementButton = document.getElementById(modalProps.okButtonProps.id);
            elementButton?.focus();
        }, 1000);
    }, [isActive]);
    // className enviada desde Actions/index.jsx/ModalWithButton, para acotar el modal

    return (
        <Fragment>
            <Modal
                className={classN || ''}
                open={isActive}
                destroyOnClose={true}
                onOk={() => {
                    // handleToggleModal();
                    onOk && onOk();

                    {
                        // handleToggleModal();

                        extraOnOkAction && extraOnOkAction();
                    }
                }}
                onCancel={handleToggleModal}
                {...modalProps}
            >
                {children}
            </Modal>
            {buttonType === 'button' && (
                <NormalButton
                    onClick={() => {
                        dispatch(toggleModal(name));
                        onButtonClick && onButtonClick();
                    }}
                    {...buttonProps}
                />
            )}
            {buttonType === 'menuitem' && (
                <MenuItem
                    onClick={() => {
                        dispatch(toggleModal(name));
                        onButtonClick && onButtonClick();
                    }}
                    {...buttonProps}
                >
                    <Tooltip placement="bottom" title={buttonProps.tooltip}>
                        {buttonProps?.buttonText ?? ''}
                    </Tooltip>
                </MenuItem>
            )}
            {buttonType === 'link' && (
                <LinkStyled
                    onClick={() => {
                        dispatch(toggleModal(name));
                        onButtonClick && onButtonClick();
                    }}
                    {...buttonProps}
                >
                    {buttonProps?.buttonText ?? ''}{' '}
                </LinkStyled>
            )}
        </Fragment>
    );
};

ModalWithButton.propTypes = {
    name: PropTypes.string,
    title: PropTypes.any,
    buttonText: PropTypes.any,
    children: PropTypes.node,
    modalProps: PropTypes.object,
    buttonProps: PropTypes.object,
    onOk: PropTypes.func,
    onButtonClick: PropTypes.func,
    classN: PropTypes.string,
};

export default ModalWithButton;
