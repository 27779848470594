import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import normalizeString from 'helpers/normalizeString';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';

export const CountryDirectionContext = createContext({
    countries: [],
    getCountryInfo: () => null,
    getMexicoZips: () => [],
    getMexicoAddressByZip: () => null,
    getMexicoZipByAddress: () => null,
});

const countryUrlHandler = (country) => {
    return `https://andes-commerce-cloud-prod-assets.s3.us-east-1.amazonaws.com/directions/${country}.json`;
};

export const CountryDirectionProvider = ({ children }) => {
    const [countries, setCountries] = useState([]);

    const activeDirectionMexico = useGetValueFeatureFlag('enable_mexico');

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const [chile, peru, colombia, mexicoWithSettlements] = await Promise.all([
                    axios.get(countryUrlHandler('chile')),
                    axios.get(countryUrlHandler('peru')),
                    axios.get(countryUrlHandler('colombia')),
                    ...(activeDirectionMexico
                        ? [axios.get(countryUrlHandler('mexico_with_settlements'))]
                        : []),
                ]);

                setCountries([
                    {
                        id: 'chile',
                        name: 'Chile',
                        code: 'CL',
                        province: chile?.data ?? [],
                    },
                    {
                        id: 'peru',
                        name: 'Peru',
                        code: 'PE',
                        province: peru?.data ?? [],
                    },
                    {
                        id: 'colombia',
                        name: 'Colombia',
                        code: 'CO',
                        province: colombia?.data ?? [],
                    },
                    {
                        id: 'mexico',
                        name: 'México',
                        code: 'MX',
                        province: mexicoWithSettlements?.data ?? [],
                    },
                ]);
            } catch (error) {
                console.log('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);

    const getMexicoZips = () => {
        const mexicoStates = countries.find((c) => c.id === 'mexico')?.province ?? [];

        return mexicoStates.reduce((acc, states) => {
            Array.from(states.municipalities ?? []).forEach((municipality) => {
                Array.from(municipality.districts ?? []).forEach((district) => {
                    Array.from(district.settlements ?? []).forEach((settlement) => {
                        if (!acc.includes(settlement.zip_code)) {
                            acc.push(settlement.zip_code);
                        }
                    });
                });
            });

            return acc;
        }, []);
    };

    const getMexicoAddressByZip = (zip) => {
        const mexicoStates = countries.find((c) => c.id === 'mexico')?.province ?? [];

        for (const state of mexicoStates) {
            for (const municipality of state.municipalities) {
                for (const district of municipality.districts) {
                    for (const settlement of district.settlements) {
                        if (settlement.zip_code === zip) {
                            return {
                                state: state.name,
                                municipality: municipality.name,
                                city: district.name,
                                settlement: settlement.name,
                            };
                        }
                    }
                }
            }
        }

        return null;
    };

    const getMexicoZipByAddress = (stateName, municipalityName, cityName, settlementName) => {
        const mexicoStates = countries.find((c) => c.id === 'mexico')?.province ?? [];

        for (const state of mexicoStates) {
            for (const municipality of state.municipalities) {
                for (const district of municipality.districts) {
                    for (const settlement of district.settlements) {
                        if (
                            normalizeString(settlement.name) === normalizeString(settlementName) &&
                            normalizeString(district.name) === normalizeString(cityName) &&
                            normalizeString(municipality.name) ===
                                normalizeString(municipalityName) &&
                            normalizeString(state.name) === normalizeString(stateName)
                        ) {
                            return settlement.zip_code;
                        }
                    }
                }
            }
        }

        return null;
    };

    const getCountryInfo = (country, province, municipality, city) => {
        const countryInfo = countries.find(
            (c) => c.id === country || c.name === country || c.code === country
        );

        const provinces = countryInfo?.province ?? [];

        if (!province) return provinces;

        const municipalities =
            provinces.find((p) => p.id === province || p.name === province)?.municipalities ?? [];

        if (!municipality && !city) return municipalities;

        if (!city) {
            return (
                municipalities.find((m) => m.id === municipality || m.name === municipality)
                    ?.districts ?? []
            );
        }

        return (
            municipalities
                .find((m) => m.id === municipality || m.name === municipality)
                ?.districts.find((d) => d.id === city || d.name === city)?.settlements ?? []
        );
    };

    return (
        <CountryDirectionContext.Provider
            value={{
                countries,
                getCountryInfo,
                getMexicoZips,
                getMexicoAddressByZip,
                getMexicoZipByAddress,
            }}
        >
            {children}
        </CountryDirectionContext.Provider>
    );
};
