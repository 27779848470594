import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import {
    ActionButtonTooltip,
    AvatarChip,
    CommentaryBox,
    CustomTooltip,
    DotTimeLine,
    LimitTimeFulfillment,
    LogDescription,
    LogItem,
    MessageTimeLineStatus,
    // ActionButton,
    Text,
    Textarea,
    TimeColumn,
    TimelineContainer,
    TimelineDate,
    TimelineLine,
    TitleLineTimeStatus,
} from './styles';

import { Button } from '@andescommercecloud/beflow-design-system';
import { Tag as AntdTag, List, Modal } from 'antd';
import axios from 'axios';
import { FulFillmentTag, OrderSacStatusTag } from 'components/RenderTags';
import { ModalLogs } from 'components/TimeLine/components/ModalLogs';
import { ModalTicket } from 'components/TimeLine/components/ModalTicket';
import Flex from 'components/Utils/Flex';
import MoneyFormat from 'helpers/MoneyFormat';
import { printText } from 'helpers/printText';
import useCurrentUser from 'hooks/useCurrentUser';
import { FiChevronUp, FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useResendWebhookMutation } from 'redux/features/Webhooks';
import { ModalCreateComment } from './ModalCreateComment';
import { WebhookTimeLineDescription } from './WebhookLogs';
import { ModalWebhookDetail } from './WebhookLogs/ModalWebhookDetail';
import { ModalWebhookReport } from './WebhookLogs/ModalWebhookReport';
import { useLogMessages } from './hooks/useLogMessages';
import { TagSLA } from './tagSLA';
// import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { JsonImporterErrorTimeline } from './JsonImporterSOLogs';
import { Rpro9ErrorTimeline } from './Rpro9Logs';

import {
    jsonImporterCloseSaleOrderLogs,
    jsonImporterCreditNoteLogs,
    jsonImporterInvoiceLogs,
    jsonImporterStockTransferLogs,
    jsonImporterTicketLogs,
} from './constants/json-importer';
import { documentReportedLogs } from './DocumentReportedLogs';
import { siesaInvoiceLogs } from './constants/siesa';
import Tag from 'components/Tag';

const TimelineV2 = ({ data, name }) => {
    const [dataLogs, setDataLogs] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [openComment, setOpenComment] = useState({});
    const [pdfsOk, setPdfsOk] = useState([]);
    const { userData } = useCurrentUser();
    const {
        onCreateComment,
        handleDelete,
        handleEdit,
        handleUpdate,
        commentary,
        setCommentary,
        createLogMessageStatus,
        // deleteLogMessageStatus,
        updateLogMessageStatus,
        setCurrentLog,
        currentLog,
        setIsModalCommentVisible,
        stateComments,
        setIsEdit,
    } = useLogMessages({ data });

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancelModal = () => {
        setIsModalVisible(false);
    };
    const handleOkModal = () => {
        setIsModalVisible(false);
    };

    const [resendWebhook] = useResendWebhookMutation();
    const [isOpenModalWebhookDetail, setIsOpenModalWebhookDetail] = useState(false);
    const [isOpenModalWebhookReport, setIsOpenModalWebhookReport] = useState(false);
    const [webhookLogSelected, setWebhookLogSelected] = useState();
    const [disabledResendWebhook, setDisabledResendWebhook] = useState(false);

    const handleClickResendWebhook = async (log) => {
        if (disabledResendWebhook) {
            toast.error('Espere 30 segundos para volver a reenviar el informe');
            return;
        }
        setDisabledResendWebhook(true);
        resendWebhook({
            log_id: log._id,
        });

        setTimeout(() => {
            setDisabledResendWebhook(false);
        }, 30000);
    };

    useEffect(() => {
        setDataLogs(data?.logs);
    }, [data]);

    const eventsLogs = dataLogs?.map((log) => {
        const itemsRemoved = log?.other?.items?.reduce((acc, el) => {
            if (!el?.is_shipping_item) {
                acc += el.quantity;
            }

            return acc;
        }, 0);

        const itemsCancelationRequested = log?.other?.items?.reduce((acc, el) => {
            if (el?.quantity) {
                acc += el.quantity;
            }

            return acc;
        }, 0);

        const fulfillmentCreateBy = log?.other?.username;

        const findFulfillmentByLogisticInfo = data?.fulfillments?.find(
            (ful) => ful?.logistic_id === log?.other?.logistic_id
        );

        const isRefunded = log?.other?.type === 'refund';
        const hasShippingItem = log?.other?.items?.some((item) => item?.is_shipping_item);

        const logIsFailed =
            String(log?.action)?.toLowerCase().includes('failed') ||
            String(log?.action)?.toLowerCase().includes('error') ||
            String(log?.action)?.toLowerCase().includes('cancelation');
        const attempts = `${log?.attempts} ${log?.attempts > 1 ? 're intentos' : 'intento'}`;

        const labels = {
            retailpro9: 'Retail Pro 9',
        };

        const actionLabels = {
            ORDER_INCIDENCE_CREATED: `Se ha añadido al pedido ${
                log?.other?.order_name
            } una nueva incidencia de tipo: ${log?.other?.incident_type}. responsable: ${
                log?.other?.username
            }
            ${log?.other?.comment ? `con comentario: ${log?.other?.comment}` : ''}`,
            ORDER_SC_ITEMS_CHANGED_SUCCESS: `Se han removido productos del pedido ${
                log?.other?.order_name ?? ''
            } en ${log?.other?.channel_name ?? ''}`,
            ORDER_SC_ITEMS_CHANGED_FAILED: `Ha fallado la actualización de productos del pedido ${
                log?.other?.order_name ?? ''
            } en ${log?.other?.channel_name ?? ''}`,
            ORDER_CREATED: `Se ha ingresado el pedido ${
                log?.other?.name ?? log?.other?.order_name
            }`,
            ORDER_CREATED_FAILED: `Ha fallado la ingestión del pedido ${
                log?.other?.name ?? ''
            }, ${attempts}`,
            FULFILLMENT_STATUS_UPDATED: (
                <span>
                    <FulFillmentTag status={log.other.new_status ?? ''} />{' '}
                </span>
            ),
            DELIVERY_TRACKING_UPDATED_EXTERNAL: (
                <>
                    <span>
                        Se ha actualizado el seguimiento del fulfillment{' '}
                        {log?.other?.fulfillment_id && (
                            <Link to={`/fulfillments/${log?.other?.fulfillment_id}`}>
                                <b>
                                    <u>{log?.other?.fulfillment_name ?? ''}</u>
                                </b>
                            </Link>
                        )}
                    </span>
                    <span>
                        <FulFillmentTag status={log.other.new_tracking_status ?? ''} />{' '}
                    </span>
                </>
            ),
            ORDER_DELETED: `Se ha eliminado el pedido ${log?.other?.name}`,
            FLOW_EXECUTION_STARTED: `Se ha iniciado el flujo de integración ${
                String(log?.other?.flow_name)?.toLowerCase() ?? ''
            } para el pedido ${log?.other?.order_name ?? ''}`,
            DEFAULT_FLOW_EXECUTION_STARTED: `Se ha iniciado el flujo de integración por defecto para el pedido ${log?.other?.order_name}`,
            ORDER_DOCUMENT_REPORTED_FAILED: `Ha fallado el reporte de un documento de el pedido ${log?.other?.order_name} a ${log?.other?.channel_name} (${attempts}) `,
            ORDER_DOCUMENT_REPORTED_SUCCESS: `Se ha informado un documento de el pedido ${log?.other?.order_name} a ${log?.other?.channel_name}`,
            VERIFY_ITEMS_PRISM: `Se han verificado los items del pedido ${log.other?.order_name}`,
            CHANGE_LINE_ITEM_SKU: `Se ha cambiado el SKU ${log?.other?.prev_sku} por el SKU ${log?.other?.new_sku} por: ${log?.other.username}`,
            FULFILLMENT_PROMISED_DATE_CHANGED: `Se ha cambiado la fecha de entrega del fulfillment ${
                log?.other?.fulfillment_name
            } de ${dayjs(log?.other?.old_promised_date).format('DD/MM/YYYY HH:mm')} a ${dayjs(
                log?.other?.new_promised_date
            ).format('DD/MM/YYYY HH:mm')}. Responsable: ${log?.other.username}`,
            ORDER_PROMISED_DATE_CHANGED: `Se ha cambiado la fecha de entrega del pedido ${
                log?.other?.order_name
            } de ${dayjs(log?.other?.old_promised_date).format('DD/MM/YYYY HH:mm')} a ${dayjs(
                log?.other?.new_promised_date
            ).format('DD/MM/YYYY HH:mm')}. Responsable: ${log?.other.username}`,
            DTE_TICKET_CREATED_FAILED: `Ha fallado la ${
                log?.other?.is_external ? 'asociación' : 'creación'
            } de la boleta DTE para el pedido ${log?.other?.order_name} (${attempts})`,
            REMOVE_ITEMS_FROM_ORDER: `Se ha${itemsRemoved > 1 ? 'n' : ''} ${
                isRefunded ? 'devuelto' : 'anulado'
            } ${itemsRemoved} artículo${itemsRemoved > 1 ? 's' : ''} del pedido ${
                log?.other?.order_name
            }, ${hasShippingItem ? `más el costo de envío` : ''}  por motivo: ${
                log?.comment ? log?.comment : 'Sin comentario'
            }. ${log?.other?.username ? `Usuario: ${log?.other?.username}` : ''} `,
            ORDER_CREDIT_NOTE_PRISM_FAILED: `Ha fallado la integración de la nota de crédito a prism para el pedido ${log?.other?.order_name} en la sucursal ${log?.other?.location_name}`,
            ORDER_WMS_REPORTED_SUCCESS: `Se ha integrado el documento ${
                log?.other?.id_doc_salida ?? ''
            } correctamente del pedido ${log?.other?.order_name ?? ''} a WMS`,
            ORDER_WMS_REPORTED_FAILED: `Ha fallado la integración a WMS para el pedido ${log?.other?.order_name} (${attempts})`,
            ORDER_NEW_FULFILLMENT: `Se ha creado el fulfillment ${log?.other?.fulfillment_name}  ${
                fulfillmentCreateBy ? `Responsable: ${fulfillmentCreateBy}` : ''
            }`,
            ORDER_RESERVATION_INVOICE_FAILED: `Ha fallado la integración de la boleta a ${log?.other?.platform?.toLowerCase()} para el pedido ${
                log?.other?.order_name
            } ${
                log?.other?.location_name ? `en la sucursal ${log.other.location_name}` : ''
            } (${attempts})`,
            DTE_CREDIT_NOTE_CREATED_FAILED: `Ha fallado la creación de la nota de crédito DTE para el pedido ${log?.other?.order_name} (${attempts})`,
            ORDER_CANCELLED_OMS: `Se ha cancelado el pedido ${log?.other?.order_name} en OMS por usuario: ${log?.other?.username}`,
            DTE_CREDIT_NOTE_CREATED: (
                <>
                    Se ha creado la nota de crédito DTE{' '}
                    {log?.other?.DocUrl ? (
                        <a target="_blank" rel="noreferrer" href={log?.other?.DocUrl}>
                            <u>{log?.other?.DocNum ?? ''}</u>
                        </a>
                    ) : (
                        <u>{log?.other?.DocNum ?? ''}</u>
                    )}{' '}
                    para el pedido {log?.other?.order_name}
                </>
            ),
            ORDER_STATUS_UPDATED: (
                <>
                    <span>Se ha actualizado el estado del pedido a </span>
                    <FulFillmentTag status={log?.other?.new_status} />
                    <span>
                        {log?.other?.username
                            ? log?.other?.new_status === 'fulfilled'
                                ? `Usuario: ${log?.other?.username}`
                                : ''
                            : ''}
                    </span>
                </>
            ),
            ORDER_INPROGRESS_REPORTED_FAILED: `Ha fallado el informe de orden en progreso ${
                log?.other?.channel_name
            } para el pedido ${log?.other?.order_name ?? ''} (${attempts})`,
            ORDER_STATUS_UPDATED_EXTERNAL: (
                <>
                    <span>Se ha recibido nuevo cambio de estado </span>
                    <FulFillmentTag status={log?.other?.new_status} />
                    <span>
                        {log?.other?.new_status === 'PickingCompleto' ||
                        log?.other?.new_status === 'PickingParcialAnulado' ||
                        log?.other?.new_status === 'PickingParcialCompletado'
                            ? `Usuario: Servicio de uso externo`
                            : ''}
                    </span>
                </>
            ),
            ORDER_FULFILLMENT_COMPLETED: (
                <>
                    <span>
                        El fulfillment{' '}
                        <Link to={`/fulfillments/${log.other.fulfillment_id}`}>
                            <u>{log.other.fulfillment_name}</u>
                        </Link>{' '}
                        se ha completado{' '}
                    </span>
                    {log?.other?.tracking_url && (
                        <>
                            <a href={log?.other?.tracking_url} target="_blank" rel="noreferrer">
                                <b>
                                    <u>Ver Seguimiento</u>
                                </b>
                            </a>
                            {log?.other?.pdf && (
                                <ModalTicket
                                    urlPDF={log?.other?.pdf}
                                    showModal={showModal}
                                    setPdfsOk={setPdfsOk}
                                />
                            )}
                        </>
                    )}
                </>
            ),

            ORDER_FULFILLMENT_DELIVERED: (
                <span>
                    {log?.other?.username ? (
                        <>
                            Se ha informado la entrega al cliente del fulfillment{' '}
                            <Link to={`/fulfillments/${log?.other?.fulfillment_id ?? ''}`}>
                                <u>{log?.other?.fulfillment_name ?? ''}</u>
                            </Link>{' '}
                            por parte del operador: {log?.other?.username}
                        </>
                    ) : (
                        <>
                            Se ha informado la entrega al cliente del fulfillment{' '}
                            <Link to={`/fulfillments/${log?.other?.fulfillment_id ?? ''}`}>
                                <u>{log?.other?.fulfillment_name ?? ''}</u>
                            </Link>{' '}
                            por parte del operador logístico {log?.other?.courier_name ?? ''}
                        </>
                    )}
                </span>
            ),
            ORDER_FULFILLMENT_DELIVERED_TO_COURIER: (
                <span>
                    El fulfillment{' '}
                    <Link to={`/fulfillments/${log?.other?.fulfillment_id ?? ''}`}>
                        <u>{log?.other?.fulfillment_name ?? ''}</u>
                    </Link>{' '}
                    del pedido {log?.other?.order_name ?? ''} se ha entregado al transportista.{' '}
                    {log?.other?.username ? `Usuario: ${log?.other?.username}` : ''}
                </span>
            ),
            CHANGE_COURIER_WITH_INCIDENCE_FROM_DELIVERIES: (
                <>
                    Se ha cambiado el operador logístico de {log?.other?.old_courier} a{' '}
                    {log?.other?.new_courier}.{' '}
                    {log?.other?.username && `Usuario: ${log?.other?.username}`}
                </>
            ),
            CREATE_DELIVERY: (
                <>
                    <span>
                        Se ha creado el envío del fulfillment{' '}
                        <Link to={`/fulfillments/${log.other.fulfillment_id}`}>
                            <u>{log?.other?.fulfillment_name}</u>
                        </Link>{' '}
                        en {log.other.platform}.
                    </span>{' '}
                    <a href={log?.other?.tracking_url ?? '#'} target="_blank" rel="noreferrer">
                        <b>
                            <u>Ver Seguimiento </u>
                        </b>
                    </a>
                    {log?.other?.pdf && (
                        <ModalTicket
                            urlPDF={log?.other?.pdf}
                            showModal={showModal}
                            setPdfsOk={setPdfsOk}
                        />
                    )}
                </>
            ),
            CREATE_DELIVERY_FAILED: `Ha fallado la creación de envío ${
                log?.other?.old_sid ? log?.other?.old_sid : ''
            } del pedido ${log?.other?.order_name} en ${log?.other?.platform} (${attempts})`,
            ORDER_RESERVATION_INVOICE_CREATED: (
                <span>
                    Se ha creado la boleta {log?.other?.doc_num} a{' '}
                    {log?.other?.platform?.toLowerCase()} para el pedido {log?.other?.order_name} en
                    la sucursal {log?.other?.location_name?.toLowerCase() ?? ''}
                </span>
            ),
            ORDER_SALE_ORDER_CREATED: (
                <span>
                    Se ha integrado la orden de venta {log?.other?.DocEntry ?? ''} a{' '}
                    {log?.other?.platform?.toLowerCase()} para el pedido{' '}
                    {log?.other?.order_name ?? ''} en la sucursal{' '}
                    {log?.other?.location_name?.toLowerCase() ?? ''}{' '}
                    {/* {log?.other?.logistic_index > -1
                        ? `(${log?.other?.order_name}-F${log?.other?.logistic_index + 1})`
                        : ''} */}
                </span>
            ),
            DTE_TICKET_CREATED: (
                <span>
                    Se ha {log?.other?.is_external ? 'asociado' : 'creado'} la boleta DTE con folio{' '}
                    <a
                        {...(log?.other?.doc_url ?? log?.other?.DocUrl ?? ''
                            ? {
                                  target: '_blank',
                                  rel: 'noreferrer',
                                  href: log?.other?.doc_url ?? log?.other?.DocUrl ?? '',
                              }
                            : {})}
                    >
                        <u>#{log?.other?.doc_num ?? log?.other?.DocNum ?? '' ?? ''}</u>
                    </a>{' '}
                    para el pedido {log?.other?.order_name ?? ''}{' '}
                    {log?.other?.from ? `informada desde ${log?.other?.from}` : ''}
                </span>
            ),
            ORDER_CREDIT_NOTE_PRISM_CREATED: (
                <span>
                    Se ha integrado la nota de crédito{' '}
                    <a
                        {...(log?.other?.doc_url || log?.other?.DocUrl
                            ? {
                                  target: '_blank',
                                  rel: 'noreferrer',
                                  href: log?.other?.doc_url || log?.other?.DocUrl,
                              }
                            : {})}
                    >
                        <u>{log?.other?.doc_num || log?.other?.DocNum}</u>
                    </a>{' '}
                    a prism para el pedido {log?.other?.order_name} en la sucursal{' '}
                    {log?.other?.location_name?.toLowerCase() ?? ''}
                </span>
            ),
            FULFILLMENT_COMPLETED_REPORTED_FAILED: `Ha fallado la información de la preparación del fulfillment ${
                log?.other?.fulfillment_name
            } del pedido ${log?.other?.order_name ?? ''} a ${log?.other?.channel_name}`,
            FULFILLMENT_COMPLETED_REPORTED_SUCCESS:
                log?.other?.channel_name === 'magento' && !log?.other?.fulfillment_id
                    ? `Se ha informado que el pedido ${
                          log?.other?.order_name ?? ''
                      } ha creado el shipment en magento`
                    : `Se ha informado la preparación del fulfillment ${
                          log?.other?.fulfillment_name
                      } del pedido ${log?.other?.order_name ?? ''} a ${log?.other?.channel_name}`,
            ORDER_SALE_ORDER_CREATED_FAILED: `Ha fallado la integración de la orden de venta a ${log?.other?.platform?.toLowerCase()} para el pedido ${
                log?.other?.order_name
            } ${
                log?.other?.location_name ? 'en la sucursal ' + log?.other?.location_name : ''
            } (${attempts})`,
            TRANSFER_STOCK_TO_PRISM_SUCCESS: `Se ha realizado la transferencia de stock con SID ${
                log?.other?.doc_entry ?? ''
            } asociada al pedido/fulfillment ${
                log?.other?.fulfillment_name ?? log?.other?.order_name
            } de la tienda ${log?.other?.from_location_name} (${
                log?.other?.from_location_id
            }) a la tienda ${log?.other?.to_location_name} (${log?.other?.to_location_id})`,
            TRANSFER_STOCK_TO_PRISM_FAILED: ` Ha fallado la transferencia de stock asociada al pedido/fulfillment ${
                log?.other?.fulfillment_name ?? log?.other?.order_name ?? ''
            }. (${attempts})`,
            TRANSFER_STOCK_WITH_ITEMS_TO_PRISM_SUCCESS: `Se ha realizado transferencia inversa de artículo(s) en Prism con SID ${
                log?.other?.doc_entry ?? ''
            } asociada al pedido/fulfillment ${
                log?.other?.fulfillment_name ?? log?.other?.order_name
            } de la tienda ${log?.other?.from_location_name} (${
                log?.other?.from_location_id
            }) a la tienda ${log?.other?.to_location_name} (${log?.other?.to_location_id})`,
            TRANSFER_STOCK_WITH_ITEMS_TO_PRISM_FAILED: `Ha fallado la transferencia inversa de artículo(s) en Prism asociada al pedido/fulfillment ${
                log?.other?.fulfillment_name ?? log?.other?.order_name ?? ''
            } de la tienda ${log?.other?.from_location_name} (${
                log?.other?.from_location_id
            }) a la tienda ${log?.other?.to_location_name} (${
                log?.other?.to_location_id
            }) (${attempts})`,
            ORDER_MANUAL_REMOVE_SHIPPING_PRICE: (
                <span>
                    Se ha modificado el costo de envío del pedido {log?.other?.order_name} de{' '}
                    {MoneyFormat(log?.other?.old_shipping_price) ?? '$0'} a{' '}
                    {MoneyFormat(log?.other?.new_shipping_price) ?? '$0'}
                </span>
            ),
            ORDER_MANUAL_CHANGE_SAC_STATUS: (
                <>
                    <span>
                        Se ha actualizado el estado SAC del{' '}
                        {log.other?.fulfillment_name
                            ? `fulfillment ${log.other.fulfillment_name}`
                            : 'pedido'}{' '}
                        de forma manual a{' '}
                    </span>
                    <OrderSacStatusTag status={log.other.new_status} />
                    <br />
                    <small>{log?.comment ? `Nota: ${log?.comment}` : ''}</small>
                </>
            ),
            ORDER_MANUAL_CHANGE_STATUS: (
                <span>
                    Se ha actualizado el estado del pedido de{' '}
                    <FulFillmentTag status={log.other.old_status} />
                    de forma manual a <FulFillmentTag status={log.other.new_status} />
                    Responsable: {log?.other?.username}
                </span>
            ),
            FULFILLMENT_MANUAL_CHANGE_STATUS: (
                <span>
                    Se ha actualizado el estado del fulfillment {log?.other?.fulfillment_name ?? ''}{' '}
                    de <FulFillmentTag status={log.other.old_status} />
                    de forma manual a <FulFillmentTag
                        status={log.other.new_status}
                    /> Responsable: {log?.other?.username}
                </span>
            ),
            SALE_ORDER_CLOSED_FAILED: `Ha fallado el cierre de la orden de venta ${
                log?.other?.DocEntry?.sid ?? ''
            } del pedido ${log?.other?.order_name} (${attempts})`,
            SALE_ORDER_CLOSED_SUCCESS: `Se ha cerrado la orden de venta ${
                log?.other?.DocEntry?.sid ?? ''
            } del pedido ${log?.other?.order_name}`,
            FULFILLMENT_SHIPMENT_REPORTED_SUCCESS: `Se ha notificado el shipment del pedido ${
                log?.other?.order_name ?? ''
            } del pedido a ${log?.other?.channel_name}`,
            FULFILLMENT_SHIPMENT_REPORTED_FAILED: `Ha fallado la notificación del shipment del pedido ${
                log?.other?.order_name ?? ''
            } del pedido a ${log?.other?.channel_name} (${attempts})`,
            ORDER_SALE_ORDER_RESTARTED: (
                <span>
                    Se ha iniciado un reinicio de la orden de venta del pedido{' '}
                    {log?.other?.order_name ?? ''}
                    en ({log?.other?.platform ?? ''}/{log?.other?.integration ?? ''})
                    {log?.other.old_location_name !== log?.other.new_location_name && (
                        <Fragment>
                            {' '}
                            desde <u>{log?.other?.old_location_name ?? ''}</u> a{' '}
                            <u>{log?.other?.new_location_name ?? ''}</u>
                        </Fragment>
                    )}
                </span>
            ),
            ORDER_SALE_ORDER_RESTARTED_FAILED: `Ha fallado el reinicio de la orden de venta ${
                log?.other?.old_sid ?? ''
            } del
            pedido ${log?.other?.order_name ?? ''}.
            (${attempts} en ${log?.other?.platform ?? ''}/
            ${log?.other?.integration ?? ''})`,
            CHANGE_ORDER_SHIPPING_ADDRESS: `Se ha modificado la dirección de entrega del pedido ${
                log?.other?.order_name
            }. ${log?.other?.username ? `Usuario: ${log?.other?.username}` : ''}`,
            FULFILLMENT_DELIVERED_TICKETS_FAILED: `Ha fallado la obtención de etiquetas de ${
                String(log?.other?.channel_name).toLowerCase() ?? ''
            } para el pedido${log?.other?.order_name}`,
            ORDER_ALREADY_CANCELLED_STOP_CHECK_TICKETS: `El pedido ${log?.other?.order_name} ya se encuentra cancelado, se detiene la obtención de etiquetas`,
            CREATION_FULFILLMENT_PENDING: `Fulfillment pendiente de creación para el pedido ${
                log?.other?.name ?? log?.other?.order_name
            }`,
            CANCEL_DELIVERY_DELETE_MANIFEST: `El pedido ${log?.other?.order_name} asociado al manifiesto ${log?.other?.manifest_code}, ha sido retornado a entrega. Responsable: ${log?.other?.username}`,
            ORDER_FULFILLMENT_EXTERNAL_CREATED: (
                <span>
                    Se ha creado el fulfillment externo{' '}
                    <Link to={`/fulfillments/${log?.other?.fulfillment_id ?? ''}`}>
                        <u>{log?.other?.fulfillment_name ?? ''}</u>
                    </Link>
                    {'  '}
                    para el pedido
                    {log?.other?.order_name ?? ''}
                    {'  '}
                    {log?.other?.pending_of_confirmation ? (
                        <AntdTag color="geekblue"> Pendiente de confirmación externa </AntdTag>
                    ) : (
                        ''
                    )}
                </span>
            ),
            VERIFY_ITEMS_PRISM_FAILED: (() => {
                const status = log?.other?.error_object?.status ?? null;
                if (log?.other?.order_id) {
                    const orderName = log?.other?.order_name ?? '';
                    const hasValidItemsError = status === 400;
                    const hasDescriptionErrors = Array.isArray(
                        log?.other?.error_object?.response_data?.descripcion
                    );
                    // const hasRequestDataErrors = Array.isArray(log?.other?.error_object?.request_data);
                    // console.log({ log });
                    if (hasValidItemsError) {
                        return (
                            <span>
                                Al menos un artículo no válido al consultar servicio ValidaArtículos
                                en el pedido <span>{orderName}</span>
                            </span>
                        );
                    }

                    return (
                        <span>
                            Ha {hasDescriptionErrors ? 'fallado' : 'ocurrido un'} la verificación de
                            los items para el pedido {orderName}{' '}
                            <ModalLogs
                                modalProps={{
                                    title: 'Items con error',
                                }}
                                buttonProps={{
                                    buttonText: (
                                        <a>
                                            <u>Ver items</u>
                                        </a>
                                    ),
                                }}
                            >
                                <List
                                    bordered
                                    dataSource={
                                        hasDescriptionErrors
                                            ? log?.other?.error_object?.response_data?.descripcion
                                            : log?.other?.error_object?.request_data
                                    }
                                    renderItem={(item) => {
                                        console.log({ item });
                                        return (
                                            <List.Item>
                                                {hasDescriptionErrors
                                                    ? item
                                                    : `El sku ${item?.alu} no se encontró en la subsidiara ${item?.sbsno}`}
                                            </List.Item>
                                        );
                                    }}
                                />
                            </ModalLogs>{' '}
                            ({attempts})
                        </span>
                    );
                }

                return (
                    <span>
                        Ha ocurrido un error al verificar los items del pedido{' '}
                        {log?.other?.order_id && <>{log?.other?.order_name ?? ''}</>} ({attempts})
                    </span>
                );
            })(),
            CHANGE_STORE: `${
                log?.other?.isPartially
                    ? 'Se efectuó cambio de tienda/bodega por parcialidad de items de'
                    : 'Se efectuó cambio de tienda/bodega para todo el pedido de'
            } ${log?.other?.original_location_name ?? ''} a ${
                log?.other?.new_location_name ?? ''
            }. Usuario: ${log?.other?.username ?? ''}`,
            CANCEL_DELIVERY_FULFILLMENT: `Se ha cancelado el envío de ${
                log?.other?.platform ?? ''
            } para el fulfillment/pedido: ${log?.other?.fulfillment_name ?? ''}`,
            CANCEL_DELIVERY_FULFILLMENT_FAILED: `Ha fallado al cancelar el envío de ${
                log?.other?.platform ?? ''
            } para el fulfillment/pedido: ${log?.other?.fulfillment_name ?? ''}`,
            FULFILLMENT_DELIVERED_REPORTED_FAILED: (
                <>
                    <span>Ha fallado la información de la entrega del fulfillment </span>
                    <Link to={`/fulfillments/${log.other.fulfillment_id}`}>
                        <u>{log.other.fulfillment_name}</u>
                    </Link>
                </>
            ),
            ORDER_FULFILLMENT_CANCELLED: (
                <span>
                    Se ha cancelado el fulfillment{' '}
                    <b>
                        <Link to={`/fulfillments/${log?.other?.fulfillment_id ?? ''}`}>
                            <u>{log?.other?.fulfillment_name ?? ''}</u>
                        </Link>
                    </b>
                </span>
            ),
            ORDER_FULFILLMENT_CANCELLED_FAILED: (
                <>
                    <span>
                        Ha fallado el reporte de cancelación del fulfillment{' '}
                        <Link to={`/fulfillments/${log.other.fulfillment_id}`}>
                            <u>{log.other.fulfillment_name}</u>
                        </Link>{' '}
                        al canal de venta {log?.other?.channel_name ?? ''}
                    </span>
                </>
            ),
            CREATE_SALES_NOTES_SAP_FAILED: `Ha fallado la integración del pedido ${log.other.fulfillment_name} de la Orden de Venta a SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_SALES_NOTES_SAP_SUCCESS: `Se ha integrado del pedido ${log.other.fulfillment_name} la Orden de Venta a SAP  con docEntry: ${log.other.docEntry} para la sucursal ${log.other.warehouse_name}`,
            CREATE_DELIVERY_NOTES_SAP_FAILED: log.other.external
                ? `Ha fallado la vinculación de la Orden de Entrega en SAP B1, el motivo ${log.other.reason}, ${attempts}`
                : `Ha fallado la integración de la Orden de Entrega a SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_DELIVERY_NOTES_SAP_SUCCESS: log.other.update
                ? `Se ha actualizado la Orden de Entrega en SAP B1 con docEntry: ${
                      log.other.docEntry
                  }${log.other.folio ? ` con guía número ${log.other.folio}` : ''}`
                : log.other.external
                ? `Se ha vinculado la Orden de entrega en SAP B1 con docEntry: ${
                      log.other.docEntry
                  }${log.other.folio ? ` con guía número ${log.other.folio}` : ''}`
                : `Se ha integrado la Orden de Entrega en SAP B1 con docEntry: ${
                      log.other.docEntry
                  } para la sucursal ${log.other.warehouse_name}${
                      log.other.folio ? ` con guía número ${log.other.folio}` : ''
                  }`,
            CREATE_INVOICES_RESERVED_SAP_FAILED: `Ha fallado la integración de la Factura de Reserva en SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_INVOICES_RESERVED_SAP_SUCCESS: `Se ha integrado la Factura de Reserva en SAP B1 con docEntry: ${log.other.docEntry} para la sucursal ${log.other.warehouse_name}`,
            CREATE_TICKET_SAP_FAILED: `Ha fallado la integración de la Boleta en SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_TICKET_SAP_SUCCESS: `Se ha integrado la boleta en SAP B1 con docEntry: ${log.other.docEntry} para la sucursal ${log.other.warehouse_name}`,
            CREATE_INVOICES_SAP_FAILED: `Ha fallado la integración de la Factura en SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_INVOICES_SAP_SUCCESS: `Se ha integrado la factura en SAP B1 con docEntry: ${log.other.docEntry} para la sucursal ${log.other.warehouse_name}`,
            CREATE_RETURN_REQUEST_SAP_FAILED: `Ha fallado la integración de la solicitud de devolución en SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_RETURN_REQUEST_SAP_SUCCESS: `Se ha integrado la solicitud de devolución en SAP B1 con docEntry: ${log.other.docEntry} para la sucursal ${log.other.warehouse_name} con el código rma ${log.other.code_rma} y ticket id ${log.other.ticket_id}`,
            UPDATE_CREDIT_NOTES_SAP_SUCCESS: `Se ha actualizado la Nota de Crédito en SAP B1 del docEntry: ${log.other.docEntries?.toString()} con los datos bancarios`,
            CREATE_CREDIT_NOTES_SAP_FAILED: `Ha fallado la integración de la Nota de Crédito en SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_CREDIT_NOTES_SAP_SUCCESS: `Se ha integrado la Nota de Crédito en SAP B1 con docEntry: ${log.other.docEntry} para la sucursal ${log.other.warehouse_name}`,
            CREATE_RETURN_SAP_SUCCESS: `Se ha integrado la Devolución en SAP B1 con docEntry: ${log.other.docEntry} de la Nota Crédito ${log.other.folio}`,
            CREATE_RETURN_SAP_FAILED: `Ha fallado la integración de la Devolución en SAP B1 de la Nota Crédito ${log.other.folio}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_STOCK_TRANSFER_SAP_FAILED: log.other.external
                ? `Ha fallado la vinculación de la Transferencia de Stock${
                      log.other.document_id ? ' Inversa' : ''
                  } en SAP B1`
                : `Ha fallado la integración de la Transferencia de Stock${
                      log.other.document_id ? ' Inversa' : ''
                  } en SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${
                      log.other.reason
                  }, ${attempts}`,
            CREATE_STOCK_TRANSFER_SAP_SUCCESS: log.other.external
                ? `Se ha vinculado la Transferencia de Stock${
                      log.other.document_id ? ' Inversa' : ''
                  } en SAP B1 con docEntry: ${log.other.docEntry}`
                : `Se ha integrado la Transferencias de Stock${
                      log.other.document_id ? ' Inversa' : ''
                  } en SAP B1 con docEntry: ${log.other.docEntry} de la sucursal ${
                      log.other.warehouse_name
                  } a la sucursal ${log.other.warehouse_destination}${
                      log.other.doc_entry_inventory_transfer_request
                          ? ` relacionada a la Sol. de Transf. de stock número ${log.other.doc_entry_inventory_transfer_request}`
                          : ''
                  }`,
            CREATE_INVENTORY_TRANSFER_REQUEST_SAP_FAILED: `Ha fallado la integración del pedido ${log.other.fulfillment_name} de la Solicitud de Transferencia de Stock en SAP B1 para la sucursal ${log.other.warehouse_name}, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_INVENTORY_TRANSFER_REQUEST_SAP_SUCCESS: `Se ha integrado la Solicitud Transferencias de Stock en SAP B1 con docEntry: ${log.other.docEntry} de la sucursal ${log.other.warehouse_name} a la sucursal ${log.other.warehouse_destination}`,
            CREATE_CANCEL_INVENTORY_TRANSFER_REQUEST_SAP_SUCCESS: `Se ha cancelado la Solicitud Transferencias de Stock en SAP B1 con docEntry: ${log.other.docEntry} de la sucursal ${log.other.warehouse_name} a la sucursal ${log.other.warehouse_destination}`,
            CREATE_INCOMING_PAYMENT_SAP_FAILED: `Ha fallado la integración del Pago Recibido en SAP B1, el motivo ${log.other.reason}, ${attempts}`,
            CREATE_INCOMING_PAYMENT_SAP_SUCCESS: `Se ha integrado el Pago Recibido${
                log.other.credit_note ? ' por la cancelación de la Nota de Crédito' : ''
            } a la ${log.other.nameInvoice} número ${
                log.other.docEntryInvoice
            } en SAP B1 con docEntry: ${log.other.docEntry}`,
            CREATE_CANCEL_INCOMING_PAYMENT_SAP_SUCCESS: `Se ha cancelado el Pago Recibido en SAP B1 con docEntry: ${log.other.docEntry} a la ${log.other.nameInvoice} relacionada ${log.other.docEntryInvoice}`,
            CREATE_CANCEL_STOCK_TRANSFER_SAP_SUCCESS: `Se ha cancelado la Transferencia de Stock en SAP B1 con docEntry: ${log.other.docEntry} a la ${log.other.nameInvoice} relacionada ${log.other.docEntryInvoice}`,
            CREATE_CANCEL_DELIVERY_NOTES_SAP_SUCCESS: `Se ha cancelado la Orden de Entrega en SAP B1 con docEntry: ${log.other.docEntry} a la ${log.other.nameInvoice} relacionada ${log.other.docEntryInvoice}`,
            UPDATE_REFERENCE_INFO_PRISM_IN_SAP_FAILED: `Ha fallado la integración del SID de PRISM/RPRO9 A SAP B1, el motivo ${log.other.reason}, ${attempts}`,
            UPDATE_REFERENCE_INFO_PRISM_IN_SAP_SUCCESS: `Se ha integrado SID ${log.other.docEntry} de PRISM/RPRO9 a SAP`,
            CREATE_BUSINESS_PARTNER_SAP_SUCCESS: `Se ha creado el Business Partner en SAP B1 con el CardCode: ${log.other.document}`,
            CREATE_BUSINESS_PARTNER_SAP_FAILED: `Ha fallado la creación del Business Partner en SAP B1, el motivo ${log.other.reason}, ${attempts}`,
            UPDATE_BUSINESS_PARTNER_SAP_SUCCESS: `Se ha actualizado el Business Partner en SAP B1 con el CardCode: ${log.other.document}`,
            UPDATE_BUSINESS_PARTNER_SAP_FAILED: `Ha fallado la actualización del Business Partner en SAP B1, el motivo ${log.other.reason}, ${attempts}`,
            ON_ROUTE_COURIER_COURIER: 'Ha iniciado la entrega del courier Recibelo',
            COMPLETED_COURIER: 'Ha finalizado la entrega del courier Recibelo',
            NOT_COMPLETED_COURIER: 'Ha fallado al entrega del courier Recibelo',
            ORDER_RPRO9_SO_CREATED_PENDING: `Se ha solicitado ${
                log?.other?.is_cancelation ? 'cancelación de' : ''
            } la orden de venta a RPRO9 para el pedido ${log?.other?.order_name} en la sucursal ${
                log?.other?.location_name
            }`,
            ORDER_RPRO9_SO_CREATED: `Se ha integrado ${
                log?.other?.is_cancelation ? 'cancelación de' : ''
            } la orden de venta ${log?.other?.sid} a RPRO9 para el pedido ${
                log?.other?.order_name
            } en la sucursal ${log?.other?.location_name}`,
            ORDER_RPRO9_SO_FAILED: (
                <Rpro9ErrorTimeline
                    log={log}
                    entity={`${log?.other?.is_cancelation ? 'cancelación de' : ''} orden de venta`}
                    labels={labels}
                />
            ),
            ORDER_RPRO9_STOCK_TRANSFER_FAILED: (
                <Rpro9ErrorTimeline log={log} entity="transferencia de stock" labels={labels} />
            ),
            ORDER_RPRO9_STOCK_TRANSFER_CREATED: `Se ha realizado una transferencia de stock ${
                log?.other?.sid ?? ''
            } para el pedido ${log?.other?.order_name ?? ''} de la tienda ${
                log?.other?.from_location_name
            } a la tienda ${log?.other?.to_location_name}`,
            ORDER_RPRO9_INVOICE_CREATED_PENDING: `Se ha solicitado la boleta a Retail Pro 9 para el pedido ${
                log?.other?.order_name ?? ''
            } en la sucursal ${log?.other?.location_name ?? ''}`,
            ORDER_RPRO9_INVOICE_CREATED: `Se ha integrado la boleta ${
                log?.other?.sid ?? ''
            } a Retail Pro 9 para el pedido ${log?.other?.order_name ?? ''} en la sucursal ${
                log?.other?.location_name ?? ''
            }`,
            ORDER_RPRO9_CREDIT_NOTE_FAILED: (
                <Rpro9ErrorTimeline log={log} entity="nota de crédito" labels={labels} />
            ),
            ORDER_INPROGRESS_REPORTED_SUCCESS: `Se ha informado que el pedido ${
                log?.other?.order_name ?? ''
            } esta en proceso a ${log?.other?.channel_name}`,
            SALES_CHANNEL_ORDER_CANCELLED_SUCCESS: (
                <span>
                    Se ha recibido solicitud de cancelación para el pedido {log?.other?.name ?? ''}{' '}
                    desde {log?.other?.sales_channel ?? ''} {log?.other?.origin ?? ''}
                    {log?.comment && (
                        <span style={{ opacity: '0.8' }}>
                            <br />
                            <b>Comentario: </b> {log?.comment ?? ''}
                        </span>
                    )}
                </span>
            ),
            TRACKING_PUSH_CREATED: (
                <span>
                    Se ha actualizado el estado del seguimiento{' '}
                    <a target="_blank" href={log?.other?.tracking_url ?? ''} rel="noreferrer">
                        <b>
                            <u>{log?.other?.tracking_number ?? ''}</u>
                        </b>
                    </a>{' '}
                    a <u>{log?.other?.tracking_description}</u> el{' '}
                    {dayjs(log?.other?.tracking_event_date).format('DD/MM/YYYY HH:mm:ss')}
                </span>
            ),
            TRACKING_PUSH_CREATED_FAILED: `Ha ocurrido un error al actualizar seguimiento, ${attempts}`,
            ORDER_LOCATION_PICKUP_NOT_FOUND: `No se encuentra la tienda ${log?.other?.location_not_found} en el oms.`,
            ORDER_CANCELLED_CHANNEL: `Se ha cancelado el pedido en Ecommerce `,
            ORDER_INCOMING_PAYMENT_CREATED: `Se ha creado la orden de venta ${
                log.other.doc_num
            } para el pedido ${log?.other?.order_name ?? ''}`,
            ORDER_INCOMING_PAYMENT_FAILED: `Ha fallado la creación de la orden de venta para el pedido ${
                log?.other?.order_name ?? ''
            }. ${attempts}`,
            FULFILLMENT_QUICK_PREPARATION: `
                Se ha completado una preparacion rapida por el usuario ${
                    log?.other?.username ?? ''
                } para el fulfillment ${log?.other?.fulfillment_name ?? ''}.`,
            DTE_INVOICE_CREATED: (
                <span>
                    Se ha {log?.other?.is_external ? 'asociado' : 'creado'} la factura DTE{' '}
                    <a
                        {...(log?.other?.doc_url ?? log?.other?.DocUrl ?? ''
                            ? {
                                  target: '_blank',
                                  rel: 'noreferrer',
                                  href: log?.other?.doc_url ?? log?.other?.DocUrl ?? '',
                              }
                            : {})}
                    >
                        <u>{log?.other?.doc_num ?? log?.other?.DocNum ?? '' ?? ''}</u>
                    </a>{' '}
                    para el pedido {log?.other?.order_name ?? ''}{' '}
                    {log?.other?.from ? `informada desde ${log?.other?.from}` : ''}
                </span>
            ),
            DTE_INVOICE_CREATED_FAILED: `Ha fallado la creación de la factura DTE para el pedido ${
                log?.other?.order_name ?? ''
            }, ${attempts}`,
            DTE_SHIPPING_NOTE_CREATED: (
                <span>
                    Se ha creado una nueva guía de despacho / remisión con el folio{' '}
                    <a
                        {...(log?.other?.doc_url ?? log?.other?.DocUrl ?? ''
                            ? {
                                  target: '_blank',
                                  rel: 'noreferrer',
                                  href: log?.other?.doc_url ?? log?.other?.DocUrl ?? '',
                              }
                            : {})}
                    >
                        <u>{log?.other?.doc_num ?? log?.other?.DocNum ?? '' ?? ''}</u>
                    </a>{' '}
                    para el fulfillment {log?.other?.fulfillment_name ?? ''}
                </span>
            ),
            DTE_SHIPPING_NOTE_CREATED_FAILED: `Ha fallado la creación de la guía de despacho DTE para el pedido ${
                log?.other?.order_name ?? ''
            }, ${attempts}`,
            ORDER_START_HANDLING_CHANNEL: `Se ha iniciado el manejo del pedido ${log?.other?.order_name} en ${log.other.platform}`,
            ORDER_START_HANDLING_CHANNEL_FAILED: `Ha fallado el inicio del manejo del pedido ${log?.other?.order_name} en ${log.other.platform}`,
            DTE_INVOICE_CREATED_CHANNEL: `Se ha adjuntado el DTE ${log.other.doc_num} al pedido ${log?.other?.order_name}en ${log.other.platform}`,
            DTE_INVOICE_CREATED_CHANNEL_FAILED: `Ha fallado el intento de adjuntar DTE en ${log.other.platform}`,
            TRACKING_INVOICE_CREATED_CHANNEL: (
                <>
                    <span>
                        Se ha iniciado el seguimiento{' '}
                        <a target="_blank" href={log?.other?.tracking_url} rel="noreferrer">
                            <u>{log?.other?.tracking_num} Ver seguimiento </u>
                        </a>{' '}
                        en {log.other.platform}
                    </span>
                    {log?.other?.pdf && (
                        <ModalTicket
                            urlPDF={log?.other?.pdf}
                            showModal={showModal}
                            setPdfsOk={setPdfsOk}
                        />
                    )}
                </>
            ),
            TRACKING_INVOICE_CREATED_CHANNEL_FAILED: `Ha fallado el intento de iniciar seguimiento en ${log.other.platform}`,
            TRACKING_DELIVERED_CREATED_CHANNEL: `Se ha informado la entrega del pedido ${log?.other?.order_name} en ${log.other.platform}`,
            TRACKING_DELIVERED_CREATED_CHANNEL_FAILED: `Ha fallado el intento de informar entrega en ${log.other.platform}`,
            ORDER_MANUAL_CHANGE_COURIER: `Se ha cambiado manualmente el courier ${log?.other?.old_courier} por ${log?.other?.new_courier}`,
            FULFILLMENT_DELIVERED_REPORTED_SUCCESS: `
            Se ha informado la entrega del fulfillment ${log?.other?.fulfillment_name} del pedido ${
                log?.other?.order_name ?? ''
            }
            a ${log?.other?.channel_name}
            `,
            FULFILLMENT_DELIVERED_TO_COURIER_REPORTED_SUCCESS: `
            Se ha informado el despacho del fulfillment ${
                log?.other?.fulfillment_name
            } del pedido ${log?.other?.order_name ?? ''}
            a ${log?.other?.channel_name}
            `,
            FULFILLMENT_DELIVERED_TICKETS_SUCCESS: (
                <span>
                    Se han generado etiquetas para el fulfillment {log?.other?.fulfillment_name} del
                    pedido
                    {log?.other?.order_name ?? ''} desde {log?.other?.channel_name}
                    {log?.other?.label && (
                        <ModalTicket
                            urlPDF={log?.other?.label}
                            showModal={showModal}
                            setPdfsOk={setPdfsOk}
                        />
                    )}
                    {log?.other?.ZPLUrl && (
                        <a
                            role="button"
                            className="ml-2"
                            onClick={async () => {
                                try {
                                    const response = await axios.get(log?.other?.ZPLUrl, {
                                        headers: {
                                            'Content-Type': 'text/plain',
                                            'Access-Control-Allow-Origin': '*',
                                        },
                                    });
                                    printText(response.data);
                                } catch (error) {
                                    console.error(error);
                                }
                            }}
                        >
                            <b>
                                <u>Ver etiqueta ZPL</u>
                            </b>
                        </a>
                    )}
                </span>
            ),
            SHIPMENT_NOTIFICATION_FULFILLMENT_SUCCESS: `Se ha notificado de manera exitosa el informe del shipment con número ${log?.other?.shipment_id} a Magento para el estado ${log?.other?.status_informed}.`,
            SHIPMENT_NOTIFICATION_FULFILLMENT_DELIVERED_SUCCESS: `Se ha notificardo el envio al shipment de la orden ${log?.other?.order_name}.`,
            SHIPMENT_NOTIFICATION_READY_FOR_PICKUP_SUCCESS: `Se ha notificado de manera exitosa el informe de recogida del shipment con número ${log?.other?.shipment_id} a Magento para el estado ${log?.other?.status_informed}.`,
            CONFIRMATION_DELIVERY_ORDER: `La orden "${log?.other?.order_name}" ha sido confirmada exitosamente para el courier "${log?.other?.platform}".`,
            CONFIRMATION_DELIVERY_ORDER_FAILED: `Ocurrió un error al confirmar la orden "${log?.other?.order_name}" para el courier "${log?.other?.platform}".`,
            CANCELLATION_REQUEST_APPROVED: `Se ha aprobado la solicitud de cancelación del pedido ${log?.other?.order_name}. Responsable: ${log?.other?.username}`,
            FULFILLMENT_CANCELATION_REQUESTED: `Se ha enviado solicitud de cancelación del fulfillment ${log?.other?.fulfillment_name}. Responsable: ${log?.other?.username}`,
            ORDER_CANCELATION_REQUESTED: `Se ha enviado solicitud de cancelación del pedido ${log?.other?.order_name}. Responsable: ${log?.other?.username}`,
            ITEMS_CANCELATION_REQUESTED: `Se ha enviado solicitud de anulación de ${itemsCancelationRequested} ${
                itemsCancelationRequested > 1 ? 'artículos' : 'artículo'
            } para el pedido/fulfillment ${
                log?.other?.fulfillment_name || log?.other?.order_name
            }, por motivo: ${log?.comment ?? 'Sin comentarios'}. Responsable: ${
                log?.other?.username
            }`,
            ORDER_CANCEL_REPORTED_SUCCESS: `
            Se ha informado la cancelación del pedido ${log?.other?.order_name ?? ''} a ${
                log?.other?.channel_name
            }`,
            ORDER_CANCEL_REPORTED_FAILED: `Ha fallado la cancelación del pedido ${
                log?.other?.order_name ?? ''
            } en ${log?.other?.channel_name}`,
            ORDER_PRICE_CHANGED_FAILED: `Ha fallado el cambio de precio del pedido ${
                log?.other?.order_name ?? ''
            }`,
            ORDER_RPRO9_INVOICE_FAILED: (
                <Rpro9ErrorTimeline log={log} entity="boleta" labels={labels} />
            ),
            ORDER_RPRO9_CREDIT_NOTE_CREATED: `Se ha integrado la nota de crédito ${
                log?.other?.sid ?? ''
            } a Retail Pro9 para el pedido ${log?.other?.order_name ?? ''} en la sucursal ${
                log?.other?.location_name ?? ''
            }`,
            FULFILLMENT_READY_PENDING_RECEIPT: `El fulfillment ${log?.other?.fulfillment_name} del pedido ${log?.other?.order_name} ha sido recepcionado en la tienda/bodega ${log?.other?.pickup_location}.`,
            WEBHOOK_SENT_SUCCESSFULLY: (
                <WebhookTimeLineDescription
                    onClickReport={() => {
                        setWebhookLogSelected(log);
                        setIsOpenModalWebhookReport(true);
                    }}
                    onClickViewDetail={() => {
                        setWebhookLogSelected(log);
                        setIsOpenModalWebhookDetail(true);
                    }}
                    onClickRetry={() => handleClickResendWebhook(log)}
                    dataLog={log}
                    isFailed={logIsFailed}
                />
            ),
            WEBHOOK_SENT_FAILED: (
                <WebhookTimeLineDescription
                    onClickReport={() => {
                        setWebhookLogSelected(log);
                        setIsOpenModalWebhookReport(true);
                    }}
                    onClickViewDetail={() => {
                        setWebhookLogSelected(log);
                        setIsOpenModalWebhookDetail(true);
                    }}
                    onClickRetry={() => handleClickResendWebhook(log)}
                    dataLog={log}
                    isFailed={logIsFailed}
                />
            ),
            JSON_IMPORTER_SALE_ORDER_PENDING: `Se encuentra disponible una orden de venta para la integración a ${(
                log.other?.type_erp ?? ''
            ).toUpperCase()} para el ${
                findFulfillmentByLogisticInfo?.name ? 'fulfillment' : 'pedido'
            } ${
                findFulfillmentByLogisticInfo?.name ?? log.other?.request_data?.idexterno
            } en la sucursal ${log.other?.request_data?.storeno}`,
            JSON_IMPORTER_SALE_ORDER_READ: `Se ha leído la orden de venta SID ${
                log.other?.sid
            } para integración a ${log.other?.type_erp ?? ''} para el ${
                findFulfillmentByLogisticInfo?.name ? 'fulfillment' : 'pedido'
            } ${
                findFulfillmentByLogisticInfo?.name ?? log.other?.request_data?.idexterno
            } en la sucursal ${log.other?.request_data?.storeno}`,
            JSON_IMPORTER_SALE_ORDER_COMPLETED: `Se ha integrado la orden de venta SID ${
                log.other?.sid
            } a ${(log.other?.type_erp ?? '').toUpperCase()} para el ${
                findFulfillmentByLogisticInfo?.name ? 'fulfillment' : 'pedido'
            } ${
                findFulfillmentByLogisticInfo?.name ?? log.other?.request_data?.idexterno
            } en la sucursal ${log.other?.request_data?.storeno}`,
            JSON_IMPORTER_SALE_ORDER_ERROR: (
                <JsonImporterErrorTimeline log={log} entity="de la orden de venta" />
            ),
            JSON_IMPORTER_SALE_ORDER_PENDING_FAILED: log.other?.sid
                ? `Ha fallado el intento la creación de la orden de venta SID ${
                      log.other?.sid
                  } para la integración a ${(log.other?.type_erp ?? '').toUpperCase()} para el ${
                      findFulfillmentByLogisticInfo?.name ? 'fulfillment' : 'pedido'
                  } ${
                      findFulfillmentByLogisticInfo?.name ?? log.other?.request_data?.idexterno
                  } en la sucursal ${log.other?.request_data?.storeno}, el motivo ${
                      log.other.reason
                  }, ${attempts}`
                : `Ha fallado el intento la creación de una orden de venta para la integración ${(
                      log.other.type_erp ?? ''
                  ).toUpperCase()}, el motivo ${log.other.reason}, ${attempts}`,
            ...jsonImporterCloseSaleOrderLogs({
                log,
                attempts,
                fulfillmentName: findFulfillmentByLogisticInfo?.name,
            }),
            ...jsonImporterStockTransferLogs({
                log,
                attempts,
                fulfillmentName: findFulfillmentByLogisticInfo?.name,
            }),
            ...jsonImporterInvoiceLogs({
                log,
                attempts,
            }),
            ...jsonImporterTicketLogs({
                log,
                attempts,
            }),
            ...jsonImporterCreditNoteLogs({
                log,
                attempts,
                fulfillmentName: findFulfillmentByLogisticInfo?.name,
            }),
            ...siesaInvoiceLogs({
                log,
                attempts,
                fulfillmentName: findFulfillmentByLogisticInfo?.name,
            }),
            ESB_NOTIFY_STATUS_SUCCESS: (
                <>
                    <span>Se ha informado de manera exitosa el cambio de estado </span>
                    <Tag centered>
                        {log.other?.esb_state_reported ?? log.other?.request_data?.State}
                    </Tag>
                    <span>al ESB</span>
                </>
            ),
            ESB_NOTIFY_STATUS_FAILED: (
                <>
                    <span>Ha fallado el informe de cambio de estado </span>
                    <FulFillmentTag
                        status={log.other?.esb_state_reported ?? log.other?.request_data?.State}
                    />
                    <span>al ESB ({attempts})</span>
                </>
            ),
            ORDER_CASH_ON_DELIVERY: `Se ha informado el monto ${String(log.other.amount).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
            )} del pago contra entrega de manera exitosa, por parte del operador: ${
                log.other.username
            }`,
            ...documentReportedLogs(log),
        };

        const dateString = log?.createdAt;
        return {
            id: log?._id,
            log_messages: log?.log_messages,
            date: dayjs(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY'),
            time: dayjs(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('HH:mm:ss'),
            description: actionLabels[log?.action] || log?.action,
            action: log?.action,
            colorDot: logIsFailed ? '#E33512' : '#037FB9',
            colorDescription: logIsFailed ? '#E33512' : '#142C4A',
            title_status: 'sac o fulfillment',
        };
    });

    // Agrupar eventos por fecha
    const groupedEvents = eventsLogs?.reduce((result, event) => {
        const date = event?.date;
        if (!result[date]) {
            result[date] = [];
        }
        result[date].push(event);
        return result;
    }, {});

    // Agrupar eventos por fecha con filtro por log.action
    const groupedEventsStatus = {
        Fulfillment: {},
        Sac: {},
        Credit_note: {},
    };

    groupedEvents &&
        Object.keys(groupedEvents).forEach((date) => {
            const events = groupedEvents[date];
            const filteredSacEvents = events.filter((event) =>
                event.action?.toLowerCase().includes('sac')
            );
            const filteredFulfillmentEvents = events.filter((event) =>
                event.action?.toLowerCase().includes('fulfillment')
            );
            const filteredCreditNoteEvents = events.filter((event) =>
                event.action?.toLowerCase().includes('credit_note')
            );

            if (filteredSacEvents.length > 0) {
                groupedEventsStatus.Sac[date] = filteredSacEvents;
            }

            if (filteredFulfillmentEvents.length > 0) {
                groupedEventsStatus.Fulfillment[date] = filteredFulfillmentEvents;
            }

            if (filteredCreditNoteEvents.length > 0) {
                groupedEventsStatus.Credit_note[date] = filteredCreditNoteEvents;
            }
        });

    return (
        <>
            <ModalCreateComment
                isModalCommentVisible={stateComments?.isModalCommentVisible}
                userData={userData}
                setIsModalCommentVisible={(val) => setIsModalCommentVisible(val)}
                onCreateComment={onCreateComment}
                currentLog={currentLog}
                loading={
                    createLogMessageStatus.isLoading ||
                    updateLogMessageStatus.isLoading ||
                    updateLogMessageStatus.isLoading
                }
            />
            {name === 'time_line' ? (
                <TimelineContainer>
                    {groupedEvents &&
                        Object.entries(groupedEvents).map(([date, events]) => (
                            <React.Fragment key={date}>
                                <TimelineDate>{date}</TimelineDate>
                                {events.map((event, index) => (
                                    <LogItem key={index}>
                                        <TimeColumn>
                                            <span>{event.time}</span>{' '}
                                            <DotTimeLine color={event.colorDot} />
                                        </TimeColumn>

                                        <CustomTooltip>
                                            <LogDescription color={event.colorDescription}>
                                                {event.description}
                                                <br />
                                                {event?.log_messages?.length &&
                                                !openComment?.[event?.id] ? (
                                                    <Button
                                                        variation="link"
                                                        onClick={() =>
                                                            setOpenComment({
                                                                ...openComment,
                                                                [event?.id]: true,
                                                            })
                                                        }
                                                    >
                                                        Ver comentario
                                                    </Button>
                                                ) : (
                                                    !!openComment?.[event?.id] && (
                                                        <CommentaryBox>
                                                            <Flex
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                            >
                                                                <Text
                                                                    fontWeight="bold"
                                                                    color="#2D3D76"
                                                                    mb="0"
                                                                >
                                                                    Comentario:
                                                                </Text>
                                                                <Flex direction="row">
                                                                    <Button
                                                                        size="small"
                                                                        variation="link"
                                                                        icon={
                                                                            <FiEdit
                                                                                style={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleEdit(
                                                                                        event
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                    />
                                                                    <Button
                                                                        size="small"
                                                                        variation="link"
                                                                        icon={
                                                                            <FiTrash2
                                                                                style={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleDelete(
                                                                                        event
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                    />
                                                                </Flex>
                                                            </Flex>
                                                            {stateComments?.isEdit?.[event?.id] ? (
                                                                <Flex
                                                                    direction="column"
                                                                    style={{ minWidth: 500 }}
                                                                >
                                                                    <Flex columnGap="8px">
                                                                        <AvatarChip>
                                                                            {event?.log_messages?.[0]?.author
                                                                                ?.split(' ')
                                                                                ?.map((at) =>
                                                                                    at.substring(
                                                                                        0,
                                                                                        1
                                                                                    )
                                                                                )
                                                                                ?.toString()
                                                                                ?.replace(',', '')}
                                                                        </AvatarChip>
                                                                        <Textarea
                                                                            width="75%"
                                                                            value={commentary}
                                                                            onChange={(e) =>
                                                                                setCommentary(
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                            placeholder="Escriba aquí"
                                                                        />
                                                                    </Flex>
                                                                    <Flex
                                                                        direction="row"
                                                                        columnGap="5px"
                                                                        justifyContent="flex-end"
                                                                        marginTop="10px"
                                                                        width="83%"
                                                                    >
                                                                        <Button
                                                                            variation="secondary"
                                                                            onClick={() =>
                                                                                setIsEdit(
                                                                                    [event?.id],
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            Cancelar
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() =>
                                                                                handleUpdate(event)
                                                                            }
                                                                        >
                                                                            Guardar cambios
                                                                        </Button>
                                                                    </Flex>
                                                                </Flex>
                                                            ) : (
                                                                <Flex
                                                                    alignItems="center"
                                                                    columnGap="6px"
                                                                    padding=".5rem 1rem"
                                                                >
                                                                    <AvatarChip>
                                                                        {event?.log_messages?.[0]?.author
                                                                            ?.split(' ')
                                                                            ?.map((at) =>
                                                                                at.substring(0, 1)
                                                                            )
                                                                            ?.toString()
                                                                            ?.replace(',', '')}
                                                                    </AvatarChip>
                                                                    <Text fontWeight="bold" mb="0">
                                                                        {
                                                                            event?.log_messages?.[0]
                                                                                ?.author
                                                                        }
                                                                    </Text>
                                                                </Flex>
                                                            )}
                                                            <br />
                                                            {!stateComments?.isEdit?.[
                                                                event?.id
                                                            ] && (
                                                                <Flex
                                                                    alignItems="center"
                                                                    columnGap="6px"
                                                                    padding="0 1.2rem .5rem"
                                                                >
                                                                    <Text>
                                                                        {
                                                                            event?.log_messages?.[0]
                                                                                ?.message
                                                                        }
                                                                    </Text>
                                                                </Flex>
                                                            )}

                                                            <Flex
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                style={{
                                                                    borderTop: '1px solid #E4E8EC',
                                                                }}
                                                            >
                                                                <Button
                                                                    variation="link"
                                                                    style={{ paddingRight: 4 }}
                                                                    onClick={() =>
                                                                        setOpenComment({
                                                                            ...openComment,
                                                                            [event?.id]: false,
                                                                        })
                                                                    }
                                                                >
                                                                    <Text
                                                                        color="#2D3D76"
                                                                        mb="0"
                                                                        fontWeight="bold"
                                                                        style={{
                                                                            textDecoration:
                                                                                'underline',
                                                                        }}
                                                                    >
                                                                        Ver menos
                                                                    </Text>
                                                                </Button>
                                                                <FiChevronUp
                                                                    color="#2D3D76"
                                                                    fontSize={18}
                                                                />
                                                            </Flex>
                                                        </CommentaryBox>
                                                    )
                                                )}
                                            </LogDescription>
                                            {!event?.log_messages?.length ? (
                                                <div className="tooltip-content">
                                                    <ActionButtonTooltip
                                                        variation="secondary"
                                                        onClick={() => {
                                                            setIsModalCommentVisible(true);
                                                            setCurrentLog(event);
                                                        }}
                                                    >
                                                        + Agregar comentario
                                                    </ActionButtonTooltip>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </CustomTooltip>
                                    </LogItem>
                                ))}
                            </React.Fragment>
                        ))}
                    <TimelineLine />
                </TimelineContainer>
            ) : (
                <>
                    {groupedEventsStatus &&
                        Object.entries(groupedEventsStatus).map(([status, groupedEvents]) => {
                            if (Object.keys(groupedEvents).length === 0) {
                                return null; // Si no encuentra logs para este tipo de status, se omite en el timeline
                            }

                            return (
                                <TimelineContainer key={status}>
                                    {status !== 'Fulfillment' && (
                                        <>
                                            <hr style={{ margin: 3 }} />
                                            <TitleLineTimeStatus>
                                                <span className="time-line-status-label">
                                                    {status === 'Sac' && 'Estado '}
                                                    {status === 'Credit_note' && 'Estado de la '}
                                                </span>
                                                <span className="time-line-status-value">
                                                    {status === 'Sac' && ' SAC '}
                                                    {status === 'Credit_note' && 'Nota de crédito '}
                                                </span>
                                            </TitleLineTimeStatus>
                                        </>
                                    )}
                                    {status === 'Fulfillment' && (
                                        <>
                                            <TitleLineTimeStatus>
                                                <span className="time-line-status-label">
                                                    Estado del
                                                </span>
                                                <span className="time-line-status-value">
                                                    {' '}
                                                    Fulfillment{' '}
                                                </span>
                                            </TitleLineTimeStatus>

                                            <LimitTimeFulfillment>
                                                <span>Plazo límite:</span>
                                                <TagSLA data={data} />
                                            </LimitTimeFulfillment>
                                        </>
                                    )}

                                    {Object.entries(groupedEvents).map(([date, events]) => (
                                        <React.Fragment key={date}>
                                            <TimelineDate>{date}</TimelineDate>
                                            {events.map((event, index) => (
                                                <LogItem key={index}>
                                                    <TimeColumn>
                                                        <span> {event.time}</span>{' '}
                                                        <DotTimeLine color={event.colorDot} />
                                                    </TimeColumn>
                                                    <LogDescription color={event.colorDescription}>
                                                        {event.description}
                                                    </LogDescription>
                                                </LogItem>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                    <TimelineLine />
                                </TimelineContainer>
                            );
                        })}
                </>
            )}

            {name === 'time_line_status' &&
            Object.keys(groupedEventsStatus).every((key) => {
                return Object.keys(groupedEventsStatus[key]).length === 0;
            }) ? (
                <MessageTimeLineStatus>
                    <p> Sin datos</p>
                </MessageTimeLineStatus>
            ) : null}

            <Modal
                title="Tickets"
                visible={isModalVisible}
                onOk={handleOkModal}
                onCancel={handleCancelModal}
            >
                {pdfsOk.length > 0 &&
                    pdfsOk.map((u, i) => (
                        <iframe
                            className="mb-4"
                            key={`ticket${i}`}
                            title="Documento"
                            src={`${u}#toolbar=1&navpanes=0`}
                            style={{ width: '100%', height: '350px' }}
                            frameBorder="0"
                        ></iframe>
                    ))}
            </Modal>
            <ModalWebhookReport
                onClose={() => {
                    setIsOpenModalWebhookReport(false);
                    setWebhookLogSelected(undefined);
                }}
                isOpen={isOpenModalWebhookReport}
                data={webhookLogSelected}
            />
            <ModalWebhookDetail
                onClose={() => {
                    setIsOpenModalWebhookDetail(false);
                    setWebhookLogSelected(undefined);
                }}
                isOpen={isOpenModalWebhookDetail}
                data={webhookLogSelected?.other?.response ?? {}}
            />
        </>
    );
};

export default TimelineV2;
