import { ReactComponent as HourGlassIcon } from 'assets/hourglass.svg';
import { ReactComponent as HourGlassStartIcon } from 'assets/hourglass-start.svg';
import { ReactComponent as ReadyForDeliverIcon } from 'assets/ready-for-deliver.svg';
import { ReactComponent as CheckDeliveredIcon } from 'assets/check-delivered.svg';
import { ReactComponent as DeliveredToCourierIcon } from 'assets/delivered-to-courier.svg';
import { ReactComponent as CrossIcon } from 'assets/cross.svg';
import { ReactComponent as XCircleIcon } from 'assets/x-circle.svg';
import { ReactComponent as ReturnIcon } from 'assets/return.svg';
import { ReactComponent as ExclamationIcon } from 'assets/alert-icon-incidence.svg';
import { ReactComponent as PauseCircleIcon } from 'assets/pause-circle.svg';
import { ReactComponent as AlertIcon } from 'assets/alert-icon.svg';

export const pickupDefaultFlow = () => [
    {
        status: 'open',
        step_completed: true,
        step_name: 'Preparación pendiente',
        icon_name: 'hourglass',
        icon: <HourGlassIcon />,
    },
    {
        status: 'in_progress',
        step_completed: false,
        step_name: 'En proceso',
        icon_name: 'hourglass-start',
        icon: <HourGlassStartIcon />,
    },
    {
        status: 'ready_for_deliver',
        step_completed: false,
        step_name: 'Listo para retiro',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'delivered',
        step_completed: false,
        step_name: 'Entregado',
        is_final: true,
        icon_name: 'check-delivered',
        icon: <CheckDeliveredIcon />,
    },
];

export const shippingDefaultFlow = () => [
    {
        status: 'open',
        step_completed: true,
        step_name: 'Preparación pendiente',
        icon_name: 'hourglass',
        icon: <HourGlassIcon />,
    },
    {
        status: 'in_progress',
        step_completed: false,
        step_name: 'En proceso',
        icon_name: 'hourglass-start',
        icon: <HourGlassStartIcon />,
    },
    {
        status: 'ready_for_deliver',
        step_completed: false,
        step_name: 'Listo para despachar',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'delivered_to_courier',
        step_completed: false,
        step_name: 'Despachado',
        icon_name: 'delivered-to-courier',
        icon: <DeliveredToCourierIcon />,
    },
    {
        status: 'delivered',
        step_completed: false,
        step_name: 'Entregado',
        is_final: true,
        icon_name: 'check-delivered',
        icon: <CheckDeliveredIcon />,
    },
];

export const preparationcdTransferPickupStoreDefaultFlow = () => [
    {
        status: 'open',
        step_completed: true,
        step_name: 'Preparación pendiente',
        icon_name: 'hourglass',
        icon: <HourGlassIcon />,
    },
    {
        status: 'in_progress',
        step_completed: false,
        step_name: 'En proceso',
        icon_name: 'hourglass-start',
        icon: <HourGlassStartIcon />,
    },
    {
        status: 'ready_for_deliver_to_pickup_location',
        step_completed: false,
        step_name: 'Listo para despachar a tienda',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'delivered_to_pickup_location',
        step_completed: false,
        step_name: 'Enviado a tienda',
        icon_name: 'delivered-to-courier',
        icon: <DeliveredToCourierIcon />,
    },
    {
        status: 'received_in_pickup_location',
        step_completed: false,
        step_name: 'Recibido en tienda',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'ready_for_deliver',
        step_completed: false,
        step_name: 'Listo para retiro',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'delivered',
        step_completed: false,
        step_name: 'Entregado',
        is_final: true,
        icon_name: 'check-delivered',
        icon: <CheckDeliveredIcon />,
    },
];

export const consolidationDefaultFlow = () => [
    {
        status: 'open',
        step_completed: true,
        step_name: 'Preparación pendiente',
        icon_name: 'hourglass',
        icon: <HourGlassIcon />,
    },
    {
        status: 'in_progress',
        step_completed: false,
        step_name: 'En proceso',
        icon_name: 'hourglass-start',
        icon: <HourGlassStartIcon />,
    },
    {
        status: 'ready_for_deliver_to_cd_location',
        step_completed: false,
        step_name: 'Listo para despachar a tienda',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'delivered_to_cd_location',
        step_completed: false,
        step_name: 'Enviado a tienda',
        icon_name: 'delivered-to-courier',
        icon: <DeliveredToCourierIcon />,
    },
    {
        status: 'received_in_cd_location',
        step_completed: false,
        step_name: 'Recibido en tienda',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'ready_for_deliver',
        step_completed: false,
        step_name: 'Listo para despachar',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'delivered_to_courier',
        step_completed: false,
        step_name: 'Despachado',
        icon_name: 'delivered-to-courier',
        icon: <DeliveredToCourierIcon />,
    },
    {
        status: 'delivered',
        step_completed: false,
        step_name: 'Entregado',
        is_final: true,
        icon_name: 'check-delivered',
        icon: <CheckDeliveredIcon />,
    },
];

export const customFlowSteps = () => [
    {
        status: 'items_removed',
        step_completed: false,
        step_name: 'Items removidos',
        is_final: false,
        icon_name: 'x-circle',
        icon: <XCircleIcon />,
    },
    {
        status: 'in_progress',
        step_completed: false,
        step_name: 'En proceso',
        icon_name: 'hourglass-start',
        icon: <HourGlassStartIcon />,
    },
    {
        status: 'success',
        step_completed: false,
        step_name: 'Listo para despachar',
        icon_name: 'ready-for-deliver',
        icon: <ReadyForDeliverIcon />,
    },
    {
        status: 'items_refunded',
        step_completed: false,
        step_name: 'Items devueltos',
        is_final: false,
        icon_name: 'return',
        icon: <ReturnIcon />,
    },
    {
        status: 'cancelled',
        step_completed: false,
        step_name: 'Cancelado',
        is_final: true,
        icon_name: 'cross',
        icon: <CrossIcon />,
    },
    {
        status: 'cancelation_requested',
        step_completed: false,
        step_name: 'Solicitud de cancelación',
        is_final: false,
        icon_name: 'cross',
        icon: <AlertIcon />,
    },
    {
        status: 'sac',
        step_completed: false,
        step_name: 'SAC',
        is_final: false,
        icon_name: 'exclamation',
        icon: <ExclamationIcon />,
    },
    {
        status: 'paused',
        step_completed: false,
        step_name: 'Pausado',
        is_final: false,
        icon_name: 'paused-circle',
        icon: <PauseCircleIcon />,
    },
];
