import styled from 'styled-components';

export const FulfillmentFlowWrapper = styled.div`
    width: 100%;

    h3 {
        padding-left: 15px;
        color: #2d3d76;
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        padding-top: 1rem;
    }
`;

export const ScrollButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
`;

export const ScrollButton = styled.button`
    background-color: rgb(245, 245, 245);
    color: rgb(45, 61, 118);
    border: 1px solid rgb(83, 109, 143);
    font-size: 20px;
    /* padding: 10px; */
    width: 31px;
    height: 31px;
    border-radius: 50%;
    margin-top: 0px;
    margin: 0 2px;

    ${(props) =>
        props?.disabled &&
        `
            background-color: #F5F5F5;
            color: #DCE5EC;
            border: 1px solid #DCE5EC;
        `};
`;

export const FlowCircle = styled.div`
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 50%;
    background-color: #dce5ec;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    z-index: 101;

    ${(props) =>
        props?.isCompletedStep &&
        `
            background-color: #037FB9;
        `};

    ${(props) =>
        props?.isCompletedStep &&
        ['hourglass', 'hourglass-start', 'ready-for-deliver', 'x-circle'].includes(
            props?.iconName
        ) &&
        `
            svg path:nth-child(1) {
                fill: white;
            }

            svg path:nth-child(3) {
                fill: white;
            }
        `};

    ${(props) =>
        props?.isCompletedStep &&
        ['delivered-to-courier', 'check-delivered', 'exclamation'].includes(props?.iconName) &&
        `
            svg path:nth-child(1) {
                stroke: white;
            }
    `};

    ${(props) =>
        props?.isCompletedStep &&
        ['return'].includes(props?.iconName) &&
        `
            svg path:nth-child(1) {
                stroke: white;
            }

            svg path:nth-child(2) {
                stroke: white;
            }
    `};

    ${(props) =>
        props?.isCompletedStep &&
        ['paused-circle'].includes(props?.iconName) &&
        `
            svg {
                height: 18px;
                width: 18px;
            }

            svg path:nth-child(1) {
                stroke: white;
            }

            svg path:nth-child(2) {
                stroke: white;
            }

            svg path:nth-child(3) {
                stroke: white;
            }
    `};

    ${(props) =>
        ['x-circle'].includes(props?.iconName) &&
        `
            svg {
                height: 18px;
                width: 18px;
            }
    `};

    ${(props) =>
        props?.isCompletedStep &&
        props?.isFinalStep &&
        `background-color: #00BA76
    ;`};

    ${(props) =>
        props?.isCompletedStep &&
        ['cross'].includes(props?.iconName) &&
        `
            background-color: #E33512;
            svg path:nth-child(1) {
                stroke: white;
            }
    `};
`;

export const FlowStatus = styled.div`
    // add-first-child logic
    /* div:first-child {
        margin-top: 25px;
        font-size: 12px;
        font-weight: bold;
        color: #536d8f;
        width: 70px;
        height: 30px;
    } */

    margin-top: 25px;
    font-size: 12px;
    font-weight: 600;
    color: #536d8f;
    width: 70px;
    height: 30px;
`;

export const FlowDate = styled.div`
    p {
        margin-bottom: 0px;
    }

    margin-top: 25px;
    font-size: 10px;
    font-weight: 400;
    color: #536d8f;
    height: 30px;
`;

export const FlowStepLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #dce5ec;
    margin-top: -20px;
    z-index: 100;

    ${(props) => props.index === 0 && `margin-left: 60px;`};
    ${(props) => props.index === props.totalLength - 1 && `margin-right: 60px;`};

    ${(props) =>
        props.isCompletedStep &&
        `
            background-color: #037FB9;
        `};

    ${(props) =>
        props?.beforeStepCompleted &&
        !props?.nextStepCompleted &&
        props?.index !== props?.totalLength - 1 &&
        `
            background:#037FB9;
        `};

    ${(props) =>
        props?.beforeStepCompleted &&
        !props?.isCompletedStep &&
        `
            background: linear-gradient(to right, #037FB9 60%, #dce5ec 50%);
        `};
`;

export const FlowCircleIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const FlowCircleContainer = styled.div`
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FlowStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* vertical-align: middle; */
    /* margin-top: -20px; */
    margin-bottom: -15px;
    width: 100px;

    /* p:nth-child(2) {
        margin-top: 25px;
        font-size: 12px;
        font-weight: bold;
        color: #536d8f;
        height: 30px;
    } */
`;

export const FlowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // max-width: 775px;
    // width: 100%;
    height: 170px;
    /* max-height: 180px; */
    padding: 0 20px;
    /* margin-top: 20px; */
    margin-bottom: 0px;
    font-size: 12px;
    color: #536d8f;
    /* overflow-x: auto; */
    overflow-y: hidden;
    text-align: center;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }

    /* scrollbar-width: thin; */
    /* scrollbar-color: #ccc #f0f0f0; */
    /* &::-webkit-scrollbar {
        width: 8px;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 4px;
        border: 2px solid #f0f0f0;
    } */

    @media (max-width: 2050px) {
        max-width: 1255px;
        // background-color: yellow;
    }
    @media (max-width: 1920px) {
        max-width: 1130px;
        // background-color: green;
    }

    @media (max-width: 1710px) {
        max-width: 920px;
        // background-color: blue;
    }
    @media (max-width: 1540px) {
        max-width: 750px;
        // background-color: red;
    }
`;
